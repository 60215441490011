import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Web3 from 'web3'
import classNames from 'classnames'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputThemeField from './InputThemeField'
import Dialog from '@material-ui/core/Dialog'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import TextField from '@material-ui/core/TextField'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogTitle, MenuItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TablePaginationActions from './TablePagination'
import {
  CHANGE_TRANSACTION_STATUS,
  CANCEL_TRANSACTION_USER,
  APPROVED_TRANSACTION,
  UPDATE_ALLOCATE_TRANSACTION_HASH,
  CREATE_INTEREST_PAYMENT_TRANSACTION,
  MARK_CAPITAL_INCREASE,
} from '../queriesAndMutations'
import { TRANSACTIONS_STATUS_TYPES, PAYMENT_TYPES, TRANSACTIONS_TYPES } from '../constants/transaction'
import { USER_KYC_STATUS_TYPES, USER_RIGHT_TYPES } from '../constants/user'
import { TOKEN_TYPES } from '../constants/settings'
import { isProduction } from '../utils/app'
import { formatDate } from '../utils/table'
import { toaster } from '../utils/toaster'
import { getQueryVars } from '../utils/url'
import { usePublicSettings } from '../myHooks/useSettings'
import { useExchangeRates, useMe } from '../myHooks/index'
import AdminAddPaymentModal from './AdminAddPaymentModal'
import {
  mandatoryKyc,
  hasUserEnoughRights,
} from '../utils/me'
import { tokenToEth, ethToFiat } from '../utils/rate'
import { TRANSACTION } from '../constants/riskAssessmentLookup'
import metamaskService from '../services/metamask'
import contractAbi from '../constants/contractAbi'

import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'
import AdminExportTransactions from './AdminCsvDialogs/AdminExportTransactions'
import TableRowTheme from './TableRow'
import TableColumnTheme from './TableColumn'
import moment from 'moment'

const useStyles = makeStyles(() => {
  const defaultStatusIcon = {
    margin: "0 8px 0 0",
    opacity: ".8",
  };

  const statusBadge = {
    padding: "6px 12px",
    border: "1px solid #000",
    borderRadius: "8%",
    textTransform: "lowercase",
  };

  return {
    capitalIncreaseHeader: {
      marginBottom: "14px"
    },
    table: {
      margin: "0",
    },
    tableHeader: {
      "& .MuiTableCell-head": {
        color: "#007bff",
        fontSize: "14px",
        fontWeight: "bold",
        padding: "10px",
      },
    },
    tableBody: {
      "& .MuiTableRow-root": {
        minHeight: "75px",
      },
      "& .MuiTableCell-body": {
        padding: "10px",
      },
    },
    circularProgressWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    activeStatusBadge: {
      textTransform: "capitalize",
      padding: "6px 12px",
      border: "1px solid #009f65",
      borderRadius: "8%",
    },
    disabledStatusBadge: {
      textTransform: "lowercase",
      padding: "6px 12px",
      border: "1px solid #ea0b0b",
      borderRadius: "8%",
    },
    actionBtnPaper: {
      zIndex: 10,
      position: "absolute",
      "&>div": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&>svg": {
          marginRight: "5px",
        },
        padding: "8px 10px",
      },
    },
    actionMenuOption: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&>svg": {
        marginLeft: "5px",
      },
    },
    txNum: {
      display: "flex",
      alignItems: "center",
    },
    cellText: {
      opacity: ".8",
      fontWeight: "bold",
      fontSize: "14px",
    },
    cellSubText: {
      opacity: ".6",
      fontSize: "12px",
    },
    successIcon: {
      ...defaultStatusIcon,
      color: "#009f65",
    },
    pendingIcon: {
      ...defaultStatusIcon,
      color: "#ffc100",
    },
    errorIcon: {
      ...defaultStatusIcon,
      color: "#f00",
    },
    idleIcon: {
      ...defaultStatusIcon,
      color: "#000",
    },
    buttonPay: {
      color: "#fff",
      fontSize: "11px",
      padding: "5px 15px",
      margin: "8px",
      textTransform: "capitalize",
      backgroundColor: "#007bff",
      "&:hover": {
        backgroundColor: "#253992",
      },
      "& svg": {
        margin: "0 0 0 8px",
      },
    },
    buttonDelete: {
      borderRadius: "4px",
      color: "#ff6868",
      backgroundColor: "#ffd8d8",
      transition: ".3s",
      "&:hover": {
        transition: ".3s",
        color: "#fff",
        backgroundColor: "#ff6868",
      },
    },
    buttonInfo: {
      color: "#495463",
      backgroundColor: "#e6effb",
      borderRadius: "4px",
      transition: ".3s",
      "&:hover": {
        transition: ".3s",
        color: "#fff",
        backgroundColor: "#495463",
      },
    },
    buttonBar: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      padding: "8px 10px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    actionIcon: {
      marginRight: "5px",
    },
    idleStatusBadge: statusBadge,
    pendingStatusBadge: {
      ...statusBadge,
      borderColor: "#ffc100",
    },
    rejectedStatusBadge: {
      ...statusBadge,
      borderColor: "#ea0b0b",
    },
    controlBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    searchField: {
      margin: "8px",
    },
    buttonsBar: {
      display: "flex",
      alignItems: "center",
    },
    settingsPepper: {
      right: "46px",
      padding: "10px 12px",
      position: "absolute",
      zIndex: 10,
      "& span": {
        textTransform: "lowercase",
      },
    },
  };
});

const CapitalIncreaseTransactions = ({ query, isAdmin, filteredStatus, capitalIncrease }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const queryParam = getQueryVars();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { me } = {} } = useMe();
  const [updateAllocateTransactionHash] = useMutation(UPDATE_ALLOCATE_TRANSACTION_HASH);
  const [createInterestPaymentTransaction, createInterestPaymentTransactionData] = useMutation(CREATE_INTEREST_PAYMENT_TRANSACTION);
  const [markCapitalIncrease, markCapitalIncreaseData] = useMutation(MARK_CAPITAL_INCREASE);

  const [reqOptions, setReqOptions] = useState({
    search: queryParam.search || '',
    statuses: [],
    capitalIncreaseId: capitalIncrease ? capitalIncrease.id : '',
    paymentTypes: [],
  });
  const [transferingToken, setTransferingToken] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [isSelectAll, setIsSelectAll] = React.useState(false);
  const [selectedTransactionIds, setSelectedTransactionIds] = React.useState([]);

  const { data: { exchangeRates } = {} } = useExchangeRates();

  const [isDialogDownloadOpen, setDialogDownloadOpen] = useState(false);

  const {
    data: {
      transactionsAdmin: transactionList,
    } = {},
    error,
    loading,
    refetch,
  } = useQuery(query, {
    variables: {
      input: {
        ...reqOptions,
        page,
        pageSize,
      },
    },
    fetchPolicy: "network-only",
  });

  const onSettingsClickAway = () => {
    setSettingsOpen(false);
  };

  const onSettingsClick = () => {
    setSettingsOpen((prev) => !prev);
  };

  const isTransactionCheckable = (transaction) => {
    const disabled = (transaction.status !== TRANSACTIONS_STATUS_TYPES.APPROVED && transaction.status !== TRANSACTIONS_STATUS_TYPES.CONTRACTED) ||
    ((transaction.allocate_tx_hash || (
      transaction.payment_type === PAYMENT_TYPES.CRYPTO &&
      ['eth', 'dai'].includes(transaction.currency)
    )) && (
      transaction.interestTransactions.length &&
      moment(transaction.interestTransactions[0].issued_at).isSame(new Date(), "day")
    ))

    return !disabled;
  };

  const onClickIsSelectAll = () => {
    const selectAll = !isSelectAll;
    setIsSelectAll(!isSelectAll);

    if (selectAll) {
      setSelectedTransactionIds(
        transactionList.objects
          .filter(transaction => isTransactionCheckable(transaction))
          .map(transaction => transaction.id)
      )
    } else {
      setSelectedTransactionIds([])
    }
  }

  const onChangeFilterStatuses = (status) => () => {
    const newStatuses = reqOptions.statuses.includes(status)
      ? reqOptions.statuses.filter((v) => v !== status)
      : [...reqOptions.statuses, status];

    if (filteredStatus) {
      filteredStatus(newStatuses);
    }

    setSettingsOpen(false)
    setReqOptions({ ...reqOptions, statuses: newStatuses });
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    } else {
      setPageSize(1);
      setPage(0);
    }
  };

  const onChangeReqOptions = (name) => (event) => {
    switch (name) {
      case "search":
        if (event.target.value.length >= 50) {
          return false;
        }
        break;
      default:
    }
    setPage(0);
    return setReqOptions({ ...reqOptions, [name]: event.target.value });
  };

  const renderTextStatus = (status) => {
    const statusStyles = {
      [TRANSACTIONS_STATUS_TYPES.REJECTED]: "status-red-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.IDLE]: "status-idle products__status",
      [TRANSACTIONS_STATUS_TYPES.PENDING]: "status-pending-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.IN_PROGRESS]: "status-pending-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.APPROVED]: "status-green-dark products__status",
      [TRANSACTIONS_STATUS_TYPES.CONTRACTED]: "status-green-dark products__status",
    };

    return <span className={statusStyles[status]}>{t(status.toLowerCase())}</span>;
  };

  const renderAllocateTokenStatus = (transaction) => {
    const isAllocatedToken = transaction && transaction.allocate_tx_hash && transaction.allocate_tx_hash.length > 0;
    const allocatedClass = isAllocatedToken ? "status-green-dark products__status" : "status-red-dark products__status";

    return <span className={allocatedClass}>{isAllocatedToken ? "Allocated" : null}</span>;
  };

  const renderStatus = (status) => {
    switch (status) {
      case TRANSACTIONS_STATUS_TYPES.APPROVED:
        return (
          <div className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('check')}
              name='check'
              size="24"
            />{" "}
          </div>
        );
      case TRANSACTIONS_STATUS_TYPES.CONTRACTED:
        return (
          <span className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('check')}
              name='check'
              size="24"
            />{" "}
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.IN_PROGRESS:
        return (
          <span className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('clock')}
              name='clock'
              size="24"
            />{" "}
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.PENDING:
        return (
          <span className={classNames('customer__avatar status-green d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('close')}
              name='close'
              size="24"
            />{" "}
          </span>
        );
      case TRANSACTIONS_STATUS_TYPES.REJECTED:
        return (
          <span className={classNames('customer__avatar status-red d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('close')}
              name='close'
              size="24"
            />{" "}
          </span>
        );
      default:
        return (
          <span className={classNames('customer__avatar status-idle d__flex flex__justify_center flex__align_center')}>
            <Icon
              className={classNames('help')}
              name='help'
              size="24"
            />{" "}
          </span>
        );
    }
  };

  const renderTxNum = (transaction) => (
    <div className="customer__item customer__item_normal customer__item-transaction">
      {renderStatus(transaction.status)}
      <div className="customer__description">
        <div className="header__subtitle">
          TNX
          {transaction.id}
        </div>
        <div className="customer__login">
          {formatDate(transaction.created_at)}
        </div>
      </div>
    </div>
  );

  const renderSettingsBtn = () => {
    if (loading) {
      return (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <ClickAwayListener onClickAway={onSettingsClickAway}>
        <div className={`filters filters__kyc ${settingsOpen ? "active" : ""}`}>
          <button
            className="button-square-stroke button-small filters__head"
            onClick={onSettingsClick}
          >
            <Icon
              className={classNames('filter')}
              name='filter'
              size="24"
            />{" "}
          </button>

          <div className="filters__body">
            <div className="filters__top">
              <div className="title-red filters__title">{ t('Filters') }</div>

              <button className="filters__close" onClick={onSettingsClick}>
                <Icon
                  className={classNames('close')}
                  name='close'
                  size="24"
                />{" "}
              </button>
            </div>

            <div className="filters__group">
              <div className="filters__item">
                <div className="filters__label">{ t('Showing') }</div>

                <div className="filters__list">
                  {Object.values(TRANSACTIONS_STATUS_TYPES).map((v) => (
                    <label key={v} className="checkbox checkbox_reverse">
                      <input
                        type="checkbox"
                        className="checkbox__input"
                        checked={reqOptions.statuses.includes(v)}
                        onChange={onChangeFilterStatuses(v)}
                        value={v}
                      />
                      <div className="checkbox__inner">
                        <div className="checkbox__tick">{' '}</div>
                        <div className="checkbox__text">{v}</div>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="filters__overlay" onClick={onSettingsClick}>{' '}</div>
        </div>
      </ClickAwayListener>
    );
  };

  const allocateToken = async (transactions) => {
    // Break if metamask not available
    if (metamaskService.isMetamaskNotAvailable()) {
      toaster.error('You need to install Metamask to use this feature')

      return
    }

    // Break if metamask not available
    if (transactions.length === 0) {
      toaster.error('No transaction has been selected')

      return
    }

    // Break if smart contract is not enable
    if (!publicSettings.enable_smart_contract) {
      toaster.error('Smart contract has not been enabled')

      return
    }

    if (!['mainnet', 'polygon', 'xinfin'].includes(publicSettings.smart_contract_network)) {
      toaster.error('Smart contract network is not supported')

      return
    }

    // Break if user is trying to do multiple allocation on token contract
    // if (publicSettings.smart_contract_type === 'token_contract' && transactions.length > 1) {
    //   toaster.error('Token contract does not support for multiple allocation')

    //   return
    // }

    try {
      await Promise.all(transactions.map((transaction) => {
        return new Promise((resolve, reject) => {
          if (transaction.status !== TRANSACTIONS_STATUS_TYPES.APPROVED) {
            toaster.error(`Transaction TNX${transaction.id} has not been approved`)
            reject()

            return
          }

          if ((!transaction.user.wallet_address || !['eth', 'usdt', 'xdc', 'polygon', 'dai'].includes(transaction.user.wallet_type.toLowerCase())) && !transaction.user.eth_receiving_wallet) {
            toaster.error(`User ${transaction.user.email} doesn't have ETH or XDC wallet`)
            reject()

            return
          }

          const address = metamaskService.formatAddress(transaction.user.wallet_address || transaction.user.eth_receiving_wallet)

          if (!Web3.utils.isAddress(address)) {
            toaster.error(`User ${transaction.user.email}'s wallet is invalid`)
            reject()

            return
          }

          resolve()
        })
      }))
    } catch (error) {
      return
    }

    try {
      await metamaskService.requestAccounts()

      let network

      switch (publicSettings.smart_contract_network) {
      case 'mainnet':
        network = isProduction ? 'mainnet' : 'sepolia'

        break

      case 'polygon':
        network = isProduction ? 'polygon' : 'amoy'

        break

      case 'xinfin':
        network = isProduction ? 'xinfin' : 'apothem'

        break

      default:
        toaster.error('Network not supported');

        return
      }

      await metamaskService.requireNetwork(network, () => {
        window.location.reload()
      })

      const web3 = new Web3(window.ethereum)
      const account = metamaskService.getCurrentAddress()
      const contractAddress = metamaskService.formatAddress(publicSettings.smart_contract_address)
      const contract = new web3.eth.Contract(
        publicSettings.smart_contract_type === 'crowdsale_contract'
          ? contractAbi.crowdsale
          : contractAbi.token,
         contractAddress
      );
      const decimal = publicSettings.smart_contract_type === 'crowdsale_contract'
        ? +(await metamaskService.getContractDecimal(await metamaskService.getTokenAddressOfCrowldsaleContract(contractAddress)))
        : +(await metamaskService.getContractDecimal(contractAddress))

      if (publicSettings.smart_contract_type === 'token_contract') {
        for (const transaction of transactions) { /* eslint-disable-line no-restricted-syntax */
          /* eslint-disable no-await-in-loop */

          const data = await contract.methods
            .mint(
              metamaskService.formatAddress(transaction.user.wallet_address || transaction.user.eth_receiving_wallet),
              `${Math.ceil(+(transaction.approved_token || transaction.token_amount) * (10 ** decimal))}`
            ).send({
              from: account,
              value: 0
            });

          await updateAllocateTransactionHash({
            variables: {
              ids: [transaction.id],
              hash: data.transactionHash,
              network,
            }
          })
          /* eslint-enable no-await-in-loop */
        }

        refetch()
        setSelectedTransactionIds([])
      } else if (publicSettings.smart_contract_type === 'crowdsale_contract') {
        const data = await contract.methods.nonEthPurchaseMulti(
          transactions.map(transaction => metamaskService.formatAddress(transaction.user.wallet_address || transaction.user.eth_receiving_wallet)),
          transactions.map(transaction => Math.ceil(+(transaction.approved_token || transaction.token_amount) * (10 ** decimal)) / (10 ** decimal))
        ).send({
          from: account,
          value: 0
        })

        await updateAllocateTransactionHash({
          variables: {
            ids: transactions.map(transaction => transaction.id),
            hash: data.transactionHash,
            network,
          }
        })

        refetch()
        setSelectedTransactionIds([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const allocateTokenForMultipleTransaction = async () => {
    await allocateToken(transactionList.objects.filter(transaction => selectedTransactionIds.includes(transaction.id)))
  }

  const onCreateInterestPaymentTransaction = async () => {
    try {
      const transactions = transactionList.objects.filter(transaction => selectedTransactionIds.includes(transaction.id))
      await createInterestPaymentTransaction({
        variables: {
          ids: transactions.map(transaction => transaction.id),
        }
      })
      refetch();
      setSelectedTransactionIds([]);
      setPage(0);

      toaster.success('Create interest payment transaction successfully');
    } catch (error) {
      console.log(error)
    }
  }

  const onMarkCapitalIncrease = async () => {
    try {
      const transactions = transactionList.objects.filter(transaction => selectedTransactionIds.includes(transaction.id))
      await markCapitalIncrease({
        variables: {
          ids: transactions.map(transaction => transaction.id),
        }
      })
      refetch();
      setSelectedTransactionIds([]);

      toaster.success('Mark Capital Increase successfully');
    } catch (error) {
      console.log(error)
    }
  }

  const formatPaymentRef = (transaction) =>
    (transaction.investhub_ref ? `INV${transaction.investhub_ref}-${transaction.payment_ref}` : `TNX${transaction.id}-${transaction.payment_ref}`);

  if (error) {
    return <div>{ t('Some error') }</div>;
  }

  if (transactionList && exchangeRates) {
    const emptyRows =
      pageSize -
      Math.min(pageSize, transactionList.meta.total - page * pageSize);

    return (
      <>
        <Dialog
          open={isDialogDownloadOpen}
          onClose={() => {
            setDialogDownloadOpen(false);
          }}
        >
          <div className="popup js-popup animation visible">
            <div onClick={() => { setDialogDownloadOpen(false);}} className="popup__overlay js-popup-overlay">{' '}</div>

            <div className="popup__wrap js-popup-wrap">
              <div className="description">
                <div className="title-primary description__title">
                  { t('Download CSV File') }
                </div>

                <AdminExportTransactions
                  filteredStatus={filteredStatus}
                  capitalIncreaseId={capitalIncrease ? capitalIncrease.id : ''}
                  onClose={() => {
                    setDialogDownloadOpen(false);
                  }}
                />
              </div>

              <button
                className="popup__close js-popup-close"
                onClick={() => { setDialogDownloadOpen(false);}}
              >
                <Icon
                  className={classNames('close', 'icon-24')}
                  name="close"
                  size="24"
                />
              </button>
            </div>
          </div>
        </Dialog>

        <div className="settings__list w-full">
          <div className="settings__item">
            <div className="w-full">
              <div className="create__group">
                {hasUserEnoughRights(
                  me.rights,
                  USER_RIGHT_TYPES.ACCOUNTING
                ) && isAdmin && me && (
                  <>
                    <div className="field">
                      <div className="field__label">
                        {t('Downloads')}
                      </div>

                      <div className="field__wrap">
                        <button
                          className="button-stroke w-full"
                          type="button"
                          onClick={() => {
                            setDialogDownloadOpen(true);
                          }}
                        >
                          {t('Download as CSV')}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className="products__head">
          <div className="title-primary products__title">
            { t('Transactions') }
          </div>

          <form className="form">
            <input
              type="search"
              className="form__input"
              placeholder={t('Type in search')}
              value={reqOptions.search}
              onChange={onChangeReqOptions("search")}
            />
            <button className="form__button">
              <Icon
                className={classNames('searchh')}
                name='searchh'
                size="24"
              />{" "}
            </button>
          </form>

          <div className="products__nav">
            {renderSettingsBtn()}
          </div>
        </div>

        <div className={classNames(classes.capitalIncreaseHeader)}>
          <div className="products__title">
            { t('Capital Increase date') }
            { ' ' }
            {capitalIncrease.commercial_register_url ?
              <a href={capitalIncrease.commercial_register_url} target="_blank" rel="noopener noreferrer">
                {moment(capitalIncrease.date).format('YYYY-MM-DD')}
              </a> :
              moment(capitalIncrease.date).format('YYYY-MM-DD')
            }
            </div>
          {capitalIncrease.pdf_url ?
            <div className="products__title">
              { t('Capital Increase PDF') }
              { ' ' }
              <a href={capitalIncrease.pdf_url} target="_blank" rel="noopener noreferrer">
                Link
              </a>
            </div> : ''
          }
        </div>

        <div className="transactions__wrapper overflow-x__auto">
          <div className="transactions__table kyc__table_transactions">
            <TableRowTheme typeRow="transactions__row">
              <TableColumnTheme typeColumn="transactions__col" isFirstColumn={true}>
                { t('Tranx No') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Payment Reference') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Approved Tokens') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Tokens') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Amount') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Discount') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('Wallet') }
              </TableColumnTheme>

              <TableColumnTheme typeColumn="transactions__col">
                { t('From') }
              </TableColumnTheme>

              {(publicSettings || {}).show_source_signup && (
                <TableColumnTheme typeColumn="transactions__col">
                  { t('Source') }
                </TableColumnTheme>
              )}

              {publicSettings.token_type === TOKEN_TYPES.BOND && (
                <TableColumnTheme typeColumn="transactions__col">
                  { t('Interest Issued At') }
                </TableColumnTheme>
              )}

              {publicSettings.token_type === TOKEN_TYPES.EQUITY && (
                <TableColumnTheme typeColumn="transactions__col">
                  { t('Capital Increase') }
                </TableColumnTheme>
              )}

              <TableColumnTheme typeColumn="transactions__col">
                { t('Token Off Chain') }
              </TableColumnTheme>

              {isAdmin && (<TableColumnTheme typeColumn="transactions__col">
                { t('Token On Chain') }
              </TableColumnTheme>)}
            </TableRowTheme>

            {transactionList.objects.map((transaction) => (
              <TableRowTheme typeRow="transactions__row" key={transaction.id} propertyName={transaction.id}>
                <div className="transactions__col">
                  <div className="transactions__label">{ t('Tranx No') }</div>
                  {renderTxNum(transaction)}
                </div>

                <div className="transactions__col">
                  <div className="transactions__label">{ t('Payment Reference') }</div>
                  {transaction.payment_ref && formatPaymentRef(transaction)}
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{ t('Approved Tokens') }</div>
                  {transaction.status === 'APPROVED' ? +transaction.approved_token || +transaction.token_amount : '' }
                  {' '}
                  {transaction.status === 'APPROVED' && publicSettings && publicSettings.token_symbol}
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{ t('Tokens') }</div>
                  {+transaction.token_amount}
                  {' '}
                  {publicSettings && publicSettings.token_symbol}
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{ t('Amount') }</div>
                  <div className="transactions__content_wrapper">
                    {(+transaction.currency_amount).toFixed(2)}
                    {' '}
                    {transaction.currency}
                    <br />
                    ({transaction.payment_type})
                  </div>
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">
                    { t('Discount') }
                  </div>
                  {transaction.discount_percent ? `${transaction.discount_percent}%` : ''}
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{ t('Wallet') }</div>

                  <div className="shorten__text">
                    {transaction.user.wallet_address
                      ? `${transaction.user.wallet_address || ""} ${(
                        transaction.user.wallet_type || ""
                      ).toUpperCase()}`
                      : `${transaction.user.eth_receiving_wallet} ETH`
                    }
                  </div>
                </div>

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{ t('From') }</div>

                  <div className="transactions__content_wrapper">
                    <div className="transactions__product">
                      {transaction.user.email}
                    </div>

                    <div className="transactions__invoice vert__middle">
                      {formatDate(transaction.created_at)}
                    </div>
                  </div>
                </div>

                {(publicSettings || {}).show_source_signup && (
                  <div className="transactions__col vert__middle">
                    <div className="transactions__label">{t('Source')}</div>
                    {transaction.user.is_gtoswiss
                      ? "GTOwiss.com"
                      : transaction.user.is_internal_sales
                      ? "InternalSales"
                      : transaction.user.referral_code || "Organic"}
                  </div>
                )}

                {publicSettings.token_type === TOKEN_TYPES.BOND && (
                  <div className="transactions__col vert__middle">
                    <div className="transactions__label">{t('Interest Issued At')}</div>
                    {
                      transaction.interestTransactions.length ?
                        moment(transaction.interestTransactions[0].issued_date).format('YYYY-MM-DD') :
                        ''
                    }
                  </div>
                )}

                {publicSettings.token_type === TOKEN_TYPES.EQUITY && (
                  <div className="transactions__col vert__middle">
                    <div className="transactions__label">{t('Capital Increase')}</div>
                    {transaction.capitalIncrease ? 'TRUE' : 'FALSE'}
                    {transaction.capitalIncrease && (
                      <p>
                        {
                          transaction.capitalIncrease.commercial_register_url ?
                          <a href={transaction.capitalIncrease.commercial_register_url} target="_blank" rel="noopener noreferrer">
                            {moment(transaction.capitalIncrease.date).format('YYYY-MM-DD')}
                          </a> :
                          moment(transaction.capitalIncrease.date).format('YYYY-MM-DD')
                        }
                      </p>
                    )}
                  </div>
                )}

                <div className="transactions__col vert__middle">
                  <div className="transactions__label">{t('Token Off Chain')}</div>
                  {renderTextStatus(transaction.status)}
                </div>

                {isAdmin && (<div className="transactions__col vert__middle">
                  <div className="transactions__label">{t('Token On Chain')}</div>
                  {renderAllocateTokenStatus(transaction)}
                </div>)}
              </TableRowTheme>
            ))}
            {emptyRows > 0 && (
              <div className="transactions__row">
                <div className="transactions__col transactions__col-full">
                  {' '}
                </div>
              </div>
            )}
          </div>
        </div>
        <TablePaginationActions
          count={transactionList.meta.total}
          page={page}
          rowsPerPage={pageSize}
          onChangePage={onChangePage}
          onChangeRows={onChangePageSize}
          rowsPerPageOptions={[1, 5, 10, 25, 100]}
        />

        <div className={classNames(classes.capitalIncreaseHeader)}>
          <div className="products__title">
            { `${t('Capital Increase Lodged')}: ${capitalIncrease ? capitalIncrease.amount : ''}` }
          </div>
          <div className="products__title">
            { `${t('Grand Total')}: ${capitalIncrease ? capitalIncrease.totalAmount : ''}` }
          </div>
          <div className="products__title">
            { `${t('Nominal Total')}: ${capitalIncrease ? capitalIncrease.totalToken * publicSettings.capital_increase_nominal_value : ''}` }
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={classes.circularProgressWrapper}>
      <CircularProgress />
    </div>
  );
};

CapitalIncreaseTransactions.propTypes = {};

export default CapitalIncreaseTransactions;
