/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'
import { useQuery } from '@apollo/react-hooks'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
// import DialogContent from '@material-ui/core/DialogContent'
// import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
// import Button from '@material-ui/core/Button'
import { useMutation } from '@apollo/react-hooks'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import InputTheme from '../../components/InputTheme'
import AdminSettingsIco from './AdminSettingsIco'
import AdminSettingsEvolve from './AdminSettingsEvolve'
import AdminSettingsCompany from '../../components/AdminSettings/AdminSettingsCompany'
import AdminSettingsBank from '../../components/AdminSettings/AdminSettingsBank'
import AdminSettingsCrypto from '../../components/AdminSettings/AdminSettingsCrypto'
import AdminSettingsMailgun from '../../components/AdminSettings/AdminSettingsMailgun'
import { useSettings } from '../../myHooks/useSettings'
import { toaster } from '../../utils/toaster'
import { SAVE_SETTINGS, SYSTEM_SETTINGS, GET_AVAILABLE_FEATURES } from '../../queriesAndMutations'
import AdminSettingsUploads from '../../components/AdminSettings/AdminSettingsUploads'
import { useMe } from '../../myHooks'
import { hasUserEnoughRights, isFeatureEnabled } from '../../utils'
import { USER_RIGHT_TYPES } from '../../constants/user'
import AdminSettingsKYClvl from '../../components/AdminSettings/AdminSettingsKYClvl'
import AdminSettingsIdentification from '../../components/AdminSettings/AdminSettingsIdentification'
import AdminSettingsCashlink from './AdminSettingsCashlink'
import AdminSettingsSystem from '../../components/AdminSettings/AdminSettingsSystem'
import AdminSettingsBlacklist from './AdminSettingsBlacklist'
import ModalInnerMaterialDialog from '../../components/ModalInnerMaterialDialog'
import AdminSettingsSmartContract from './AdminSettingsSmartContract'
import AdminSettingsSiteLanguages from './AdminSettingsSiteLanguages'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AdminSettings = () => {
  const { data, refetch } = useSettings({ fetchPolicy: "network-only" });
  const { data: { systemSettings } = {}, refetch: refetchSystemSetting } = useQuery(SYSTEM_SETTINGS, { fetchPolicy: "network-only" });
  const { data: { me = {} } = {} } = useMe();
  const [updateSettings, updateSettingsData] = useMutation(SAVE_SETTINGS);
  const { t } = useTranslation();
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES);

  const [valuesToSend, setValuesToSend] = useState({ two_fa_token: "" });

  const latestValueToSend = useRef(valuesToSend);

  useEffect(() => {
    latestValueToSend.current = { ...valuesToSend };
  }, [valuesToSend]);

  const [twoFaDialogOpened, setTwoFaDialogOpened] = useState(false);
  const changeTwoFADialogState = (state) => () => setTwoFaDialogOpened(state);

  const onUpdate = (newSettings) => {
    if (me.is_2fa_enabled === false) {
      return toaster.error(
        <span>
          Please, enable 2fa first in
          {' '}
          <a href="/profile">your profile</a>
        </span>
      );
    }
    setValuesToSend({ ...newSettings });

    setTwoFaDialogOpened(true);
  };

  const onSend = async () => {
    if (data && data.settings) {
      const { settings } = data;
      try {
        await updateSettings({
          variables: {
            input: {
              ...omit(
                settings,
                "__typename",
                "logo_path",
                "logo_for_dark_bg_path",
                "brief_logo_path",
                "dataroom_logo_path",
                "source_of_funds_example_path",
                "rights_of_withdrawal_path",
                "source_of_address_for_tokens_example_path",
                "source_of_address_for_sending_tokens_example_path",
                "example_photo_proof_path",
                "logo_bank_path",
                "cashlink_contract_info",
                "cashlink_paper_contract_hash",
                "thumbnail_path",
              ),
              ...latestValueToSend.current,
            },
          },
        });
        setTwoFaDialogOpened(false);
        refetch();
        toaster.success('Settings saved')
        refetchSystemSetting();
      } catch(e) { console.log(e); }
    }
  };

  const editingAllowed = hasUserEnoughRights(
    me.rights,
    USER_RIGHT_TYPES.GENERAL_ADMIN
  );

  const [values, setValues] = useState({
    tab: isFeatureEnabled('KYB_ONBOARDING', availableFeatures) ? "ADMIN_MAIN" : "EVOLVE",
  });

  const changeTab = (tabName) => () =>
    setValues({
      ...values,
      tab: tabName,
    });

  const changeMenuByDropdown = (e) => {
    setValues({...values, tab: e.target.value})
  }

  const renderTabs = () => (
    <div className="settings__menu tablet-hide">
      {isFeatureEnabled('EVOLVE', availableFeatures) && (
        <button
          className={`settings__link ${values.tab === "EVOLVE" ? "active" : ""}`}
          onClick={changeTab("EVOLVE")}
        >
          { t('Evolve') }
        </button>
      )}

      {isFeatureEnabled('KYB_ONBOARDING', availableFeatures) && (
        <>
          <button
            className={`settings__link ${values.tab === "ADMIN_MAIN" ? "active" : ""}`}
            onClick={changeTab("ADMIN_MAIN")}
          >
            { t('Main') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_COMPANY" ? "active" : ""}`}
            onClick={changeTab("ADMIN_COMPANY")}
          >
            { t('Company') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_BANK" ? "active" : ""}`}
            onClick={changeTab("ADMIN_BANK")}
          >
            { t('Bank') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_CRYPTO" ? "active" : ""}`}
            onClick={changeTab("ADMIN_CRYPTO")}
          >
            { t('Crypto') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_MAILGUN" ? "active" : ""}`}
            onClick={changeTab("ADMIN_MAILGUN")}
          >
            { t('Mailgun') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_KYC" ? "active" : ""}`}
            onClick={changeTab("ADMIN_KYC")}
          >
            { t('KYC LEVEL') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_IDENTIFICATION" ? "active" : ""}`}
            onClick={changeTab("ADMIN_IDENTIFICATION")}
          >
            { t('Identification') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_CASHLINK" ? "active" : ""}`}
            onClick={changeTab("ADMIN_CASHLINK")}
          >
            { t('Cashlink') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_SYSTEM" ? "active" : ""}`}
            onClick={changeTab("ADMIN_SYSTEM")}
          >
            { t('System') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_BLACKLIST" ? "active" : ""}`}
            onClick={changeTab("ADMIN_BLACKLIST")}
          >
            { t('Blacklist') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_SMART_CONTRACT" ? "active" : ""}`}
            onClick={changeTab("ADMIN_SMART_CONTRACT")}
          >
            { t('Smart Contract') }
          </button>

          <button
            className={`settings__link ${values.tab === "ADMIN_SITE_LANGUAGES" ? "active" : ""}`}
            onClick={changeTab("ADMIN_SITE_LANGUAGES")}
          >
            { t('Site Languages') }
          </button>
        </>
      )}

      <button
        className={`settings__link ${values.tab === "ADMIN_UPLOADS" ? "active" : ""}`}
        onClick={changeTab("ADMIN_UPLOADS")}
      >
        { t('Uploads') }
      </button>
    </div>
  );

  const renderTabsRWD = () => (
    <div className="settings__menu-rwd tablet-show">
      <div className="field">
        <div className="messenger__line">
          <div className="messenger__man">
            {t('Admin Menu Setting')}
          </div>
        </div>
        <div className="space space__8">{' '}</div>
        <div className="field__wrap field__wrap-select">
          <TextField
            select
            value={values.tab}
            fullWidth
            onChange={changeMenuByDropdown}
          >
            {isFeatureEnabled('EVOLVE', availableFeatures) && (
              <MenuItem value="EVOLVE">
                {t('Evolve')}
              </MenuItem>
            )}

            {isFeatureEnabled('KYB_ONBOARDING', availableFeatures) && (
              <>
                <MenuItem value="ADMIN_MAIN">
                  {t('Main')}
                </MenuItem>

                <MenuItem value="ADMIN_COMPANY">
                  {t('Company')}
                </MenuItem>
                <MenuItem value="ADMIN_BANK">
                  {t('Bank')}
                </MenuItem>
                <MenuItem value="ADMIN_CRYPTO">
                  {t('Crypto')}
                </MenuItem>
                <MenuItem value="ADMIN_MAILGUN">
                  {t('Mailgun')}
                </MenuItem>
                <MenuItem value="ADMIN_KYC">
                  {t('KYC LEVEL')}
                </MenuItem>
                <MenuItem value="ADMIN_IDENTIFICATION">
                  {t('Identification')}
                </MenuItem>
                <MenuItem value="ADMIN_CASHLINK">
                  {t('Cashlink')}
                </MenuItem>
                <MenuItem value="ADMIN_SYSTEM">
                  {t('System')}
                </MenuItem>
                <MenuItem value="ADMIN_BLACKLIST">
                  {t('Blacklist')}
                </MenuItem>
                <MenuItem value="ADMIN_SMART_CONTRACT">
                  {t('Smart Contract')}
                </MenuItem>
                <MenuItem value="ADMIN_SITE_LANGUAGES">
                { t('Site Languages') }
                </MenuItem>
              </>
            )}

            <MenuItem value="ADMIN_UPLOADS">
              {t('Uploads')}
            </MenuItem>
          </TextField>
        </div>
      </div>
      <div className="space space__24">{' '}</div>
    </div>
  )

  return (
    <>
      <Dialog
        open={twoFaDialogOpened}
        onClose={changeTwoFADialogState(false)}
      >
        <ModalInnerMaterialDialog
          modalSize="popup__kyc-medium"
          onClickClose={changeTwoFADialogState(false)}
          modalTitle={t('2FA Token')}
          modalTitleClassColor="title-primary"
        >
          <div className="space space__32">{' '}</div>
          <div className="settings__item">
            <div className="settings__fieldset">
              <InputTheme
                classWrapper="field"
                classLabel="field__label"
                classInput="field__input"
                propertyName="two_fa_token"
                state={valuesToSend}
                setState={setValuesToSend}
                error={updateSettingsData.error}
                loading={updateSettingsData.loading}
              />
            </div>
          </div>

          <div className="settings__button settings__buttons d__flex flex__row flex__wrap flex__justify_between">
            <button className="button button-small" onClick={onSend} disabled={updateSettingsData.loading}>
              { t('Update') }
            </button>
          </div>
        </ModalInnerMaterialDialog>
      </Dialog>

      <div className="page__title h3">{ t('Settings') }</div>

      <div className="settings">
        {renderTabs()}

        {data && data.settings && (
          <div className="settings__wrapper settings__wrapper_2">

            {renderTabsRWD()}

            <div className="settings__list">
              {values.tab === "ADMIN_MAIN" && (
                <div className="tab-pane fade show active" id="admin-main">
                  <AdminSettingsIco
                    editingAllowed={editingAllowed}
                    settings={data.settings}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "EVOLVE" && (
                <div className="tab-pane fade show active" id="admin-evolve">
                  <AdminSettingsEvolve
                    editingAllowed={editingAllowed}
                    settings={data.settings}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_COMPANY" && (
                <div className="tab-pane fade" id="admin-company">
                  <AdminSettingsCompany
                    companySettings={data.settings && data.settings.company}
                    settings={data.settings || {}}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_BANK" && (
                <div className="tab-pane fade" id="admin-bank">
                  <AdminSettingsBank
                    bankSettings={data.settings && data.settings.bank}
                    settings={data.settings || {}}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_CRYPTO" && (
                <div className="tab-pane fade" id="admin-crypto">
                  <AdminSettingsCrypto
                    cryptoSettings={data.settings && data.settings.crypto}
                    kycAcceptedWallets={
                      data.settings && data.settings.kyc_accepted_wallets
                    }
                    kycValidateWallets={
                      (data.settings && data.settings.kyc_validate_wallets) || {}
                    }
                    settings={data.settings || {}}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_MAILGUN" && (
                <div className="tab-pane fade" id="admin-mailgun">
                  <AdminSettingsMailgun
                    mailgunSettings={data.settings && data.settings.mailgun}
                    settings={data.settings || {}}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_UPLOADS" && (
                <div className="tab-pane fade" id="admin-uploads">
                  <AdminSettingsUploads
                    settings={data.settings}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_KYC" && (
                <div className="tab-pane fade" id="admin-kyc">
                  <AdminSettingsKYClvl
                    settings={data.settings}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_IDENTIFICATION" && (
                <div className="tab-pane fade" id="admin-identification">
                  <AdminSettingsIdentification
                    settings={data.settings}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                  />
                </div>
              )}

              {values.tab === "ADMIN_CASHLINK" && (
                <div className="tab-pane fade" id="admin-cashlink">
                  <AdminSettingsCashlink
                    settings={data.settings}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                    refetch={refetch}
                  />
                </div>
              )}

              {values.tab === "ADMIN_SYSTEM" && (
                <div className="tab-pane fade" id="admin-system">
                  <AdminSettingsSystem
                    settings={data.settings}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                    refetch={refetch}
                  />
                </div>
              )}

              {values.tab === "ADMIN_BLACKLIST" && (
                <div className="tab-pane fade" id="admin-blacklist">
                  <AdminSettingsBlacklist />
                </div>
              )}

              {values.tab === "ADMIN_SMART_CONTRACT" && (
                <div className="tab-pane fade" id="admin-smart-contract">
                  <AdminSettingsSmartContract
                    settings={data.settings}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                    refetch={refetch}
                  />
                </div>
              )}

              {values.tab === "ADMIN_SITE_LANGUAGES" && (
                <div className="tab-pane" id="admin-site-languages">
                  <AdminSettingsSiteLanguages
                    settings={data.settings}
                    editingAllowed={editingAllowed}
                    loading={updateSettingsData.loading}
                    onUpdate={onUpdate}
                    refetch={refetch}
                  />

                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminSettings;
