import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TopUpAmountDialog from './TopUpAmountDialog';
import RegisterWalletDialog from './RegisterWalletDialog';
import { useMe } from '../../myHooks';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  LOOKUP_SENDWYRE_WALLET,
} from '../../queriesAndMutations/sendwyreMutation';
import {
  GET_SENDWYRE_RATES,
} from '../../queriesAndMutations';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  cardSubtitle: {
    margin: '0 0 4px 0',
    color: '#74fffa',
  },
  cardSubtitleContributeForm: {
    margin: '0 0 4px 0',
  },
  tokenBalanceText: {
    display: 'inline-block',
  },
  topUpWallet: {
    margin: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: '#000',
  },
  textKYCSubbmited: {
    fontSize: '10px',
    marginTop: '5px',
  },
}));

const CreateNewAndTopUpLink = (props) => {
  const {
    data: { me: userData },
    refetch,
  } = useMe();
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: { getSendwyreExchangeRates = {} } = {} } = useQuery(GET_SENDWYRE_RATES);

  const [
    getCurrentSendwyreWallet,
    { data: { lookupSendwyreWallet } = {} },
  ] = useMutation(LOOKUP_SENDWYRE_WALLET);

  const [values, setValues] = useState({
    topUpAmountDialogOpened: false,
    registerSendwyreWalletDialogOpened: false,
    isTopUpSuccess: false,
    isRegisterWalletSuccess: false,
    isMandatoryKYCNoticeDialogOpened: false,
    sendWyreEthAddress: '',
    sendWyreBtcAddress: '',
  });

  const walletId = userData && userData.sendwyre_wallet_id;

  useEffect(() => {
    if (userData.sendwyre_wallet_id !== null) {
      getCurrentSendwyreWallet({
        variables: { input: { walletId: walletId } },
      }).then((response) => {
        setValues({
          ...values,
          sendWyreBtcAddress:
            response.data.lookupSendwyreWallet.depositAddresses.BTC,
          sendWyreEthAddress:
            response.data.lookupSendwyreWallet.depositAddresses.ETH,
          sendWyreMaticAddress:
            response.data.lookupSendwyreWallet.depositAddresses.MATIC,
        });
      }).catch(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const changeMandatoryKYCNoticeDialogState = (state) => () =>
    setValues({
      ...values,
      isMandatoryKYCNoticeDialogOpened: state,
    });

  const changeRegisterSendwyreWalletDialogState = (state) => () =>
    setValues({
      ...values,
      registerSendwyreWalletDialogOpened: state,
    });

  const changeTopUpAmountDialogState = (state) => () =>
    setValues({
      ...values,
      topUpAmountDialogOpened: state,
    });

  function handleTopUpSuccess(value) {
    if (value) {
      setValues({
        ...values,
        isTopUpSuccess: value,
      });
    }
  }

  const renderMandatoryKYC = () => (
    <Dialog
      open={values.isMandatoryKYCNoticeDialogOpened}
      onClose={changeMandatoryKYCNoticeDialogState(false)}
    >
      <div className='popup-body'>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={changeMandatoryKYCNoticeDialogState(false)}
        >
          <CloseIcon />
        </IconButton>
        <h4 className='popup-title'>{ t('Notice') }</h4>
        <p>{t('Please, complete KYC first')}</p>
      </div>
    </Dialog>
  );

  const createNewSendwyreWallet = () => (
    <div className={`${classes.tokenBalanceText} token-balance-text`}>
      <span
        className={
          props.parent && props.parent === 'ContributeForm'
            ? classes.cardSubtitleContributeForm
            : classes.cardSubtitle
        }
      >
        { `${t('You don\'t have Sendwyre Wallet')}. ` }
      </span>
      <span className='lead'>
        <span
          onClick={changeRegisterSendwyreWalletDialogState(true)}
          className={`${classes.topUpWallet} link link-ucap`}
        >
          { t('Create Sendwyre\'s wallet') }
        </span>
      </span>
    </div>
  );

  const renderTextTopUpSuccess = () => (
    <div className={classes.textKYCSubbmited}>
      {values.isTopUpSuccess
        ? 'Top up complete this can take 10-60 seconds please refresh this page in 10-60 seconds'
        : null}
    </div>
  );
  const getBalanceInFiat = () => {
    if (!props.fiatCurrency || !getSendwyreExchangeRates || !getSendwyreExchangeRates[`USDC${props.fiatCurrency}`]) {
      return ''
    }

    try {
      const exchangeRate = getSendwyreExchangeRates[`USDC${props.fiatCurrency}`]
      const usdcBalance = lookupSendwyreWallet.totalBalances[props.currency] || 0
      const balanceInFiat = Math.floor(usdcBalance * exchangeRate * 100) / 100

      return `(${balanceInFiat} ${props.fiatCurrency}) `
    } catch (error) {
      return ''
    }
  }

  const topUpSendwyreWallet = () => {
    return (
      <div className={classes.tokenBalanceText}>
        <span
          className={
            props.parent && props.parent === 'ContributeForm'
              ? classes.cardSubtitleContributeForm
              : classes.cardSubtitle
          }
        >
          { `${t('Wallet Balance')}: ` }
        </span>
        <span className='lead'>
          {`${lookupSendwyreWallet.totalBalances[props.currency] || 0} ${props.currency} `}
          {getBalanceInFiat()}
          <span
            onClick={changeTopUpAmountDialogState(true)}
            className={`${classes.topUpWallet} link link-ucap`}
          >
            { t('Top Up') }
          </span>
        </span>
        {renderTextTopUpSuccess()}
      </div>
    );
  };

  const onRegisterSendwyreWalletSuccess = () => {
    refetch();
    changeTopUpAmountDialogState(true)
  }

  return (
    <>
      {renderMandatoryKYC()}
      <div className='sendwyre-text'>
        {userData.sendwyre_wallet_id == null
          ? createNewSendwyreWallet()
          : lookupSendwyreWallet &&
            lookupSendwyreWallet.status &&
            lookupSendwyreWallet.status === 'success'
          ? topUpSendwyreWallet()
          : null}
      </div>
      <RegisterWalletDialog
        dialogOpen={values.registerSendwyreWalletDialogOpened}
        onCloseDialog={changeRegisterSendwyreWalletDialogState(false)}
        onSuccess={onRegisterSendwyreWalletSuccess}
      />
      {values.topUpAmountDialogOpened && (
        <TopUpAmountDialog
          dialogOpen={values.topUpAmountDialogOpened}
          onCloseDialog={changeTopUpAmountDialogState(false)}
          onTopUpSuccess={(value) => handleTopUpSuccess(value)}
          sendWyreAddress={
            props.currency === 'MATIC'
              ? `matic:${values.sendWyreMaticAddress}`
              : (props.currency === 'BTC'
                ? `bitcoin:${values.sendWyreBtcAddress}`
                : `ethereum:${values.sendWyreEthAddress}`
              )
          }
          currency={props.currency}
          amount={props.topUpAmount}
        />
      )}
    </>
  );
};

export default CreateNewAndTopUpLink;
