import React, { useEffect, useState, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'
import { pick, reduce, remove } from 'lodash'
// import "../styles/legacy/style.scss";
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import FormHelperText from '@material-ui/core/FormHelperText'
import ReactPixel from 'react-facebook-pixel'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns/build/index'
import metamaskService from '../services/metamask'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { PROSPECTUS_OR_ISSUING, TOKEN_TYPES } from '../constants/settings'
import {
  CUSTOMER_TYPES,
  COUNTRY_LIST,
  INDUSTRY_OPTIONS,
  RESTRICTED_COUNTRY_LIST,
  RESTRICTED_NATIONALITY,
} from '../constants/customer'
import {
  extractMsgFromErrByPropertyName,
  getExternalFileUrl,
  mapSchema,
  numberWithCommas,
  toaster,
  getCountryList,
  getNationalityList,
} from '../utils'
import { GET_MY_TOKEN_SWAP } from '../queriesAndMutations'
import { ethToFiat, tokenToEth, isFiat, tokenToUsdc, isCrypto } from '../utils/rate'
import { useExchangeRates, useMe } from '../myHooks'
import { usePublicSettings } from '../myHooks/useSettings'
import { getSendingWalletTypeOptions } from '../constants/walletLabelTextMapping'
import KycVerificationFormSourceOfFunds from './KycVerificationFormSourceOfFunds'
import KycVerificationFormSourceOfWealth from './KycVerificationFormSourceOfWealth'
import KycFormSupportedSourceOfFund from './KycFormSupportedSourceOfFund'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useTranslation } from 'react-i18next'
import KycFormDocumentProofOfResidence from './KycFormDocumentProofOfResidence'
import KycVerificationFormDisclaimer from './KycVerificationFormDisclaimer'
import InputThemeLite from './InputThemeLite'
import DropdownMaterial from './DropdownMaterial'
import RadioTheme from './Radio'
// import WalletRequest from './WalletRequest'
import SwitchCheckboxTheme from './SwitchCheckbox'
// import InfoIcon from './Icon'

const useStyles = makeStyles(() => ({
  input_border_date: {
    borderRadius: "4px",
    border: "1px solid var(--color__border-box)",
    width: "100%",
    padding: "14px 15px",
    lineHeight: "20px",
    fontSize: ".9em",
    color: "rgba(73, 84, 99, 0.7)",
    transition: "all .4s",
    "&:focus": {
      boxShadow: "none",
      outline: "none",
      borderColor: "#b1becc",
    },
    "&:disabled": {
      background: "rgba(230, 239, 251, 0.2)",
    },

    "~": {
      error: {
        color: "#ff6868",
        marginBottom: "0",
        position: "relative",
        top: "7px",
      },
    },
  },
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  itemBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "center",
  },
  info: {
    color: "#758698",
    "&>svg": {
      margin: "0 5px 0 0",
    },
  },
  note: {
    color: "#758698",
    fontSize: "12px",
  },
  listCheck: {
    "&>li": {
      display: "flex",
      alignItems: "center",
      "&>svg": {
        margin: "0 5px 0 0",
        color: "#6e81a9",
      },
    },
  },
  formControl: {
    marginBottom: "30px",
    "& label": {
      margin: "0 0 10px 0",
      "&>.MuiTypography-body1": {
        fontSize: "14px",
        color: "rgba(0, 0, 0, 0.65)",
      },
    },
  },
  title: {
    marginBottom: "40px",
  },
  confirmParagraph: {
    paddingLeft: "20px",
    margin: "0 0 0 30px",
    listStyleType: "square",
    "& li:last-child": {
      paddingBottom: "0",
    },
    "& li": {
      padding: "0 0 25px 0",
    },
    "&>li": {
      listStyleType: "square",
      paddingBottom: "25px",
      "&>ul": {
        padding: "20px 0 0 20px",
        margin: "0 0 0 30px",
        listStyleType: "circle",
        "&>li": {
          listStyleType: "circle",
        },
      },
    },
  },
  declarationWarging: {
    border: "1px solid",
    padding: "10px",
    margin: " 20px 0 50px 30px",
  },
  inputText: {
    marginBottom: "5px",
  },
  controlIcon: {
    cursor: "pointer",
  },

  equalsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  sourceOfFundsExample: {
    marginLeft: 10,
  },

  documentTypeButton: {
    paddingBottom: "22px !important",
  },
}));

const NET_INVESTABLE_ASSETS_OPTIONS = [
  "Up to 5.000 CHF",
  "5001 CHF - 25.000 CHF",
  "25001 CHF - 100.000 CHF",
  "100.001 CHF - 500.000 CHF",
  "500.001 CHF - 1 Mio CHF",
  "> 1 Mio - 5 Mio",
  "> 5 Mio",
];

const WHAT_WILL_YOU_USE_OPTIONS = [
  "Investing",
  "Reselling-related activities (broker/dealer)",
];

const WHAT_WILL_YOU_USE_OPTIONS_UTILITY_TOKEN = [
  "Staking",
  "Resale",
  "Usage of the Network",
  "Other",
];

const useTokenForOptionsXDCTEQ = [
  'Investing',
  'Other',
]

const useTokenForOptionsQdev = [
  'Blockchain Validation',
]

const UpdateKycVerificationForm = ({
  mainInfoSchemas,
  addressInfoSchemas,
  filesSchemas,
  documentTypeEnum,
  kycType,
  updateCustomer,
  updateCustomerData,
  onSuccess,
  customer,
  match,
}) => {
  const currentHost = window.location.host;
  const isXDCTEQ = currentHost.includes('xdcteq');
  const isQdev = currentHost === 'kyc.qdev.li' || currentHost === 'qdevelopment-dev.investhub.io' || currentHost === 'localhost:3000';
  const { t } = useTranslation();
  const cloneCus = { ...customer };

  cloneCus["firstName"] = customer.first_name;
  cloneCus["lastName"] = customer.last_name;
  cloneCus["middleName"] = customer.middle_name;

  const classes = useStyles();
  const { data: { exchangeRates } = {} } = useExchangeRates();
  const { data: { publicSettings = {} } = {}, loading: loadingPublicSettings } =
    usePublicSettings();
  const { data: { getMyTokenSwap: tokenSwap } = {} } = useQuery(GET_MY_TOKEN_SWAP)
  const isCryptoadvisory = currentHost === 'kyc.cryptoadvisory.li' || currentHost === 'dev-kyc.cryptoadvisory.li';

  let whatWillYouUseOptions = publicSettings.token_type === TOKEN_TYPES.UTILITY ? WHAT_WILL_YOU_USE_OPTIONS_UTILITY_TOKEN : WHAT_WILL_YOU_USE_OPTIONS;

  if (isXDCTEQ) {
    whatWillYouUseOptions = useTokenForOptionsXDCTEQ;
  } else if (isQdev) {
    whatWillYouUseOptions = useTokenForOptionsQdev;
  }

  const INTENDED_INVESTMENT =
    publicSettings.kyc_levels ?
    Object.values(publicSettings.kyc_levels).filter((level) => {
      if (isQdev && level.min_invest_amount > 25000) {
        return false;
      }
      return !level.enable || level.enable.enable_lvl;
    })
    .map((item, index) => {
      const unit = publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF';

      if (item.enable && item.enable.enable_open_end) {
        return `> ${item.min_invest_amount} ${unit}`;
      }

      return `${item.min_invest_amount} - ${item.max_invest_amount} ${unit}`;
    }) : [];

  const textConfig =
    publicSettings.token_type === "UTILITY"
      ? {
          issuer: "Seller",
          subscription: "Token Purchase",
          subscriber: "purchaser",
        }
      : {
          issuer: "Issuer",
          subscription: "subscription",
          subscriber: "subscriber",
        };

  const { data: { me: userData = {} } = {} } = useMe();
  const { loading, error, data } = updateCustomerData;

  const [values, setValues] = useState({
    // wallets and terms
    tokenAddress: customer.tokenAddress || userData.wallet_address || "",
    verified_wallet: customer.verified_wallet,
    tokenSendingAddress: userData.wallet_sending_address || "",
    generatedWallet: '',
    personType: customer.personType,
    duties: customer.duties,
    relationshipToPEP: customer.relationshipToPEP,
    documentType: documentTypeEnum[Object.keys(documentTypeEnum)[0]].value,
    amount: String(parseInt(customer.amount)),
    currency: customer.currency,
    isAddEtherWalletLater: false,
    sourceOfFunds: customer.source_of_funds || '',
    sourceOfFundsOther: customer.source_of_funds_other || '',
    sourceOfWealth: customer.source_of_wealth || '',
    sourceOfWealthOther: customer.source_of_wealth_other || '',
    intended_investment: customer.intended_investment,
    exactIntendedInvestmentAmount: customer.exactIntendedInvestmentAmount,
    wallet: customer.wallet,
    sending_wallet: customer.sending_wallet,
    has_legal_protection_insurance: false,
    proof_of_residence_type: customer.proof_of_residence_type || '',
  });

  const countryList = getCountryList(publicSettings, isCryptoadvisory);
  const nationalityList = getNationalityList(publicSettings, isCryptoadvisory);

  const [exactIntendedInvestmentAmountValue, setExactIntendedInvestmentAmountValue] = useState(values.exactIntendedInvestmentAmount)

  const baseCurrency =
    (publicSettings && publicSettings.kyc_use_base_currency && publicSettings.base_currency) || "chf";

  const tokenToBaseCurrency =
    exchangeRates &&
    ethToFiat(
      baseCurrency,
      tokenToEth(values.amount, exchangeRates),
      exchangeRates
    );

  useEffect(() => {
    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      const autoSelect = INTENDED_INVESTMENT.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= exactIntendedInvestmentAmountValue
          : range[0] <= exactIntendedInvestmentAmountValue
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }
    }
    // eslint-disable-next-line
  }, [exactIntendedInvestmentAmountValue])

  const [amountValue, setAmountValue] = useState(values.amount)

  useEffect(() => {
    if (exchangeRates && publicSettings.token_type !== TOKEN_TYPES.NONE) {
      const amountToBaseCurrency = amountValue
        ? exchangeRates && ethToFiat(
          baseCurrency,
          tokenToEth(amountValue, exchangeRates),
          exchangeRates
        )
        : 0

      const autoSelect = INTENDED_INVESTMENT.find(option => {
        const range = option.match(/\d+/g).map(n => +n)

        return range.length === 2
          ? range[1] >= amountToBaseCurrency
          : range[0] <= amountToBaseCurrency
      })

      if (autoSelect && autoSelect !== values.intended_investment) {
        setValues({
          ...values,
          intended_investment: autoSelect
        });
      }
    }
    // eslint-disable-next-line
  }, [amountValue, exchangeRates])

  useEffect(() => {
    if (publicSettings && publicSettings.enable_swap_token && tokenSwap) {
      setValues({
        ...values,
        amount: `${tokenSwap.newTokenAmount}`,
      })

      setAmountValue(tokenSwap.newTokenAmount)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSettings, tokenSwap])

  const disabledInput = false;

  const addEtherWalletSetting =
    (publicSettings && publicSettings.add_ether_wallet_later_option) || false;

  const accepted_field_application_individual_fiat =
    Object.keys(publicSettings).length > 0
      ? publicSettings.kyc_levels[1].acceptedFieldIndividualFiat || {}
      : {};

  const accepted_field_application_individual_crypto =
    Object.keys(publicSettings).length > 0
      ? publicSettings.kyc_levels[1].acceptedFieldIndividualCrypto || {}
      : {};

  const fiatList = ["usd", "eur", "chf"];
  const cryptoList = ["eth", "usdc"];
  const acceptedCurrencies =
    (publicSettings && publicSettings.accepted_currencies) || {};

  const isShowingOccupation =
    (publicSettings && publicSettings.kyc_occupation) || false;

  const isShowingYearlySalary =
    (publicSettings && publicSettings.kyc_yearly_salary) || false;

  const isShowingEmployerCompanyName =
    (publicSettings && publicSettings.kyc_employer_company_name) || false;

  const isShowingEducationLevel =
    (publicSettings && publicSettings.kyc_education_level) || false;

  const isShowingPhoneNumber =
    (publicSettings && publicSettings.kyc_phone_number) || false;

  const isShowingTotalWealth =
    (publicSettings && publicSettings.kyc_net_investable_assets) || false;

  const isShowingWhatWillYouUse =
    (publicSettings && publicSettings.kyc_what_will_you_use) || false;

  const isShowPreferCurrency =
    (publicSettings && publicSettings.kyc_prefer_currency) || false;

  const isShowIntendedInvestment =
    (publicSettings && publicSettings.kyc_intended_investment) || false;

  const isShowSupportSourceOfFunds =
    (publicSettings && publicSettings.kyc_support_source_of_funds) || false;

  // const isShowSupportSourceOfWealth =
  //   (publicSettings && publicSettings.kyc_support_source_of_wealth) || false;

  const isShowReceivingWallet =
    (publicSettings && publicSettings.kyc_show_receiving_wallet) || false;

  const isShowIndustry =
    (publicSettings && publicSettings.kyc_require_industry) || false;

  const isShowingLegalProtectionInsurance =
    (publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && publicSettings.kyc_require_legal_protection_insurance) || false;

  const [checkboxValues, setCheckboxValues] = useState({
    IActOnMyOwnAccount: false,
    clientStatement: false,
    acknowledgementOfReceiptTerm1: false,
    acknowledgementOfReceiptTerm2: false,
    acknowledgementOfReceiptTerm3: false,
    confirmationTerms1: false,
    declarationOfConsent1: false,
    declarationOfConsent2: false,
    declarationOfConsent3: false,
    declarationOfConsent4: false,

    declarationOfConsent5: false,
    declarationOfConsent6: false,
    usExportControl: false,
    applicableLaw: false,
    placeOfJurisdiction: false,
    severability: false,
  });

  const [mainInfoValues, setMainInfoValues] = useState({
    ...reduce(mainInfoSchemas, mapSchema, {}),
    birthDate: customer.birthDate,
    email: customer.email,
    firstName: customer.first_name,
    lastName: customer.last_name,
    middleName: customer.middle_name,
    phoneNumber: customer.phoneNumber,
    uniqueId: customer.uniqueId,
    descriptionOfFunds: customer.descriptionOfFunds,
    descriptionOfWealth: customer.descriptionOfWealth,
    taxNumber: customer.taxNumber,
    taxOffice: customer.taxOffice,
    personType: customer.personType,
    duties: customer.duties,
    relationshipToPEP: customer.relationshipToPEP,
    companyName: customer.companyName || '',
    companyAddress: customer.companyAddress,
    companyRegisterNumber: customer.companyRegisterNumber,
    descriptionRegisterExtractBusinessActivity:
      customer.descriptionRegisterExtractBusinessActivity,
    authorizedPersonals: customer.authorizedPersonals,
    education_level: customer.education_level || '',
    occupation: customer.occupation || '',
    education_level_other: customer.education_level_other || '',
    occupation_other: customer.occupation_other || '',
    position: customer.position || '',
    position_other: customer.position_other || '',
    website: customer.website || '',
    industry: customer.industry || '',
    employer_company_name: customer.employer_company_name || '',
    yearly_salary: customer.yearly_salary || '',
    net_investable_assets: customer.net_investable_assets || '',
    what_will_you_use: customer.what_will_you_use || '',
    what_will_you_use_other: customer.what_will_you_use_other || '',
    exactIntendedInvestmentAmount: customer.exactIntendedInvestmentAmount || 0,
    industryOther: customer.industryOther || '',
  });

  const [addressValues, setAddressValues] = useState({
    ...reduce(addressInfoSchemas, mapSchema, {}),
    placeOfBirth: customer.placeOfBirth,
    nationality: customer.nationality,
    nationality_two: customer.nationality_two,
    nationality_three: customer.nationality_three,
    countryOfResidence: customer.countryOfResidence,
    residentialAddress: customer.residentialAddress,
    city: customer.city,
    postalCode: customer.postalCode,
  });

  const [showNationalityTwo, setShowNationalityTwo] = useState(!!customer.nationality_two);
  const [showNationalityThree, setShowNationalityThree] = useState(!!customer.nationality_three);
  const [disableSubmitVerify, setDisableSubmitVerify] = useState(false)

  const kycAcceptedWallets = useCallback(() => (
    publicSettings.kyc_accepted_wallets
      ? Object.entries(
        publicSettings.kyc_accepted_wallets
      ).filter((ele) => ele[1])
        .map((ele) => ele[0])
      : []
  ), [publicSettings])

  useEffect(() => {
    let userWalletType =
      userData.wallet_type && userData.wallet_type.toLowerCase();

    if (!userWalletType) {
      userWalletType = kycAcceptedWallets()[0];
    }

    setValues({ ...values, wallet: userWalletType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.wallet_type, kycAcceptedWallets]);

  let prospectusOrIssuingDate, prospectusOrIssuing;

  switch ((publicSettings || {}).prospectus_or_issuing) {
    case PROSPECTUS_OR_ISSUING.ISSUING:
      prospectusOrIssuing = "Issuing Guidelines";
      prospectusOrIssuingDate = moment(
        publicSettings.issuing_guidelines
      ).format("YYYY-MM-DD");
      break;
    case PROSPECTUS_OR_ISSUING.PROSPECTUS:
      prospectusOrIssuing = "Prospectus";
      prospectusOrIssuingDate = moment(publicSettings.prospectus_date).format(
        "YYYY-MM-DD"
      );
      break;
    default:
      prospectusOrIssuing = "Token Sale Agreement";
      prospectusOrIssuingDate = moment(
        publicSettings.token_sale_agreement_date
      ).format("YYYY-MM-DD");
      break;
  }

  const getDownloadUrl = (path) => getExternalFileUrl(path);

  const handleChange = (name, state, setState) => (event) => {
    setState({ ...state, [name]: event.target.value });

    if (name === 'exactIntendedInvestmentAmount') {
      setExactIntendedInvestmentAmountValue(event.target.value);
    }
  };

  const getIntendedInvestmentOptions = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return []
    }

    return Object.values(publicSettings.kyc_levels).filter((level) => !level.enable || level.enable.enable_lvl).map((level) => {
      if (level.enable && level.enable.enable_open_end) {
        return `> ${level.min_invest_amount} ${baseCurrency.toUpperCase()}`
      }
      return `${level.min_invest_amount} - ${level.max_invest_amount} ${baseCurrency.toUpperCase()}`
    })
  }

  let currentLevel = publicSettings.kyc_levels ? Object.values(publicSettings.kyc_levels).filter((item) => {
    return !item.enable || item.enable.enable_lvl;
  }).reduce((all, item) => all + (tokenToBaseCurrency >= item.min_invest_amount ? 1 : 0), 0) : 0;

  if (publicSettings.token_type === TOKEN_TYPES.NONE) {
    currentLevel = getIntendedInvestmentOptions().indexOf(values.intended_investment) + 1
  }

  const isDescriptionOfFundsRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return currentLevel > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return currentLevel > 1
    }

    return false
  }

  const isDescriptionOfWealthRequired = () => {
    if (!publicSettings || !publicSettings.kyc_levels) {
      return false
    }

    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      return currentLevel > 1
    }

    if ([TOKEN_TYPES.UTILITY, TOKEN_TYPES.EQUITY, TOKEN_TYPES.BOND].includes(publicSettings.token_type)) {
      return currentLevel > 1
    }

    return false
  }

  const [supportedSourceOfFundFiles, setSupportedSourceOfFundFiles] = useState([]);
  const [documentProofOfResidence, setDocumentProofOfResidence] = useState(null);

  const handleAmountChange = (e) => {
    const { value } = e.target;
    const amount = value ? String(parseInt(value)) : String(value);
    setValues({ ...values, amount });
    setAmountValue(amount);
  };

  const onChangeCheckbox = (name, state, setState) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const confirmWalletAndSend = async () => {
    setDisableSubmitVerify(true);
    const message = 'Please sign this message to confirm the ownership of provided wallet '

    try {
      await metamaskService.requestAccounts()

      const signature = await metamaskService.signMessage(
        metamaskService.getCurrentAddress(),
        message
      )
      const signerAddress = await metamaskService.getSigner(signature, message)

      if (signerAddress === values.tokenAddress.toLowerCase()) {
        setValues({...values, verified_wallet: signerAddress})
        toaster.success('The wallet have been verified.')
      } else {
        toaster.error('The wallet you provided does not match with the current wallet on Metamask.')
      }

      setDisableSubmitVerify(false);
    } catch (error) {
      switch (error.code) {
        case 4001:
          toaster.error('You need to select at least the account with the provided address to connect')
          break
        default:
          toaster.error(error.message)
          break
      }
      setDisableSubmitVerify(false);
    }
  }

  const onSend = () => {
    const supportedSourceOfFundUploadFiles = supportedSourceOfFundFiles.map(
      (fileValues) => {
        return fileValues.img;
      }
    );

    let inputData = {
      ...pick(mainInfoValues, [...Object.keys(mainInfoSchemas)]),
      ...pick(values, [
        "wallet",
        "sending_wallet",
        "tokenAddress",
        "tokenSendingAddress",
        "personType",
        "duties",
        "relationshipToPEP",
        "documentType",
        "amount",
        "currency",
        "intended_investment",
        "isAddEtherWalletLater",
        'has_legal_protection_insurance',
        "verified_wallet",
        "proof_of_residence_type",
      ]),
      exactIntendedInvestmentAmount: +values.exactIntendedInvestmentAmount,
      isAddEtherWalletLater: values.isAddEtherWalletLater || isFiat(values.currency),
      legal_protection_insurance: values.has_legal_protection_insurance
        ? mainInfoValues.legal_protection_insurance
        : '',
      policy_number: values.has_legal_protection_insurance
        ? mainInfoValues.policy_number
        : '',
    };

    if (kycType === CUSTOMER_TYPES.INDIVIDUAL) {
      inputData = {
        ...inputData,
        ...pick(values, [
          "sourceOfFunds",
          "sourceOfFundsOther",
          "sourceOfWealth",
          "sourceOfWealthOther",
        ]),
        supportedSourceOfFundUploadFiles: supportedSourceOfFundUploadFiles,
        documentProofOfResidence: (documentProofOfResidence && documentProofOfResidence.img) || null,
      };
    }

    if (addressInfoSchemas) {
      inputData = {
        ...inputData,
        ...pick(addressValues, [...Object.keys(addressInfoSchemas)]),
      };
    }

    updateCustomer({ variables: { id: customer.id, input: inputData } }).catch(() => {});
    ReactPixel.track("SubmitApplication");
  };

  const onDropDocumentProofOfResidence = (img, imgURI) => {
    const newImage = {
      img: img.length > 0 ? img[0] : null,
      imgURI: imgURI.length > 0 ? imgURI[0] : "",
    };
    setDocumentProofOfResidence(newImage);
  };

  const removeDropDocumentProofOfResidence = () => {
    setDocumentProofOfResidence(null);
  };

  const formatIntendedInvestment = (numberStr) => {
    return numberStr.replace(/\d+/g, (str) => numberWithCommas(str, 0));
  }

  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    type = "text",
    isRequired,
    helperText,
    state,
    setState,
    disabled = false,
    setter = null,
    labelPostfix = null,
    classWrapper = "",
    options = [],
    InputProps,
  } = {}) => {
    const errorsTexts = extractMsgFromErrByPropertyName(error, propertyName);

    let input = null;
    const isDisabledWithReopen = false;

    switch (type) {
      case "phone":
        input = (
          <>
            <PhoneInput
              defaultCountry="us"
              onChange={
                setter ||
                ((value) => {
                  setState({ ...state, [propertyName]: value });
                })
              }
              variant="outlined"
              disabled={disabledInput || isDisabledWithReopen}
              fullWidth
              value={state[propertyName] || ""}
            />
            <FormHelperText error={errorsTexts.length !== 0}>
              {helperText || (errorsTexts && errorsTexts.join(". ")) || ""}
            </FormHelperText>
          </>
        );
        break;
      case "select":
        input = (
          <TextField
            className="input-bordered"
            select
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            margin="none"
            variant="outlined"
            fullWidth
            error={errorsTexts.length !== 0}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={disabledInput || isDisabledWithReopen}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {propertyName === 'intended_investment' ? formatIntendedInvestment(option) : option}
              </MenuItem>
            ))}
          </TextField>
        );
        break;
      default:
        input = (
          <InputThemeLite
            value={state[propertyName] || ""}
            onChange={setter || handleChange(propertyName, state, setState)}
            margin="none"
            type={type}
            error={errorsTexts}
            helperText={
              helperText || (errorsTexts && errorsTexts.join(". ")) || ""
            }
            disabled={isDisabledWithReopen || disabled}
            fullWidth
            InputProps={InputProps}
          />
        );
        break;
    }

    return (
      <div className="field" key={propertyName}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        { label && (
          <div className="field__label" key={`label-${propertyName}`}>
            {label}

            {isRequired && <span className="text__red"> *</span>}
            {labelPostfix}
          </div>
        )}

        <div className={classNames('field__wrap', classWrapper)} key={propertyName}>
          {input}
        </div>
      </div>
    );
  };

  const renderInputArray = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    type,
    isRequired,
    state,
    setState,
  } = {}) => (
    <>
      <div className={classes.itemBar}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="input-item-label" key={`label-${propertyName}`}>
          {label}
          {isRequired && <span className="text-danger"> *</span>}
        </label>
        <div>
          <AddIcon
            className={classes.controlIcon}
            onClick={() => {
              if (state[propertyName].length < 15) {
                const newArray = state[propertyName];
                setState({ ...state, [propertyName]: [...newArray, ""] });
              }
            }}
          />
          <RemoveIcon
            className={classes.controlIcon}
            onClick={() => {
              if (state[propertyName].length > 1) {
                const newArray = state[propertyName].slice(
                  0,
                  state[propertyName].length - 1
                );
                setState({ ...state, [propertyName]: newArray });
              }
            }}
          />
        </div>
      </div>

      <div className="input-item" key={propertyName}>
        {state[propertyName].map((v, i) => {
          const errorsTexts = extractMsgFromErrByPropertyName(
            error,
            `${propertyName}.${i}`
          );

          return (
            <TextField
              id="outlined-name"
              // eslint-disable-next-line react/no-array-index-key
              key={`${propertyName}-${i}`}
              className={classNames("input-bordered", classes.inputText)}
              value={v || ""}
              onChange={(event) => {
                const arrayValues = state[propertyName];
                arrayValues[i] = event.target.value;

                setState({ ...state, [propertyName]: arrayValues });
              }}
              margin="none"
              type={type || "text"}
              error={errorsTexts.length !== 0}
              helperText={(errorsTexts && errorsTexts.join(". ")) || ""}
              variant="outlined"
              disabled={disabledInput}
              fullWidth
            />
          );
        })}
      </div>
    </>
  );

  const isDisabledProcessVerifyButton = () => {
    if (publicSettings && publicSettings.kyc_skip_legal_disclaimer) {
      return false
    }

    let tokenTypeCheckboxValues;
    // const tokenType = publicSettings && publicSettings.token_type;

    // if (tokenType === "UTILITY") {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     "IActOnMyOwnAccount",
    //     "acknowledgementOfReceiptTerm1",
    //     "acknowledgementOfReceiptTerm2",
    //     "acknowledgementOfReceiptTerm3",
    //     "confirmationTerms1",
    //     "declarationOfConsent1",
    //     "declarationOfConsent2",
    //     "declarationOfConsent3",
    //     "declarationOfConsent4",
    //   ]);
    // } else if (tokenType === "NONE") {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     "acknowledgementOfReceiptTerm1",
    //     "acknowledgementOfReceiptTerm2",
    //     "acknowledgementOfReceiptTerm3",
    //     "declarationOfConsent2",
    //     "declarationOfConsent5",
    //     "declarationOfConsent6",
    //   ]);
    // } else if (tokenType === TOKEN_TYPES.LIQUIDATION) {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     'IActOnMyOwnAccount',
    //     'acknowledgementOfReceiptTerm1',
    //     'acknowledgementOfReceiptTerm2',
    //     'acknowledgementOfReceiptTerm3',
    //     'confirmationTerms1',
    //     'declarationOfConsent5',
    //     'declarationOfConsent6',
    //   ]);
    // } else {
    //   tokenTypeCheckboxValues = omit(checkboxValues, [
    //     "declarationOfConsent5",
    //     "declarationOfConsent6",
    //   ]);
    // }

    // if (!publicSettings.kyc_acknowledgement_of_receipt) {
    //   tokenTypeCheckboxValues = omit(tokenTypeCheckboxValues, [
    //     'IActOnMyOwnAccount'
    //   ]);
    // }

    if (publicSettings.kyc_client_statement) {
      tokenTypeCheckboxValues = pick(checkboxValues, [
        'clientStatement'
      ]);
    }

    if (values.referal_code === 'BlackMantaCapital') {
      tokenTypeCheckboxValues = pick(checkboxValues, [
        'declarationOfConsent4'
      ]);
    }
    // if (!publicSettings.kyc_confirmations) {
    //   tokenTypeCheckboxValues = omit(tokenTypeCheckboxValues, [
    //     'confirmationTerms1',
    //   ]);
    // }

    const currentHost = window.location.host;
    const isAwearetech = currentHost === 'kyc.awearetech.li' || currentHost === 'aweare-dev.investhub.io' || currentHost === 'localhost:3000';

    if (isAwearetech) {
      tokenTypeCheckboxValues = tokenTypeCheckboxValues || {}
      tokenTypeCheckboxValues["usExportControl"] = checkboxValues.usExportControl
      tokenTypeCheckboxValues["applicableLaw"] = checkboxValues.applicableLaw
      tokenTypeCheckboxValues["placeOfJurisdiction"] = checkboxValues.placeOfJurisdiction
      tokenTypeCheckboxValues["severability"] = checkboxValues.severability
    }

    return !!Object.keys(tokenTypeCheckboxValues).find(
      (v) => !tokenTypeCheckboxValues[v]
    );
  };

  const helperTextForWalletCompliant = () => {
    switch (values.wallet) {
      case "eth":
        return (
          <>
            {t('Note')}:{' '}
            {t('Address should be ERC20-compliant.')}
          </>
        );
      case "ixo_id":
        return (
          <>
            {t('Note')}:{' '}
            {t('Address should be IXO-ID-compliant.')}
          </>
        );
      case "cosmos":
        return (
          <>
            {t('Note')}:{' '}
            {t('Address should be Cosmos-compliant.')}
          </>
        );
      default:
        return '';
    }
  };

  const renderEqualTo = () => {
    switch (values.currency) {
      case "eth":
        return (
          <h4>
            {t('Equals')} {numberWithCommas(tokenToEth(values.amount, exchangeRates))}{" "}
            ETH
          </h4>
        );
      case "usdc":
        return (
          <h4>
            {t('Equals')} {numberWithCommas(tokenToUsdc(values.amount, exchangeRates))}{" "}
            USDC
          </h4>
        );
      default:
        return (
          <h4>
            {t('Equals')}{" "}
            {numberWithCommas(
              ethToFiat(
                values.currency,
                tokenToEth(values.amount, exchangeRates),
                exchangeRates
              )
            )}{" "}
            {(values.currency || "").toUpperCase()}
          </h4>
        );
    }
  };

  // const setGeneratedWallet = (generatedWallet) => {
  //   setValues({
  //     ...values,
  //     generatedWallet,
  //     tokenAddress: generatedWallet,
  //     wallet: !values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet,
  //   })
  // }

  const renderPaymentsForm = () => {
    const minimumInvestAmount =
      (publicSettings && publicSettings.minimum_investment_amount) || 15000;

    let prospectusOrIssuingDate, prospectusOrIssuing, paperLink;

    switch ((publicSettings || {}).prospectus_or_issuing) {
      case PROSPECTUS_OR_ISSUING.ISSUING:
        prospectusOrIssuing = "Issuing Guidelines";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).issuing_guidelines_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.issuing_guidelines;
        break;
      case PROSPECTUS_OR_ISSUING.PROSPECTUS:
        prospectusOrIssuing = "Prospectus label";
        prospectusOrIssuingDate = moment((publicSettings || {}).prospectus_date).format(
          "YYYY-MM-DD"
        );
        paperLink = publicSettings.prospectus_url;
        break;
      case PROSPECTUS_OR_ISSUING.TOKEN_OFFERING_MEMORANDUM:
        prospectusOrIssuing = "Token Sale Agreement";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).memorandum
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.memorandum_url;
        break;
      default:
        prospectusOrIssuing = "Token Sale Agreement";
        prospectusOrIssuingDate = moment(
          (publicSettings || {}).token_sale_agreement_date
        ).format("YYYY-MM-DD");
        paperLink = publicSettings.token_sale_agreement_url;
        break;
    }

    const renderHelperMinimumText = () => {
      return <>
        {t('The minimum CONFIG_SUBSCRIPTION amount per investor is BASE_CURRENCY MINIMUMINVESTAMOUNT or the equivalent in ', {
          CONFIG_SUBSCRIPTION: textConfig.subscription,
          BASE_CURRENCY: baseCurrency.toUpperCase(),
          MINIMUMINVESTAMOUNT: minimumInvestAmount,
          })}
        {' '}
        {[...fiatList, ...cryptoList]
          .filter((name) => acceptedCurrencies[name])
          .map((name) => name.toUpperCase())
          .join("/")
        }
        {' '}
        {publicSettings && publicSettings.token_type !== "UTILITY"
          ? <>
              {t('according to the')}
              {' '}
              {paperLink ? <a href={paperLink} target="_blank" rel="noopener noreferrer" className="text__primary">{t(prospectusOrIssuing)}</a> : prospectusOrIssuing}
              {' '}
              {t('dated')}
              {' '}
              {prospectusOrIssuingDate}
            </>
          : t('according. All purchases are subject to the specific terms of the Token Purchase Agreement.')
        }
      </>
    }

    return typeof exchangeRates === "undefined" ? null : (
      <div className="settings__list">
        <div className="card__head card__head-kyc-1">
          {publicSettings && publicSettings.token_type === "UTILITY" ? (
            <>
              <div className="title-primary card__title">{ t('Purchase Order') }</div>

              <div className="refund__content card__head-content">
                <p>{ t('I wish to purchase tokens to the value I have selected below. I understand that this purchase must be paid for within 10 days of my order being accepted and after I have signed a Token Purchase Agreement. If I do not send my payment within this period, my order will be cancelled.') }</p>
              </div>
            </>
          ) : (
            <>
              <div className="title-primary card__title">{ t('Payment')}</div>

              <div className="refund__content card__head-content">
                <p>
                  {t('At the same time as the CONFIGSUBSCRIPTION is accepted, the CONFIGSUBSCRIBER is requested to transfer the CONFIGSUBSCRIPTION amount to the following special account of the CONFIGISSUER within 10 days', {
                    CONFIGSUBSCRIPTION: textConfig.subscription,
                    CONFIGSUBSCRIBER: textConfig.subscriber,
                    CONFIGISSUER: textConfig.issuer
                  })}:
                </p>
              </div>
            </>
          )}
        </div>

        <div className="settings__item settings__fieldset">
          {publicSettings && publicSettings.token_type !== "NONE" && (
            <>
              <div className="field">
                {publicSettings && publicSettings.token_type === "UTILITY" ? (
                  <div className="field__label">{t('My order to purchase tokens as “the Purchaser”')}</div>
                ) : (
                  <div className="field__label">
                    {t('here with the subscribe (the “CONFIGSUBSCRIBER”)', {CONFIGSUBSCRIBER: textConfig.subscriber})}: {t('in')}
                    {[...fiatList, ...cryptoList]
                      .filter((name) => acceptedCurrencies[name])
                      .map((name) => name.toUpperCase())
                      .join("/")}
                  </div>
                )}
              </div>

              {publicSettings &&
                publicSettings.token_type !== TOKEN_TYPES.NONE &&
                publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION &&
                tokenSwap && (
                  <div className="settings__row flex__row flex__wrap">
                    {renderInputField({
                      propertyName: "amount",
                      type:
                        publicSettings.token_type === TOKEN_TYPES.BOND
                          ? "text"
                          : "number",
                      label: publicSettings && publicSettings.old_token_symbol
                        ? `${publicSettings.old_token_symbol} amount`
                        : 'Old token amount',
                      isRequired: true,
                      state: {amount: tokenSwap.oldTokenAmount},
                      disabled: true
                    })}
                  </div>
                )
              }
              <div className="settings__row flex__row flex__wrap">
                {renderInputField({
                  propertyName: "amount",
                  type:
                    publicSettings.token_type === TOKEN_TYPES.BOND
                      ? "text"
                      : "number",
                  label: publicSettings.enable_swap_token && tokenSwap
                      ? `${publicSettings.token_symbol} amount`
                      : t('Token amount'),
                  isRequired: true,
                  disabled: publicSettings.enable_swap_token && tokenSwap,
                  helperText: renderHelperMinimumText(),
                  state: values,
                  setState: setValues,
                  setter: handleAmountChange,
                })}

                {values.amount && (
                  <div className="field d__flex flex__row flex__wrap flex__justify_start flex__align_center">
                    <div className="create__line remove__line">
                      <div className="create__info text__primary">
                        {renderEqualTo()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {publicSettings && publicSettings.token_type === "NONE" && publicSettings.require_exact_investment_amount_on_none && (
            <>
              {renderInputField({
                propertyName: "exactIntendedInvestmentAmount",
                type: "number",
                label: t('Exact investment amount'),
                isRequired: true,
                state: values,
                setState: setValues,
                InputProps: {
                  endAdornment: <InputAdornment position="end">
                    <strong>{publicSettings.kyc_use_base_currency ? (publicSettings.base_currency.toUpperCase() || 'ETH') : 'CHF'}</strong>
                  </InputAdornment>,
                }
              })}
            </>
          )}

          {isShowIntendedInvestment && (
            <>
              <DropdownMaterial
                label={t('Intended investment')}
                state={values}
                setState={setValues}
                propertyName="intended_investment"
                isRequired
              >
                {INTENDED_INVESTMENT.map((option) => (
                  <MenuItem key={option} value={(option || "")}>
                    {formatIntendedInvestment(option)}
                  </MenuItem>
                ))}

              </DropdownMaterial>
            </>
          )}

          {isShowPreferCurrency && (
            <div className="field">
              <div className="field__label">{t('My preferred payment currency')}</div>

              <div className="field__wrap d__flex flex__row flex__wrap">
                {[...cryptoList, ...fiatList]
                  .filter((name) => acceptedCurrencies[name])
                  .map((name) => (
                    <RadioTheme
                      key={name}
                      nameGroup="updateKycVerificationFormCurrency"
                      isGrayText
                      isRadioGroupHorizontal
                      keyIndex={name}
                      radioText={name.toUpperCase()}
                      value={name}
                      onChange={() => setValues({ ...values, currency: name })}
                      defaultChecked={name === values.currency}
                    />
                  ))}
              </div>
            </div>
          )}

          {isShowReceivingWallet && (
            <>
              {kycAcceptedWallets().length > 0 && (
                <DropdownMaterial
                  label={t('Select a Wallet Address type')}
                  state={values}
                  setState={setValues}
                  propertyName="wallet"
                >
                  {kycAcceptedWallets().map((option) => (
                    <MenuItem key={option} value={(option)}>
                      {t(option.toUpperCase())}
                    </MenuItem>
                  ))}
                </DropdownMaterial>
              )}
            </>
          )}

          {isShowReceivingWallet && (
            <>
              {!publicSettings.force_generate_wallet && renderInputField({
                propertyName: "tokenAddress",
                type: "text",
                label: t('Receiving wallet address'),
                isRequired: (publicSettings || {}).enable_cashlink,
                state: values,
                setState: setValues,
              })}
              {publicSettings.force_generate_wallet && `${t('Your wallet')}: ${values.tokenAddress}`}

              {/* removed this since at data-center we don't have feature to remove at the moment {publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && (
                <WalletRequest
                  type={!values.wallet && kycAcceptedWallets().includes('eth') ? 'eth' : values.wallet}
                  generatedWallet={values.generatedWallet}
                  onComplete={setGeneratedWallet}
                />
              )} */}
              {publicSettings && publicSettings.show_verify_wallet ?
                (values.tokenAddress && values.verified_wallet === values.tokenAddress.toLowerCase()) ?
                  <span className="text-green">{t('This address have been verified.')}</span> :
                  <button
                    type="button"
                    className="button"
                    onClick={confirmWalletAndSend}
                    disabled={disableSubmitVerify}
                  >
                    { t('Verify this wallet') }
                  </button>
                : ''
              }

            </>
          )}

          {!isFiat(values.currency) && (
            <>
              {addEtherWalletSetting ? (
                <div className="create__line">
                  <div className="reate__info item__flex_1">
                    {t('I will add my Sending Wallet address later through my profile')}
                  </div>

                  <label className="switch">
                    <input
                      type="checkbox"
                      className="switch__input"
                      checked={values.isAddEtherWalletLater}
                      onChange={onChangeCheckbox(
                        "isAddEtherWalletLater",
                        values,
                        setValues
                      )}
                      value="isAddEtherWalletLater"
                    />

                    <div className="switch__inner">
                      <div className="switch__box">{' '}</div>
                    </div>
                  </label>
                </div>
              ) : null}

              {isCrypto(values.currency) && (
                <DropdownMaterial
                  label={ t('Select sending wallet') }
                  state={values}
                  setState={setValues}
                  propertyName="sending_wallet"
                  isRequired={!values.isAddEtherWalletLater}
                >
                  {getSendingWalletTypeOptions(values.currency).map((option) => (
                    <MenuItem key={option[0]} value={option[0]}>
                      {option[1]}
                    </MenuItem>
                  ))}
                </DropdownMaterial>
              )}

              {renderInputField({
                propertyName: "tokenSendingAddress",
                type: "text",
                label: t('My Sending Address or ID'),
                isRequired: !values.isAddEtherWalletLater,
                helperText: (publicSettings && !publicSettings.hide_wallet_format_note && helperTextForWalletCompliant()) || undefined,
                state: values,
                setState: setValues,
                disabled: values.isAddEtherWalletLater,
                labelPostfix: (publicSettings || {})
                  .source_of_address_for_sending_tokens_example_path && (
                  <a
                    className={classes.sourceOfFundsExample}
                    href={getDownloadUrl(
                      publicSettings.source_of_address_for_sending_tokens_example_path
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {"   "}
                    { t('Show Example') }
                  </a>
                ),
              })}
            </>
          )}
        </div>

        <div className="settings__item">{' '}</div>
      </div>
    );
  };

  const onDropSupportedSourceOfFundFiles = (img, imgURI) => {
    const newImage = {
      img: img.length > 0 ? img[0] : null,
      imgURI: imgURI.length > 0 ? imgURI[0] : "",
    };
    setSupportedSourceOfFundFiles([...supportedSourceOfFundFiles, newImage]);
  };

  const removeDropSupportedSourceOfFundFiles = (index) => {
    const newSupportedSourceOfFundFiles = [...supportedSourceOfFundFiles];
    const removedEle = remove(newSupportedSourceOfFundFiles, (file, indx) => {
      return indx === index;
    });

    if (removedEle) {
      setSupportedSourceOfFundFiles(newSupportedSourceOfFundFiles);
    }
  };

  const renderPersonalDetailForm = () => (
    <>
      <div className="settings__item settings__field">
        <div className="settings__row flex__row flex__wrap have__fields">
          {Object.keys(mainInfoSchemas).map((key) => {
            if (mainInfoSchemas[key].type === "array") {
              return mainInfoSchemas[key].adminOnly ? null : (
                <React.Fragment key={key}>
                  {renderInputArray({
                    propertyName: key,
                    type: mainInfoSchemas[key].type || "text",
                    label: t(mainInfoSchemas[key].label) || "",
                    isRequired: mainInfoSchemas[key].isRequired || false,
                    state: mainInfoValues,
                    setState: setMainInfoValues,
                  })}
                </React.Fragment>
              );
            }

            if (isFiat(values.currency)) {
              if (
                !accepted_field_application_individual_fiat[key] &&
                accepted_field_application_individual_fiat[key] !== undefined
              ) {
                return null;
              }
            }
            if (!isFiat(values.currency)) {
              if (
                !accepted_field_application_individual_crypto[key] &&
                accepted_field_application_individual_crypto[key] !== undefined
              ) {
                return null;
              }
            }

            if (mainInfoSchemas[key].type === "date") {
              const errorsTexts = extractMsgFromErrByPropertyName(error, key);

              return mainInfoSchemas[key].adminOnly ? null : (
                <div className="field" key={key}>
                  <div className="field__label">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    {t(mainInfoSchemas[key].label)}
                    <span className="text__red"> *</span>
                  </div>

                  <div className="field__wrap field__wrap-date">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        views={["year", "month", "date"]}
                        openTo="year"
                        disableFuture
                        variant="outlined"
                        format="dd.MM.yyyy"
                        margin="none"
                        className={classes.input_border_date}
                        id="date-picker-dialog"
                        value={mainInfoValues[key]}
                        error={errorsTexts.length !== 0}
                        invalidDateMessage={t('Invalid Date Format')}
                        helperText={
                          (errorsTexts && errorsTexts.join(". ")) || ""
                        }
                        onChange={(date) =>
                          setMainInfoValues({ ...mainInfoValues, [key]: date })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
              );
            }

            if (
              key === "descriptionOfFunds"
            ) {
              if (!isDescriptionOfFundsRequired()) {
                return null;
              }
              const postfix = (publicSettings || {})
                .source_of_funds_example_path && (
                <a
                  className={classes.sourceOfFundsExample}
                  href={getDownloadUrl(
                    publicSettings.source_of_funds_example_path
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"   "}
                  { t('Show Example') }
                </a>
              );
              return mainInfoSchemas[key].adminOnly ? null : (
                <React.Fragment key={key}>
                  {renderInputField({
                    propertyName: key,
                    type: mainInfoSchemas[key].type || "text",
                    label: t(mainInfoSchemas[key].label) || "",
                    isRequired: mainInfoSchemas[key].isRequired || false,
                    state: mainInfoValues,
                    setState: setMainInfoValues,
                    labelPostfix: postfix,
                  })}
                </React.Fragment>
              );
            }

            if (
              key === "descriptionOfWealth"
            ) {
              if (!isDescriptionOfWealthRequired()) {
                return null;
              }
              return mainInfoSchemas[key].adminOnly ? null : (
                <React.Fragment key={key}>
                  {renderInputField({
                    propertyName: key,
                    type: mainInfoSchemas[key].type || "text",
                    label: t(mainInfoSchemas[key].label) || "",
                    isRequired: mainInfoSchemas[key].isRequired || false,
                    state: mainInfoValues,
                    setState: setMainInfoValues,
                  })}
                </React.Fragment>
              );
            }

            if (mainInfoSchemas[key].id_name === "yearly_salary") {
              if (isShowingYearlySalary) {
                return (
                  <DropdownMaterial
                    key={key}
                    label={t(mainInfoSchemas[key].label) || ""}
                    isRequired={mainInfoSchemas[key].isRequired || false}
                    state={mainInfoValues}
                    setState={setMainInfoValues}
                    propertyName="yearly_salary"
                  >
                    {mainInfoSchemas[key].options.map((option) => (
                      <MenuItem key={option} value={(option.replaceAll('CHF', baseCurrency.toUpperCase()))}>
                        {t(option.replaceAll('CHF', baseCurrency.toUpperCase()))}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "education_level") {
              if (isShowingEducationLevel) {
                return (
                  <DropdownMaterial
                    key={key}
                    label={t(mainInfoSchemas[key].label) || ""}
                    isRequired={mainInfoSchemas[key].isRequired || false}
                    state={mainInfoValues}
                    setState={setMainInfoValues}
                    propertyName="education_level"
                  >
                    {mainInfoSchemas[key].options.map((option) => (
                      <MenuItem key={option} value={(option)}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "education_level_other") {
              if (isShowingEducationLevel && mainInfoValues.education_level === 'Other') {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "occupation") {
              if (isShowingOccupation) {
                return (
                  <DropdownMaterial
                    key={key}
                    label={t(mainInfoSchemas[key].label) || ""}
                    isRequired={mainInfoSchemas[key].isRequired || false}
                    state={mainInfoValues}
                    setState={setMainInfoValues}
                    propertyName="occupation"
                  >
                    {mainInfoSchemas[key].options.map((option) => (
                      <MenuItem key={option} value={(option)}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "occupation_other") {
              if (isShowingOccupation && mainInfoValues.occupation === 'Other') {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (key === "industry") {
              if (isShowIndustry) {
                return (
                  <DropdownMaterial
                    key={key}
                    propertyName={key}
                    label={t(mainInfoSchemas[key].label) || ""}
                    isRequired={mainInfoSchemas[key].isRequired || false}
                    state={mainInfoValues}
                    setState={setMainInfoValues}
                  >
                    {INDUSTRY_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {t(option)}
                        {option === t('Other') && t(' (please describe)')}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                );
              } else {
                return null;
              }
            }

            if (key === "industryOther") {
              if (isShowIndustry && mainInfoValues.industry === 'Other') {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "employer_company_name") {
              if (isShowingEmployerCompanyName) {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "position") {
              if (publicSettings && publicSettings.kyc_show_position) {
                return (
                  <DropdownMaterial
                    key={key}
                    label={t(mainInfoSchemas[key].label) || ""}
                    isRequired={mainInfoSchemas[key].isRequired || false}
                    state={mainInfoValues}
                    setState={setMainInfoValues}
                    propertyName="position"
                  >
                    {mainInfoSchemas[key].options.map((option) => (
                      <MenuItem key={option} value={(option)}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "position_other") {
              if (publicSettings && publicSettings.kyc_show_position && mainInfoValues.position === 'Other') {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (key === "website") {
              if (publicSettings && publicSettings.kyc_show_website) {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: currentLevel && currentLevel > 2,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "net_investable_assets") {
              if (isShowingTotalWealth) {
                return (
                  <DropdownMaterial
                    key={key}
                    propertyName="net_investable_assets"
                    label={t(mainInfoSchemas[key].label) || ""}
                    isRequired={mainInfoSchemas[key].isRequired || false}
                    state={mainInfoValues}
                    setState={setMainInfoValues}
                  >
                    {NET_INVESTABLE_ASSETS_OPTIONS.map((option) => (
                      <MenuItem key={option} value={option.replaceAll('CHF', baseCurrency.toUpperCase())}>
                        {t(option.replaceAll('CHF', baseCurrency.toUpperCase()))}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "what_will_you_use") {
              if (isShowingWhatWillYouUse) {
                return (
                  <React.Fragment key={key}>
                    <DropdownMaterial
                      propertyName={key}
                      label={t('What is the purpose of your token purchase?')}
                      isRequired={mainInfoSchemas[key].isRequired || false}
                      state={mainInfoValues}
                      setState={setMainInfoValues}
                    >
                      {whatWillYouUseOptions.map((option) => (
                        <MenuItem key={option} value={(option)}>
                          {t(option)}
                        </MenuItem>
                      ))}
                    </DropdownMaterial>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (mainInfoSchemas[key].id_name === "what_will_you_use_other") {
              if (mainInfoValues.what_will_you_use === 'Other') {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (key === 'phoneNumber') {
              if (isShowingPhoneNumber) {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      classWrapper:"field__wrap-phone",
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (key === "legal_protection_insurance") {
              if (isShowingLegalProtectionInsurance) {
                return (
                  <React.Fragment key={key}>
                    <div className="field">
                      <SwitchCheckboxTheme
                        defaultChecked={values.has_legal_protection_insurance}
                        onChange={({ target: { checked }}) => setValues({
                          ...values,
                          has_legal_protection_insurance: checked
                        })}
                        value="IActOnMyOwnAccount"
                        switchText="I have legal protection insurance"
                        isRemoveLine
                        isGrayText
                        isLeftSwitch
                      />
                    </div>

                    {values.has_legal_protection_insurance && renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      classWrapper:"field__wrap-phone",
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            if (key === "policy_number") {
              if (isShowingLegalProtectionInsurance && values.has_legal_protection_insurance) {
                return (
                  <React.Fragment key={key}>
                    {renderInputField({
                      propertyName: key,
                      type: mainInfoSchemas[key].type || "text",
                      label: t(mainInfoSchemas[key].label) || "",
                      isRequired: mainInfoSchemas[key].isRequired || false,
                      classWrapper:"field__wrap-phone",
                      state: mainInfoValues,
                      setState: setMainInfoValues,
                    })}
                  </React.Fragment>
                );
              } else {
                return null;
              }
            }

            return mainInfoSchemas[key].adminOnly ? null : (
              <React.Fragment key={key}>
                {renderInputField({
                  propertyName: key,
                  type: mainInfoSchemas[key].type || "text",
                  label: t(mainInfoSchemas[key].label) || "",
                  isRequired: mainInfoSchemas[key].isRequired || false,
                  state: mainInfoValues,
                  setState: setMainInfoValues,
                })}
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {kycType === CUSTOMER_TYPES.INDIVIDUAL && (
        <>
          {isFiat(values.currency) &&
            accepted_field_application_individual_fiat.descriptionOfFunds && (
              <>
                <div className="card__head" style={{ display: "none"}}>
                  <div className="title-primary settings__title nospace__bottom">
                    { t('Primary source of funds for this purchase') }
                    {' '}
                    {extractMsgFromErrByPropertyName(error, "sourceOfFunds").length !== 0 && (
                      <>
                        {(extractMsgFromErrByPropertyName(error, "sourceOfFunds") &&
                        extractMsgFromErrByPropertyName(
                          error,
                          "sourceOfFunds"
                        ).join(". ")) ||
                        ""}
                      </>
                    )}
                  </div>
                </div>

                <KycVerificationFormSourceOfFunds
                  values={values}
                  setValues={setValues}
                  loading={loading}
                  handleChange={handleChange}
                  disabled={false}
                />

                <div className="space space__32">{' '}</div>
              </>
            )}

          {!isFiat(values.currency) &&
            accepted_field_application_individual_crypto.descriptionOfFunds && (
              <>
                <div className="card__head" style={{ display: "none"}}>
                  <div className="refund__content card__head-content">
                    { t('Primary source of funds for this purchase') }
                    <br />
                    {extractMsgFromErrByPropertyName(error, "sourceOfFunds").length !== 0 && (
                      <>
                        {(extractMsgFromErrByPropertyName(error, "sourceOfFunds") &&
                        extractMsgFromErrByPropertyName(
                          error,
                          "sourceOfFunds"
                        ).join(". ")) ||
                        ""}
                      </>
                    )}
                  </div>
                </div>

                <KycVerificationFormSourceOfFunds
                  values={values}
                  setValues={setValues}
                  loading={loading}
                  handleChange={handleChange}
                  disabled={false}
                />

                <div className="space space__32">{' '}</div>
              </>
            )}
        </>
      )}

      {kycType === CUSTOMER_TYPES.INDIVIDUAL && (
        <>
          {isFiat(values.currency) &&
            accepted_field_application_individual_fiat.descriptionOfWealth && (
              <>
                <div className="card__head" style={{ display: "none"}}>
                  <div className="title-primary settings__title nospace__bottom">
                    { t('Primary source of wealth for this purchase') }
                    {' '}
                    {extractMsgFromErrByPropertyName(error, "sourceOfWealth").length !== 0 && (
                      <>
                        {(extractMsgFromErrByPropertyName(error, "sourceOfWealth") &&
                        extractMsgFromErrByPropertyName(
                          error,
                          "sourceOfWealth"
                        ).join(". ")) ||
                        ""}
                      </>
                    )}
                  </div>
                </div>

                <KycVerificationFormSourceOfWealth
                  values={values}
                  setValues={setValues}
                  loading={loading}
                  handleChange={handleChange}
                  disabled={false}
                />

                <div className="space space__32">{' '}</div>
              </>
            )}

          {!isFiat(values.currency) &&
            accepted_field_application_individual_crypto.descriptionOfWealth && (
              <>
                <div className="card__head" style={{ display: "none" }}>
                  <div className="refund__content card__head-content">
                    { t('Primary source of wealth for this purchase') }
                    <br />
                    {extractMsgFromErrByPropertyName(error, "sourceOfWealth").length !== 0 && (
                      <>
                        {(extractMsgFromErrByPropertyName(error, "sourceOfWealth") &&
                        extractMsgFromErrByPropertyName(
                          error,
                          "sourceOfWealth"
                        ).join(". ")) ||
                        ""}
                      </>
                    )}
                  </div>
                </div>

                <KycVerificationFormSourceOfWealth
                  values={values}
                  setValues={setValues}
                  loading={loading}
                  handleChange={handleChange}
                  disabled={false}
                />

                <div className="space space__32">{' '}</div>
              </>
            )}
        </>
      )}

      <KycFormDocumentProofOfResidence
        publicSettings={publicSettings}
        onDropDocumentProofOfResidence={onDropDocumentProofOfResidence}
        documentProofOfResidence={documentProofOfResidence}
        removeDropDocumentProofOfResidence={removeDropDocumentProofOfResidence}
        currency={values.currency}
        currentLevel={currentLevel}
        values={values}
        setValues={setValues}
      />

      {isShowSupportSourceOfFunds && (
        <KycFormSupportedSourceOfFund
          tokenToBaseCurrency={tokenToBaseCurrency}
          publicSettings={publicSettings}
          onDropSupportedSourceOfFundFiles={onDropSupportedSourceOfFundFiles}
          supportedSourceOfFundFiles={supportedSourceOfFundFiles}
          removeDropSupportedSourceOfFundFiles={
            removeDropSupportedSourceOfFundFiles
          }
          intendedInvestment={values.intended_investment}
        />
      )}

      <div className="settings__item settings__field">
        <div className="card__head">
          <div className="title-primary settings__title nospace__bottom">
            {t('Your address')}
          </div>
        </div>
        <div className="settings__row flex__row flex__wrap have__fields ">
          {addressInfoSchemas &&
            Object.keys(addressInfoSchemas).map((key) => {
              if (key === "nationality") {
                return (
                  <DropdownMaterial
                    key={key}
                    propertyName={key}
                    label={t(addressInfoSchemas[key].label) || ""}
                    state={addressValues}
                    setState={setAddressValues}
                    helperText={
                      <>
                        {!showNationalityTwo && (
                          <>
                            <span className="help__item help__item_blue help__item-nospace_x" onClick={() => setShowNationalityTwo(true)}>
                              {t('Add new nationality')}
                            </span>
                          </>
                        )}
                      </>
                    }
                  >
                    {nationalityList.map((option) => (
                      <MenuItem key={option} value={(option || "")}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                )
              }

              if (key === "countryOfResidence") {
                return (
                  <DropdownMaterial
                    key={key}
                    propertyName={key}
                    label={t(addressInfoSchemas[key].label) || ""}
                    state={addressValues}
                    setState={setAddressValues}
                  >
                    {countryList.map((option) => (
                      <MenuItem key={option} value={(option || "")}>
                        {t(option)}
                      </MenuItem>
                    ))}
                  </DropdownMaterial>
                )
              }

              if (key === "nationality_two") {
                return (
                  <>
                    { showNationalityTwo &&
                      <DropdownMaterial
                        key={key}
                        propertyName={key}
                        label={t(addressInfoSchemas[key].label) || ""}
                        state={addressValues}
                        setState={setAddressValues}
                        suffixLabel={
                          <>
                            <span className="help__item help__item_red help__item-nospace_x help__item-nospace_y" onClick={() => setShowNationalityTwo(false)}>
                              {t('Remove')}
                            </span>
                          </>
                        }
                        helperText={
                          <>
                            {showNationalityTwo && !showNationalityThree && (
                              <>
                                <span className="help__item help__item_blue help__item-nospace_x" onClick={() => setShowNationalityThree(true)}>
                                  {t('Add new nationality')}
                                </span>
                              </>
                            )}
                          </>
                        }
                      >
                        {nationalityList.map((option) => (
                          <MenuItem key={option} value={(option || "")}>
                            {t(option)}
                          </MenuItem>
                        ))}
                      </DropdownMaterial>
                    }
                  </>
                )
              }

              if (key === "nationality_three") {
                return (
                  <>
                    { showNationalityThree &&
                      <div className="field" key={key}>
                        <DropdownMaterial
                          key={key}
                          propertyName={key}
                          label={t(addressInfoSchemas[key].label) || ""}
                          state={addressValues}
                          setState={setAddressValues}
                          suffixLabel={
                            <>
                              <span className="help__item help__item_red help__item-nospace_x help__item-nospace_y" onClick={() => setShowNationalityThree(false)}>
                                {t('Remove')}
                              </span>
                            </>
                          }
                        >
                          {nationalityList.map((option) => (
                            <MenuItem key={option} value={(option || "")}>
                              {t(option)}
                            </MenuItem>
                          ))}
                        </DropdownMaterial>
                      </div>
                    }
                  </>
                )
              }

              return (
                <React.Fragment key={key}>
                  {renderInputField({
                    propertyName: key,
                    type: addressInfoSchemas[key].type || "text",
                    label: t(addressInfoSchemas[key].label) || "",
                    isRequired: addressInfoSchemas[key].isRequired || false,
                    state: addressValues,
                    setState: setAddressValues,
                  })}
                </React.Fragment>
              );
            })}
        </div>
      </div>
      <div className="settings__item settings__field">{' '}</div>
    </>
  );

  const renderTermsAndConditions = () => (
    <>
      {publicSettings && !publicSettings.kyc_skip_legal_disclaimer && (
        <KycVerificationFormDisclaimer
          publicSettings={publicSettings}
          kycType={kycType}
          textConfig={textConfig}
          checkboxValues={checkboxValues}
          onChangeCheckbox={onChangeCheckbox}
          setCheckboxValues={setCheckboxValues}
          loading={loading}
          values={values}
          setValues={setValues}
          addressValues={addressValues}
          customer={{
            first_name: mainInfoValues.firstName,
            middle_name: mainInfoValues.middleName,
            last_name: mainInfoValues.lastName,
          }}
          prospectusOrIssuingDate={prospectusOrIssuingDate}
          prospectusOrIssuing={prospectusOrIssuing}
          handleChange={(field, state, setState) => ({ target: { value } }) => {
            console.log(value, state)
            setState({ ...state, [field]: value })
          }}
          error={error}
          onSend={onSend}
        />
      )}

      {loading ? (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <button
          disabled={isDisabledProcessVerifyButton()}
          onClick={onSend}
          type="button"
          className="button"
        >
          { t('Process for Verify') }
        </button>
      )}
    </>
  );

  if (data) {
    const kycName = { INDIVIDUAL: "individual", COMPANY: "company" };
    const urlRedirect = `/application-success/${kycName[kycType]}/${
      Object.values(data)[0].id
    }`;
    return <Redirect push to={urlRedirect || "/profile"} />;
  }

  return !loadingPublicSettings ? (
    <div className="settings__wrapper">
      {publicSettings && renderPaymentsForm()}

      <div className="settings__list">
        <div className="card__head card__head-kyc-1">
          <div className="title-primary card__title">{ t('Personal Details') }</div>
          <div className="refund__content card__head-content">
            <p>
              { t('Your personal information required for identification.') }
            </p>
            <p>{t(publicSettings.kyc_text) || ""}</p>
            <p>
              { t('Please type carefully and fill out the form with your personal details. Entered data will not be editable once you have submitted the form.') }
            </p>
          </div>
        </div>

        {renderPersonalDetailForm()}
      </div>

      <div className="settings__list">
        <div className="settings__item settings__field">
          {renderTermsAndConditions()}
        </div>

        <div className="settings__item">{' '}</div>
      </div>
    </div>
  ) : (
    <div className={classes.circularProgressWrapper}>
      <CircularProgress />
    </div>
  );
};

UpdateKycVerificationForm.propTypes = {
  mainInfoSchemas: PropTypes.object.isRequired,
  addressInfoSchemas: PropTypes.object,
  filesSchemas: PropTypes.object.isRequired,
  documentTypeEnum: PropTypes.object.isRequired,
  kycType: PropTypes.string.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  updateCustomerData: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

export default UpdateKycVerificationForm;
