import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
// import "../styles/legacy/style.scss";
import {
  USER_KYC_STATUS_TYPES,
  USER_VIDEO_IDENT_STATUS,
} from '../constants/user'
import { TOKEN_TYPES } from '../constants/settings'
import { ME_INVEST_IN_BASE_CURRENCY } from '../queriesAndMutations'
import { useTranslation } from 'react-i18next'
import { toaster } from '../utils/toaster'

const AccountStatus = ({ userData, publicSettings }) => {
  const {
    data: { meInvestInBaseCurrency = {} } = {},
    loading: loadingMeInvestInBaseCurrency,
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);

  const currentInvestLevel = !loadingMeInvestInBaseCurrency
    ? (meInvestInBaseCurrency.current_lvl || {}).level
    : 1;
  let notVideoIdentCountries = [];
  let countryOfResidence = "";

  if (userData && userData.customer && userData.customer.countryOfResidence) {
    countryOfResidence = userData.customer.countryOfResidence;
  }
  const { t } = useTranslation();

  const renderEmailVerificationStatusBox = () => {
    const buttonClass = userData.is_active ? "status-green-dark status-l w-full" : "status-pending-dark status-l w-full";
    const text = userData.is_active ? t('Email verified') : t('Email not verified');
    return (
      <span className={`${buttonClass} popular__button`}>
        {t(text)}
      </span>
    );
  };

  const currentDirectorProfile = (userData.customerCompanyDirector && userData.customerCompanyDirector[0]) || null

  const renderKYCDirectorStatusBox = () => {
    const kycStatusMapping = {
      [USER_KYC_STATUS_TYPES.NOT_SUBMITTED]: {
        buttonClass: "button-stroke cursor-pointer",
        text: t('Director profile is unfilled'),

      },
      VIDEO_IDENT: {
        buttonClass: "button-stroke cursor-pointer",
        text: t('Liveness check for Director not started'),

      },
      [USER_KYC_STATUS_TYPES.PENDING]: {
        buttonClass: "status-green-dark",
        text: t('Director profile completed'),

      },
      [USER_KYC_STATUS_TYPES.REJECTED]: {
        buttonClass: "status-red-dark",
        text: t('Director profile rejected'),

      },
      [USER_KYC_STATUS_TYPES.PASSED]: {
        buttonClass: "status-green-dark",
        text: t('Director profile passed'),

      },
      REOPEN: {
        buttonClass: "status-pending-dark cursor-pointer",
        text: t('Director profile reopen'),

      },
    };
    const { buttonClass, text } = kycStatusMapping[currentDirectorProfile.status] || {};

    return currentDirectorProfile.status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED ? (
      <a
        href={`/director-profile/${currentDirectorProfile.id}`}
        className={`${buttonClass} status-l w-full popular__button cursor-pointer`}
      >
        {t(text)}
      </a>
    ) : currentDirectorProfile.status === 'REOPEN' ? (
      <a
        href={`/director-profile/${currentDirectorProfile.id}`}
        className={`${buttonClass} status-l w-full popular__button cursor-pointer`}

      >
        {t(text)}
      </a>
    ) : currentDirectorProfile.status === 'VIDEO_IDENT' ? (
      <>
        <span className="status-green-dark status-l w-full popular__button">{t('Director profile submitted')}</span>
        <a
          href={`/director-profile/${currentDirectorProfile.id}/video-ident`}
          className={`${buttonClass} status-l w-full popular__button`}
        >
          {t(text)}
        </a>
      </>
    ) : (
      <span
        className={`${buttonClass} status-l w-full popular__button`}
      >
        {t(text || currentDirectorProfile.status.replace('_', ' '))}
      </span>
    )
  }

  const renderKYCStatusBox = () => {
    const kycStatusMapping = {
      [USER_KYC_STATUS_TYPES.NOT_SUBMITTED]: {
        buttonClass: "button-stroke button-small",
        text: t('KYC is unfilled'),
      },
      [USER_KYC_STATUS_TYPES.PENDING]: {
        buttonClass: "status-pending-dark w-full status-l",
        text: t('KYC pending'),
      },
      [USER_KYC_STATUS_TYPES.REJECTED]: {
        buttonClass: "status-red-dark w-full status-l",
        text: t('KYC rejected'),
      },
      [USER_KYC_STATUS_TYPES.PASSED]: {
        buttonClass: "status-green-dark w-full status-l",
        text: t('KYC passed'),
      },
      'REOPEN' : {
        buttonClass: "button-stroke button-small",
        text: t('KYC reopen'),
      },
    };

    const { buttonClass, text } = kycStatusMapping[userData.kyc_status];

    // Case director doesn't have KYC registered
    if (userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED && currentDirectorProfile) {
      return renderKYCDirectorStatusBox();
    }

    if (userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED) {
      return (
        <>
          { publicSettings.kyc_require_referral && !localStorage.getItem('referalCode') && !userData.referral_code ? (
              <button
                type="button"
                className={`${buttonClass} w-full popular__button`}
                onClick={() => {
                  toaster.warning(
                    <div style={{wordBreak: 'break-word'}}>
                      { t('You are trying to access the Application form without a working referral code, please use')} {' '}
                      <div>
                        <strong>
                          <span style={{whiteSpace: 'nowrap'}}>{window.location.host}</span>
                          /
                          <span style={{whiteSpace: 'nowrap'}}>application/%ReferralCode%</span>
                        </strong>
                      </div>
                      {t('to set your Referral Code!')}<br />
                      {t('Please note that the %ReferralCode% has to be the real referral code which the project gave you.')}
                    </div>,
                    10000)
                }}
              >
                {t(text)}
              </button>
            ) : (
              <a
                href={publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION ? '/transfer-claim' : '/application'}
                className={`${buttonClass} w-full text__center popular__button`}
              >
                {t(text)}
              </a>
            )}
        </>
      );
    }

    if (userData.kyc_status === USER_KYC_STATUS_TYPES.REOPEN) {
      const typeKyc = userData.customer ? 'individual' : 'customerCompany'
      const kycId = userData.customer ? userData.customer.id : userData.customerCompany.id

      return (
        <a
          href={`/application-update/${typeKyc}/${kycId}`}
          className={`${buttonClass} w-full text__center popular__button`}
        >
          {t(text.toUpperCase())}
        </a>
      )
    }

    return (
      <button
        type="button"
        className={`${buttonClass} w-full text__center popular__button`}
      >
        {t(text)}
      </button>
    );
  };

  const renderVideoIdentStatusBtn = () => {
    if (userData.customerCompany) {
      return <></>
    }

    const buttonClass =
      userData.video_ident_status === USER_VIDEO_IDENT_STATUS.PASSED
        ? "status-green-dark status-l"
        : "button-stroke button-small";
    const textMapping = {
      [USER_VIDEO_IDENT_STATUS.PASSED]: t('Liveness check passed'),
      [USER_VIDEO_IDENT_STATUS.PENDING]: t('Liveness check pending'),
      [USER_VIDEO_IDENT_STATUS.NOT_NEEDED]: t('Liveness check not needed'),
      [USER_VIDEO_IDENT_STATUS.DENIED]: t('Liveness check denied'),
      [USER_VIDEO_IDENT_STATUS.NOT_STARTED]: t('Liveness check not started'),
    };
    const text = textMapping[userData.video_ident_status];

    switch (userData.video_ident_status) {
      case USER_VIDEO_IDENT_STATUS.PASSED:
        return (
          <div className="status-green-dark status-l w-full text__center popular__button">
            {t(text)}
          </div>
        );
      case USER_VIDEO_IDENT_STATUS.PENDING:
        return (
          <div className="status-pending-dark status-l w-full text__center popular__button">
            {t(text)}
          </div>
        );
      case USER_VIDEO_IDENT_STATUS.DENIED:
      case USER_VIDEO_IDENT_STATUS.NOT_STARTED:
        return (
          <a
            href="liveness-check-portal"
            className={`${
              userData.video_ident_status === USER_VIDEO_IDENT_STATUS.DENIED
                ? buttonClass
                : "button-stroke button-small"
            } w-full text__center popular__button`}
          >
            {t(text)}
          </a>
        );
      default:
        return null;
    }
  };

  const renderOnfidoVideoIdentStatus = () => {
    const customer = userData.customer ? userData.customer : userData.customerCompany;
    const applicant = customer.onfidoApplicant;
    const onfidoChecks = applicant.onfidoChecks || [];

    // Date in desc order, the most recent date to the left
    onfidoChecks.sort(function compare(a, b) {
      var dateA = new Date(a.created_at);
      var dateB = new Date(b.created_at);
      return dateB - dateA;
    });

    if (onfidoChecks.length > 0) {
      const lastCheck = onfidoChecks[0];
      const buttonClass = lastCheck.status === "complete" ? "status-green-dark status-l" : "button-stroke button-small";

      if (lastCheck.status === "complete") {
        return (
          <button type="button" className={`${buttonClass} w-full popular__button`}>
            {t('Liveness check')} {`${lastCheck.status}`}
          </button>
        );
      } else if (
        lastCheck.status === "" ||
        lastCheck.status === null ||
        lastCheck.status === "in_progress"
      ) {
        return (
          <button type="button" className="status-pending-dark status-l w-full popular__button">
            { t('Liveness check pending') }
          </button>
        );
      } else if (customer.status === "REOPEN_WITH_VIDEOIDENT" || customer.status === "PENDING") {
        return (
          <a
            href="/liveness-check-portal"
            target="_blank"
            rel="noopener noreferrer"
            className="button-stroke w-full text__center popular__button"
          >
            { t('Liveness check not started') }
          </a>
        );
      }

      return (
        <button type="button" className={`${buttonClass} w-full text__center popular__button`}>
          {t('Liveness check')} {`${lastCheck.status}`}
        </button>
      );
    } else {
      return (
        <a
          href="/liveness-check-portal"
          target="_blank"
          rel="noopener noreferrer"
          className="button-stroke button-small w-full text__center popular__button"
        >
          { t('Liveness check not started') }
        </a>
      );
    }
  };

  const renderInternalLivenessCheckStatus = () => {
    const customer = userData.customer ? userData.customer : userData.customerCompany;
    const applicant = customer.livenessCheckApplicant;

    if (applicant) {
      if (applicant.status === "NOT_STARTED") {
        return (
          <a
            href="/liveness-check-portal"
            target="_blank"
            rel="noopener noreferrer"
            className="button button-stroke button-small text__center w-full popular__button"
          >
            { t('LIVENESS CHECK NOT STARTED') }
          </a>
        );
      }

      if (applicant.status === "SUBMITTED") {
        return (
          <span className="status-green-dark status-l text__center w-full popular__button">
            { t('LIVENESS CHECK SUBMITTED') }
          </span>
        );
      }

      return (
        <button type="button" className="button button-small button-stroke popular__button">
          {t('Liveness check')} {applicant.status.replace(/_/g, ' ')}
        </button>
      );
    }

    return null
  };

  const renderIdnowIdentStatus = () => {
    const customer = userData.customer ? userData.customer : userData.customerCompany;
    const ident = customer.idnowIdent;

    if (ident) {
      if (ident.status === "NOT_STARTED") {
        return (
          <a
            href={`${window.location.origin}/idnow-ident`}
            target="_blank"
            rel="noopener noreferrer"
            className="button button-stroke button-small text__center w-full popular__button"
          >
            { t('LIVENESS CHECK NOT STARTED') }
          </a>
        );
      }

      return (
        <span className="status-green-dark status-l text__center w-full popular__button">
          { t('LIVENESS CHECK SUBMITTED') }
        </span>
      );
    }

    return "";
  };

  const renderVideoIdentStatus = () => {
    if (userData.kyc_status === USER_KYC_STATUS_TYPES.NOT_SUBMITTED) {
      return null;
    }
    if (
      notVideoIdentCountries &&
      countryOfResidence &&
      notVideoIdentCountries.includes(countryOfResidence) &&
      currentInvestLevel &&
      currentInvestLevel < 2
    ) {
      return null;
    }
    if (userData.customer && publicSettings.identification) {
      if (publicSettings.identification.active_ident_service === 'onfido' && userData.customer.onfidoApplicant) {
        return <>{renderOnfidoVideoIdentStatus()}</>;
      }
      if (publicSettings.identification.active_ident_service === 'internal_liveness_check' && userData.customer.livenessCheckApplicant) {
        return renderInternalLivenessCheckStatus();
      }
      if (publicSettings.identification.active_ident_service === 'idnow' && userData.customer.idnowIdent) {
        return renderIdnowIdentStatus();
      }
    }
    return <>{renderVideoIdentStatusBtn()}</>;
  };

  const renderSignatureStatus = () => {
    const documentESignature = userData && userData.customer && userData.customer.documentESignature
    if (documentESignature) {
      if (documentESignature.status === "pending") {
        return (
          <span className="status-pending-dark status-l w-full popular__button">
            { t('Required document signature') }
          </span>
        );
      } else if (documentESignature.status === "fulfilled") {
        return (
          <span className="status-green-dark status-l w-full text__center popular__button">
            { t('Document Signed') }
          </span>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const renderStatusBoxes = () => (
    <>
      {renderEmailVerificationStatusBox()}
      {renderKYCStatusBox()}
      {renderVideoIdentStatus()}
      {renderSignatureStatus()}
      {userData.kyc_status !== USER_KYC_STATUS_TYPES.NOT_SUBMITTED && currentDirectorProfile && renderKYCDirectorStatusBox()}
    </>
  );

  return <>{renderStatusBoxes()}</>;
};

AccountStatus.propTypes = {
  dialogOpen: PropTypes.bool,
  userData: PropTypes.object.isRequired,
  publicSettings: PropTypes.object,
  onOpenDialog: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

export default AccountStatus;
