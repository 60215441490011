import React, { useState } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";
import { useMutation } from "@apollo/react-hooks";
import omit from "lodash/omit";
import cn from "classnames";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { REGISTRATION, RESEND_CONFIRMATION_EMAIL } from "../../queriesAndMutations";
import { usePublicSettings } from "../../myHooks/useSettings";
import { useTranslation } from 'react-i18next';
import { getLocale } from '../../utils/lang'

import Icon from "../Icon"

const useStyles = makeStyles(() => ({
  circularProgressWrapper: {
    display: "flex",
    justifyContent: "center",
  },
}));

const RegistrationForm = ({ match }) => {
  const { data: { publicSettings = {} } = {} } = usePublicSettings();

  const company = publicSettings && publicSettings.company;

  const classes = useStyles();
  const { t } = useTranslation();
  const [registration, { data, loading }] = useMutation(REGISTRATION);
  const [resendEmail] = useMutation(RESEND_CONFIRMATION_EMAIL);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    password: "",
    password_confirm: "",
    referral_code: localStorage.getItem('referalCode') || '',
    isConfirmPolicy: false,
  });
  const [isResend, setResend] = useState(false);
  const showReferralField = !!localStorage.getItem('referalCode');

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onCreateBtnClick = () => {
    if (!values.isConfirmPolicy && !['kyc.cryptoadvisory.li', 'dev-kyc.cryptoadvisory.li'].includes(window.location.host)) {
      return;
    }

    const registerData = { ...omit(values, "isConfirmPolicy"), locale: getLocale(publicSettings.default_locale) };

    setValues({ ...values, password: "", password_confirm: "" });
    registration({ variables: { input: registerData } }).catch(() => {});
  };

  const onChangeCheckbox = (name) => (event) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  const renderInputField = ({
    // eslint-disable-next-line react/prop-types
    propertyName,
    label = "",
    index,
    type,
    icon,
  } = {}) => {
    return (
      <div className="field field_icon" key={index}>
        <div className="field__wrap">
          <input
            placeholder={label}
            className="field__input"
            value={values[propertyName] || ""}
            onChange={handleChange(propertyName)}
            type={type}
            disabled={loading}
          />

          <div className="field__icon">
            <Icon
              className={cn(
                {icon}
              )}
              name={icon}
              size="24"
            />{" "}
          </div>
        </div>
      </div>
    );
  };

  const onClickResendLink = (id) => {
    resendEmail({
      variables: {
        input: {
          id: parseInt(id)
        }
      }
    }).catch(() => {});
    setResend(true);
  }

  const renderRegistration = () => {
    if (data) {
      const urlRedirect = `/register-success/${data.registration.id}`;
      return <Redirect push to={urlRedirect || "/"} />;
    }
    if (match.params.userId) {
      if (isResend) {
        return (
          <>
            <div className="h2 entry__title nospace__bottom">
              { t('Thank you!') }
            </div>
            <div className="entry__top">{' '}</div>
            <div className="entry__text">
              { t('Your signup process is almost done.') }
              <br />
              { t('Please check your mail and verify.') }
              <br />
              <span className="resend-confirmation-text">{ t('Confirmation mail sent') }</span>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="h2 entry__title nospace__bottom">
            { t('Thank you!') }
          </div>
          <div className="entry__top">{' '}</div>
          <div className="entry__text">
            { t('Your signup process is almost done.') }
            <br />
            { t('Please check your mail and verify.') }
            <br />
            <span className="resend-confirmation-link" onClick={() => onClickResendLink(match.params.userId)}>{ t('Resend confirmation email')}</span>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="h2 entry__title nospace__bottom">{ t('Sign up Registration') }</div>
        <div className="entry__top">{' '}</div>
        {!['kyc.cryptoadvisory.li', 'dev-kyc.cryptoadvisory.li'].includes(window.location.host) && (
          <>
            <div className="entry__text">{t('Create New COMPANYORNAME Account', {
              COMPANYORNAME: (company || {}).name
            })}</div>
          </>
        )}

        <div className="entry__fieldset">
          {renderInputField({
            propertyName: "firstName",
            label: t('Your name'),
            icon: "user"
          })}

          {renderInputField({
            propertyName: "lastName",
            label: t('Your surname'),
            icon: "user"
          })}

          {renderInputField({
            propertyName: "middleName",
            label: t('Your middle name'),
            icon: "user"
          })}

          {renderInputField({
            propertyName: "email",
            label: t('Your email'),
            icon: "mail"
          })}

          {renderInputField({
            propertyName: "password",
            label: t('Password'),
            type: "password",
            icon: "lock"
          })}

          {renderInputField({
            propertyName: "password_confirm",
            label: t('Repeat Password'),
            type: "password",
            icon: "lock"
          })}

          {showReferralField && (
            <>
              {renderInputField({
                propertyName: "referral_code",
                label: t('Referral Code'),
                type: "copy",
                icon: "promotion"
              })}
            </>
          )}

          {!['kyc.cryptoadvisory.li', 'dev-kyc.cryptoadvisory.li'].includes(window.location.host) && (
            <div className="field field__checkbox-kyc">
              <label className="checkbox">
                <input
                  className="checkbox__input"
                  type="checkbox"
                  checked={values.isConfirmPolicy}
                  onChange={onChangeCheckbox("isConfirmPolicy")}
                  value="isConfirmPolicy"
                />
                <span className="checkbox__inner">
                  <span className="checkbox__tick">{' '}</span>
                  <span className="checkbox__text">
                    { t('I agree to') }
                    {' '}
                    {(company || {}).name}
                    {' '}
                    <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">{ t('Privacy Policy') }</Link>
                    {' '}
                    &amp;
                    {' '}
                    <Link to="/terms" target="_blank" rel="noopener noreferrer">{ t('Terms') }</Link>
                  </span>
                </span>
              </label>
            </div>
          )}

          {loading ? (
              <div className={classes.circularProgressWrapper}>
                <CircularProgress />
              </div>
            ) : (
              <div className="field input-submit">
                <button
                  disabled={!values.isConfirmPolicy && !['kyc.cryptoadvisory.li', 'dev-kyc.cryptoadvisory.li'].includes(window.location.host)}
                  type="button"
                  onClick={onCreateBtnClick}
                  className="button entry__button"
                >
                  { t('Create Account') }
                </button>
              </div>
            )}
        </div>
      </>
    );
  };

  return renderRegistration();
};

export default compose(withRouter)(RegistrationForm);
