import React, { useState } from 'react'
// import makeStyles from "@material-ui/core/styles/makeStyles";
import { useQuery } from '@apollo/react-hooks'
import {
  ME_INVEST_IN_BASE_CURRENCY,
} from '../../../../queriesAndMutations'
import { numberWithCommas } from '../../../../utils'
import { mandatoryKyc } from '../../../../utils/me'
import { useMe } from '../../../../myHooks'
import { usePublicSettings } from '../../../../myHooks/useSettings'
import AccountStatus from '../../../../components/AccountStatus'
import UpgradeLevelDialog from '../../../../components/UpgradeLevelDialog'
import { useTranslation } from 'react-i18next'

// const useStyles = makeStyles(() => ({
//   cursorPointer: {
//     cursor: "pointer",
//   },
//   itemCenterSpaceBetween: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
// }));

const KycLevelBox = ({ hiddenIfLvlZero = false }) => {
  // const classes = useStyles();
  const { t } = useTranslation();
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const {
    data: { me },
  } = useMe();

  const {
    data: { meInvestInBaseCurrency = {} } = {},
    loading: loadingMeInvestInBaseCurrency,
  } = useQuery(ME_INVEST_IN_BASE_CURRENCY);

  const is_gto_sales = me.is_gtoswiss || me.is_internal_sales;
  const [isUpgradeLvlDialogOpen, setIsUpgradeLvlDialogOpen] = useState(false);

  const renderKYCStatusBox = () => (
    <span className="status-pending-dark status-l w-full">
      { t('lvl increase pending') }
    </span>
  );

  const renderZeroInvestAmount = () => {
    let kycStatusText;
    if (me.kyc_status === "PENDING") {
      kycStatusText = (
        <>
          { t('Your CUSTOMERTYPE is Pending.', {CUSTOMERTYPE: me.customer ? "KYC" : me.customerCompany ? "KYB" : ""}) }
          <br />
          { t('We will email you a notification after processing your application, or please check your status here again later.') }
        </>
      );
    } else if (me.kyc_status === "NOT_SUBMITTED") {
      kycStatusText = (
        <>
          {t('To increase this limit, please complete additional KYC steps')}
        </>
      )
    } else {
      return " 0.";
    }
    return (
      <>
        {" 0. "}
        <span className="space space__8">{' '}</span>
        {kycStatusText}
      </>
    );
  };

  const {
    data: { me: userData },
  } = useMe();
  const [values, setValues] = useState({
    ethAddressDialogOpened: false,
  });
  const changeEthAddressDialogState = (state) => () =>
    setValues({
      ...values,
      ethAddressDialogOpened: state,
    });

  const getBaseCurrency = () => {
    if (!publicSettings || !publicSettings.kyc_use_base_currency) {
      return 'CHF'
    }

    return publicSettings.base_currency.toUpperCase()
  }

  const renderAccountStatusCard = () => (
    <AccountStatus
      userData={userData}
      publicSettings={publicSettings}
      onOpenDialog={changeEthAddressDialogState(true)}
      onCloseDialog={changeEthAddressDialogState(false)}
      dialogOpen={values.ethAddressDialogOpened}
    />
  );

  const maxLevel = publicSettings && publicSettings.kyc_levels && publicSettings.kyc_levels[5] &&
    publicSettings.kyc_levels[5].enable.enable_lvl ? 5 : 4

  if (!loadingMeInvestInBaseCurrency) {
    return (
      <div className="tips">
        <div className="tips__list tips__list-clear tips__list-one-column">
          {hiddenIfLvlZero &&
            (meInvestInBaseCurrency.current_lvl || {}).level < 1 ? (
              ""
            ) : (
              <div className="tips__item flex__align_start">
                <div className="tips__icon tips__icon-number">
                  {!loadingMeInvestInBaseCurrency
                    ? (meInvestInBaseCurrency.current_lvl || {}).level
                    : 1
                  }
                </div>

                <div className="tips__detail">
                  <div className="tips__title">
                    { t('Level') }{" "}
                    {!loadingMeInvestInBaseCurrency
                      ? (meInvestInBaseCurrency.current_lvl || {}).level
                      : 1
                    }
                  </div>

                  <div className="tips__line">
                    <div className="refund__content">
                      { t('You can make purchases up to the value of') }{" "}

                      {!loadingMeInvestInBaseCurrency &&
                      meInvestInBaseCurrency?.current_lvl?.max_invest_amount === 0
                        ? renderZeroInvestAmount()
                        : `${getBaseCurrency()} ${numberWithCommas(
                            meInvestInBaseCurrency.current_lvl.min_invest_amount
                          )}-${numberWithCommas(
                            meInvestInBaseCurrency.current_lvl.max_invest_amount
                          )}`}
                    </div>

                  </div>
                </div>
              </div>
            )
          }
        </div>

        {hiddenIfLvlZero &&
          (meInvestInBaseCurrency.current_lvl || {}).level < 1 ? (
            ""
          ) : (
            <div className="popular__button">
              {(meInvestInBaseCurrency.current_lvl || {}).level !== maxLevel &&
                (meInvestInBaseCurrency.current_lvl || {}).type_customer !==
                  undefined &&
                mandatoryKyc(
                  is_gto_sales,
                  publicSettings.accepted_mandatory_kyc || {}
                ) &&
                !meInvestInBaseCurrency.kyc_lvl_change && (
                  <>
                    <button
                      type="button"
                      onClick={() => setIsUpgradeLvlDialogOpen(true)}
                      className="button-stroke button-small w-full text__center popular__button"
                    >
                      { t('Increase Level now') }
                    </button>
                    <div className="space space__8">{' '}</div>
                  </>
                )
              }

              {meInvestInBaseCurrency.kyc_lvl_change && (
                <>{renderKYCStatusBox()}</>
              )}

              {renderAccountStatusCard()}

              <UpgradeLevelDialog
                open={isUpgradeLvlDialogOpen}
                onClose={() => setIsUpgradeLvlDialogOpen(false)}
              />
            </div>
          )
        }
      </div>
    );
  }
  return <></>;
};

export default KycLevelBox;
