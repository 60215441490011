import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
// import "../styles/legacy/style.scss";
import { useMutation, useQuery } from '@apollo/react-hooks'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import MaterialAccordion from '@material-ui/core/Accordion'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ModalInnerMaterialDialog from './ModalInnerMaterialDialog'
import DropdownMaterial from './DropdownMaterial'
import Web3 from 'web3'
import Icon from './Icon'
import StatusIsWhitelisted from './StatusIsWhitelisted'
import {
  MAIN_INFO_SCHEMAS,
  MAIN_INFO_COMPANY_SCHEMAS,
  ADDRESS_INFO_SCHEMAS,
  INDIVIDUAL_FILES_SCHEMAS,
  COMPANY_FILES_SCHEMAS,
} from '../schemas/kycVerification'
import { CUSTOMER_STATUS_TYPES, CUSTOMER_TYPES } from '../constants/customer'
import { USER_RIGHT_TYPES } from '../constants/user'
import { TOKEN_TYPES } from '../constants/settings'
import {
  ADMIN_KYC_LIST,
  CHANGE_CUSTOMER_COMPANY_STATUS,
  CHANGE_CUSTOMER_STATUS,
  REOPEN_KYCS_FORM,
  CHANGE_USER_STATUS_VIDEO_IDENTIFICATION,
  REMOVE_INDIVIDUAL_CUSTOMER,
  REMOVE_COMPANY_CUSTOMER,
  EXPORT_ELECTRONIC_ID_DATA,
  DELETE_CHECK_RESULT,
  GET_SPECIFIC_COMPENSATION_RATE_MAP,
  TXT_FILE_CONTRACTED,
  TXT_FILE_DISTRIBUTION,
  GET_AVAILABLE_FEATURES,
  // CHANGE_APPROVED_TO_WHITELISTED_CUSTOMER_STATUS,
  GET_MY_PERSONAL_DISCOUNT,
  OVERIDE_WATCHLIST_ENHANCED,
} from '../queriesAndMutations'
import TablePaginationActions from './TablePagination'
import ShortenText from './ShortenText'
import BatchCompensateDialog from './BatchCompensateDialog'
import AdminKycDrawer from './AdminKycDrawer'
import { useExchangeRates, useMe, usePublicSettings } from '../myHooks'
import { tokenToEth, ethToFiat, fiatToEth } from '../utils/rate'
import { hasUserEnoughRights } from '../utils/me'
import AdminExportKyc from './AdminCsvDialogs/AdminExportKyc'
import ButtonExportTxtFile from './ExportTxtFile/ButtonExportTxtFile'
import moment from 'moment'
import {
  getExternalFileUrl,
  toaster,
  numberWithCommas,
  getQueryVars,
  isFeatureEnabled,
  isProduction,
  fiatToToken,
} from '../utils'

import metamaskService from '../services/metamask'
import contractAbi from '../constants/contractAbi'

const useStyles = makeStyles(() => {
  const statusBadge = {
    padding: "6px 12px",
    border: "1px solid #000",
    borderRadius: "8%",
    textTransform: "lowercase",
  };

  return {
    tableHeader: {
      "& .MuiTableCell-head": {
        color: "#007bff",
        fontSize: "14px",
        fontWeight: "bold",
      },
    },
    tableRow: {
      minHeight: "75px",
      "& .MuiTableCell-body": {
        padding: "10px 3px",
      },
    },
    nameTableCell: {
      display: "flex",
      alignItems: "center",
    },
    avatar: {
      backgroundColor: " #007bff",
      borderRadius: "50%",
      margin: "8px",
      width: "36px",
      height: "36px",
    },
    idleStatusBadge: statusBadge,
    pendingStatusBadge: {
      ...statusBadge,
      borderColor: "#ffc100",
    },
    activeStatusBadge: {
      ...statusBadge,
      borderColor: "#009f65",
    },
    rejectedStatusBadge: {
      ...statusBadge,
      borderColor: "#ea0b0b",
    },
    actionBtnPaper: {
      "&>div": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        "&>svg": {
          marginRight: "5px",
        },
        padding: "8px 10px",
      },
    },
    circularProgressWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    searchBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttonsBar: {
      display: "flex",
      alignItems: "center",
    },
    settingsPepper: {
      padding: "10px 12px",
      position: "absolute",
      zIndex: 10,
      "& span": {
        textTransform: "lowercase",
      },
    },
    editPepper: {
      padding: "10px 12px",
      position: "absolute",
      zIndex: 10,
      "&>div": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&>svg": {
          marginRight: "5px",
        },
      },
    },
    customActionsBody: {
      padding: "24px"
    },
    customFieldSpace:{
      marginBottom: "16px !important",
    },
  };
});

const KycListActions = ({ me, userKyc, customerType, refetch, autoOpenDrawer, resetAutoOpenDrawer }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = userKyc;
  const [, setActionBtnOpen] = React.useState(null);
  const [deleteCheckResult, deleteCheckResultStatus] = useMutation(DELETE_CHECK_RESULT);
  const [openDrawer, setOpenDrawer] = React.useState(autoOpenDrawer || false);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const [commentForChangeStatus, setCommentForChangeStatus] =
    React.useState('');

  const [openCommentForChangeStatus, setOpenCommentForChangeStatus] =
    React.useState(false);
  const [changeCustomerStatus, changeCustomerStatusData] = useMutation(
    CHANGE_CUSTOMER_STATUS
  );

  const [changeCustomerCompanyStatus, changeCustomerCompanyStatusData] =
    useMutation(CHANGE_CUSTOMER_COMPANY_STATUS);

  const [removeIndividualCustomer, removeIndividualCustomerStatus] = useMutation(REMOVE_INDIVIDUAL_CUSTOMER);
  const [removeCompanyCustomer, removeCompanyCustomerStatus] = useMutation(REMOVE_COMPANY_CUSTOMER);
  const [overideWatchlistEnhanced, overideWatchlistEnhancedStatus] = useMutation(OVERIDE_WATCHLIST_ENHANCED);
  const [openOverideWatchlistEnhanced, setOpenOverideWatchlistEnhanced] = useState(false);
  const [changeUserVideoIdent] = useMutation(
    CHANGE_USER_STATUS_VIDEO_IDENTIFICATION
  );
  const [overideWatchlistEnhancedData, setOverideWatchlistEnhancedData] = useState({
    type: '',
    result: '',
    comment: '',
  })

  if (resetAutoOpenDrawer) {
    resetAutoOpenDrawer();
  }

  const onChangeUserVideoIdent = (userId, videoIdentificationStatus) => () => {
    changeUserVideoIdent({
      variables: { id: userId, videoIdentificationStatus },
    }).then(() => refetch()).catch(() => {});
  };

  const handleChangeComment = (event) => {
    setCommentForChangeStatus(event.target.value);
  };

  const onChangeStatus =
    (customerId, status, refetchCustom = () => {}) =>
    () => {
      let needDialogWithComment = false;
      if (
        [
          CUSTOMER_STATUS_TYPES.REOPEN,
          'REOPEN_WITH_VIDEOIDENT',
          CUSTOMER_STATUS_TYPES.REJECTED
        ].includes(status) &&
        commentForChangeStatus === ''
      ) {
        needDialogWithComment = true;
      }
      setOpenCommentForChangeStatus(needDialogWithComment);
      if (
        customerType === CUSTOMER_TYPES.INDIVIDUAL &&
        !needDialogWithComment
      ) {
        return changeCustomerStatus({
          variables: {
            input: { id: customerId, status, comment: commentForChangeStatus },
          },
        }).then(() => {
          refetch();
          setCommentForChangeStatus('')
          refetchCustom();
        }).catch(() => {});
      }
      if (customerType === CUSTOMER_TYPES.COMPANY && !needDialogWithComment) {
        return changeCustomerCompanyStatus({
          variables: {
            input: { id: customerId, status, comment: commentForChangeStatus },
          },
        }).then(() => {
          refetch();
          refetchCustom();
        }).catch(() => {});
      }
      return false;
    };
  const onDeleteCheckResult = (customerId) => () => {
    deleteCheckResult({
      variables: {
        input: {
          id: customerId,
          comment: commentForChangeStatus
        }
      }
    }).then(() => {
      setOpenCommentForChangeStatus(false);
      refetch();
    }).catch(() => {})
  }

  const onOverideWatchlistEnhanced = () => {
    overideWatchlistEnhanced({
      variables: {
        ...overideWatchlistEnhancedData,
        kycId: userKyc.id,
      }
    }).then(() => {
      setOpenOverideWatchlistEnhanced(false);
      setOverideWatchlistEnhancedData({
        type: "",
        result: "",
        comment: ""
      })
      refetch();
    }).catch(() => {})
  }

  // const onActionBtnClick = (clickedUserId) => () => {
  //   if (selectedUserId === clickedUserId) {
  //     setActionBtnOpen(null);
  //   } else {
  //     setActionBtnOpen(clickedUserId);
  //   }
  // };

  // const onActionBtnClickAway = () => {
  //   setActionBtnOpen(false);
  // };

  const onOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
    React.useState(false);

  const onDeleteKYCRecord = async (user) => {
    const IndividualKycId = user.id;
    try {
      if (customerType === CUSTOMER_TYPES.INDIVIDUAL) {
        await removeIndividualCustomer({ variables: { id: IndividualKycId } });
        setOpenDeleteConfirmDialog(false);
      } else {
        await removeCompanyCustomer({ variables: { id: IndividualKycId } });
        setOpenDeleteConfirmDialog(false);
      }
      refetch();
    } catch (error) {}
  };

  const mainScheme =
    customerType === CUSTOMER_TYPES.INDIVIDUAL
      ? MAIN_INFO_SCHEMAS
      : MAIN_INFO_COMPANY_SCHEMAS;
  const addressScheme =
    customerType === CUSTOMER_TYPES.INDIVIDUAL ? ADDRESS_INFO_SCHEMAS : null;
  const filesScheme =
    customerType === CUSTOMER_TYPES.INDIVIDUAL
      ? INDIVIDUAL_FILES_SCHEMAS
      : COMPANY_FILES_SCHEMAS;

  /**
   * Return true if user has already have a check and complete it
   */
  const isAbleToRedoLivenessCheck = (kyc) => {
    if (publicSettings) {
      if (kyc.livenessCheckServiceName === 'onfido') {
        return kyc.onfidoApplicant && kyc.onfidoApplicant.onfidoChecks.length > 0
      }
      if (kyc.livenessCheckServiceName === 'internal_liveness_check') {
        return kyc.livenessCheckApplicant && kyc.livenessCheckApplicant.status === 'SUBMITTED'
      }
      if (kyc.livenessCheckServiceName === 'idnow') {
        return kyc.idnowIdent && kyc.idnowIdent.status === 'COMPLETED'
      }
      if (kyc.livenessCheckServiceName === 'complycube') {
        return kyc.complycubeClient && kyc.complycubeClient.complycubeChecks.length > 0
      }
    }
    return false
  }

  /**
   * Return true if user doesn't have any check before
   */
  const isAbleToRequestLivenessCheck = (kyc) => {
    if (publicSettings) {
      if (kyc.livenessCheckServiceName === 'onfido') {
        return !kyc.onfidoApplicant
      }
      if (kyc.livenessCheckServiceName === 'internal_liveness_check') {
        return !kyc.livenessCheckApplicant
      }
      if (kyc.livenessCheckServiceName === 'idnow') {
        return !kyc.idnowIdent
      }
      if (kyc.livenessCheckServiceName === 'complycube') {
        return !kyc.complycubeClient
      }
    }
    return false
  }

  const dialogForChangeStatusKyc = () => (
    <Dialog
      open={openCommentForChangeStatus}
      onClose={() => setOpenCommentForChangeStatus(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={() => setOpenCommentForChangeStatus(false)}
        modalTitle={ t('Comment') }
        modalTitleClassColor="title-primary"
      >
        <div className="settings__fieldset">
          <div className="field">
            <div className="field__wrap field__wrap-textarea">
              <TextField
                fullWidth
                id="filled-multiline-flexible"
                multiline
                maxRows="4"
                rows="4"
                onChange={handleChangeComment}
                value={commentForChangeStatus}
              />
            </div>
          </div>

          <div className="field">
            {!deleteCheckResultStatus.loading && !changeCustomerStatusData.loading && (
              <div className="field__wrap">
                {
                  [
                    CUSTOMER_STATUS_TYPES.REOPEN,
                    CUSTOMER_STATUS_TYPES.PENDING,
                    CUSTOMER_STATUS_TYPES.APPROVED,
                  ].includes(userKyc.status) &&
                  isAbleToRedoLivenessCheck(userKyc) && (
                    <button
                      className="button-stroke"
                      onClick={onChangeStatus(userKyc.id, 'REOPEN_WITH_VIDEOIDENT')}
                    >
                      { t('Reopen with liveness check') }
                    </button>
                  )
                }
                {' '}
                {
                  [
                    CUSTOMER_STATUS_TYPES.PENDING,
                    CUSTOMER_STATUS_TYPES.APPROVED,
                  ].includes(userKyc.status) && (
                    <button
                      className="button-stroke"
                      onClick={onChangeStatus(userKyc.id, CUSTOMER_STATUS_TYPES.REOPEN)}
                    >
                      { t('Reopen only') }
                    </button>
                  )
                }
                {' '}
                {
                  <button
                    className="button-stroke"
                    onClick={onDeleteCheckResult(userKyc.id)}
                  >
                    { t('Redo liveness check only') }
                  </button>
                }
                {isAbleToRequestLivenessCheck(userKyc) && (
                  <button
                    className="button-stroke"
                    onClick={onDeleteCheckResult(userKyc.id)}
                  >
                    { t('Request to perform liveness check') }
                  </button>
                )}
              </div>
            )}
            {(deleteCheckResultStatus.loading || changeCustomerStatusData.loading) && (
              <div className="field__wrap">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  const dialogForOverideWatchlistEnhaced = () => (
    <Dialog
      open={openOverideWatchlistEnhanced}
      onClose={() => setOpenOverideWatchlistEnhanced(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ModalInnerMaterialDialog
        modalSize="popup__kyc-medium"
        onClickClose={() => setOpenOverideWatchlistEnhanced(false)}
        modalTitle={ t('Overide Watchlist Enhanced Status') }
        modalTitleClassColor="title-primary"
      >
        <div className="settings__fieldset">
          <DropdownMaterial
            propertyName="result"
            label="Status"
            state={overideWatchlistEnhancedData}
            setState={setOverideWatchlistEnhancedData}
            >
            {['clear', 'consider'].map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </DropdownMaterial>

          <div className="field">
            <div className="field__label">
              { t('Comment') }
            </div>

            <div className="field__wrap field__wrap-textarea">
              <TextField
                fullWidth
                id="filled-multiline-flexible"
                multiline
                maxRows="4"
                minRows="4"
                onChange={({ target: { value } }) => setOverideWatchlistEnhancedData({...overideWatchlistEnhancedData, comment: value})}
                value={overideWatchlistEnhancedData.comment}
              />
            </div>
          </div>

          <div className='field'>
            <button
              className="button-stroke"
              onClick={onOverideWatchlistEnhanced}
            >
              { t('Submit') }
            </button>
          </div>
        </div>
      </ModalInnerMaterialDialog>
    </Dialog>
  );

  return (
    <>
      {dialogForChangeStatusKyc()}
      {dialogForOverideWatchlistEnhaced()}
      <div className="actions actions__kyc">
        {changeCustomerStatusData.loading ||
        changeCustomerCompanyStatusData.loading ? (
          <div className={classes.circularProgressWrapper}>
            <CircularProgress />
          </div>
        ) : (
          <PopupState popupId={`${user.id}`} variant="popover">
            {(popupState) => (
              <>
                <button className="actions__button" {...bindTrigger(popupState)}>
                  <Icon
                    className={classNames('more-horizontal')}
                    name='more-horizontal'
                    size="24"
                  />{" "}
                </button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  className="actions__popover"
                >
                  <div className="actions__body">
                    <button
                      className="actions__option"
                      onClick={() => {
                        setActionBtnOpen(false);
                        onOpenDrawer();
                      }}
                    >
                      <Icon
                        className={classNames('edit')}
                        name='edit'
                        size="24"
                      />{" "}
                      { t('View Details') }
                    </button>
                    {me && hasUserEnoughRights(
                      me.rights,
                      USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                    ) && (
                      <>
                        <button
                          className="actions__option"
                          onClick={() => {
                            setActionBtnOpen(false);
                            setOpenDeleteConfirmDialog(true);
                          }}
                        >
                          <Icon
                            className={classNames('trash')}
                            name='trash'
                            size="24"
                          />{" "}
                          { t('Delete KYC record') }
                        </button>
                        {(
                          (
                            [
                              CUSTOMER_STATUS_TYPES.PENDING,
                              CUSTOMER_STATUS_TYPES.APPROVED,
                            ].includes(userKyc.status)
                          ) || (
                            CUSTOMER_STATUS_TYPES.REOPEN &&
                            userKyc.onfidoApplicant &&
                            userKyc.onfidoApplicant.onfidoChecks.length > 0
                          )
                        ) && (
                          <button
                            className="actions__option"
                            onClick={() => setOpenCommentForChangeStatus(true)}
                          >
                            <Icon
                              className={classNames('link')}
                              name='link'
                              size="24"
                            />{" "}
                            {t('Reopen')}
                          </button>
                        )}
                        {userKyc.resource_file && (
                          <>
                            <a
                              href={`${getExternalFileUrl(userKyc.resource_file)}&ts=${+(new Date())}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="actions__option">
                                <Icon
                                  className={classNames('download')}
                                  name='download'
                                  size="24"
                                />{" "}
                                { t('Download Resources') }
                              </button>
                            </a>
                            <a
                              href={`${getExternalFileUrl(userKyc.resource_file)}&ts=${+(new Date())}&cache=ignore`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="actions__option">
                                <Icon
                                  className={classNames('download')}
                                  name='download'
                                  size="24"
                                />{" "}
                                { t('Regenerate And Download Resources') }
                              </button>
                            </a>
                          </>
                        )}
                        <button
                          className="actions__option"
                          onClick={() => {
                            setOverideWatchlistEnhancedData({...overideWatchlistEnhancedData, type: 'watchlist'})
                            setActionBtnOpen(false);
                            setOpenOverideWatchlistEnhanced(true);
                          }}
                          >
                          <Icon
                            className={classNames('edit')}
                            name='edit'
                            size="24"
                          />{" "}
                          { t('Overide Watchlist Enhanced Status') }
                        </button>

                        <button
                          className="actions__option"
                          onClick={() => {
                            setOverideWatchlistEnhancedData({...overideWatchlistEnhancedData, type: 'liveness'})
                            setActionBtnOpen(false);
                            setOpenOverideWatchlistEnhanced(true);
                          }}
                          >
                          <Icon
                            className={classNames('edit')}
                            name='edit'
                            size="24"
                          />{" "}
                          { t('Overide Liveness Check Status') }
                        </button>
                      </>
                    )}
                  </div>
                </Popover>
              </>
            )}
          </PopupState>
        )}
      </div>
      <AdminKycDrawer
        me={me}
        refetch={refetch}
        addressScheme={addressScheme}
        mainScheme={mainScheme}
        filesScheme={filesScheme}
        open={openDrawer}
        userKyc={userKyc}
        user={user}
        customerType={customerType}
        onClickDrawerAway={onCloseDrawer}
        onChangeUserVideoIdent={onChangeUserVideoIdent}
        onChangeStatus={onChangeStatus}
      />
      <Dialog
        open={openDeleteConfirmDialog}
        onClose={() => {
          setOpenDeleteConfirmDialog(false);
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="popup js-popup animation visible">
          <div onClick={() => {setOpenDeleteConfirmDialog(false);}} className="popup__overlay js-popup-overly">{' '}</div>

          <div className="popup__wrap js-popup-wrap popup__kyc-medium">
            <div className="description">
              <div className="title-purple description__title">{ t('Delete Confirmation') }</div>

              <div className="description__row flex__row flex__wrap">
                <div className="refund__content w-full">
                  {t('Do you really want to delete KYC profile of')}
                  {' '}
                  <strong>{userKyc.email}</strong>
                  {' '}
                  ?
                </div>
                <div className="space space__16">{' '}</div>
                <div className="refund__item refund__item-middle_content w-full">
                  <div className="refund__icon">
                    <Icon
                      className={classNames('close', 'icon-24')}
                      name="close"
                      size="24"
                    />
                  </div>
                  <div className="refund__content refund__content-link">
                    <strong>{ t('Notice:') }</strong>
                    {' '}
                    { t('This action cannot be undone') }
                  </div>
                </div>

                <div className="space space__32">{' '}</div>
                <div className="w-full">
                  {(removeIndividualCustomerStatus.loading || removeCompanyCustomerStatus.loading) ? (
                    <CircularProgress />
                  ) : (
                    <button
                      className="button button-danger"
                      onClick={() => {
                        onDeleteKYCRecord(userKyc);
                      }}
                    >
                      {t('Delete')}
                    </button>
                  )}
                </div>
              </div>
            </div>

            <button
              className="popup__close js-popup-close"
              onClick={() => {setOpenDeleteConfirmDialog(false);}}
            >
              <Icon
                className={classNames('close', 'icon-24')}
                name="close"
                size="24"
              />
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

KycListActions.propTypes = {
  userKyc: PropTypes.object.isRequired,
  customerType: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  autoOpenDrawer: PropTypes.bool,
  resetAutoOpenDrawer: PropTypes.func,
};

const AdminKycList = ({ type }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const queryParam = getQueryVars();
  const [reqOptions, setReqOptions] = useState({
    search: queryParam.id || queryParam.search || '',
    statuses: [],
    countryOfResidence: 'ANY',
    nationality: 'ANY',
    watchlistEnhanced: 'ANY',
    livenessChecks: [],
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const { data: { publicSettings = {} } = {} } = usePublicSettings();
  const { data: { getSpecificCompensationRateMap: specificCompensationRateMap } = {} } = useQuery(GET_SPECIFIC_COMPENSATION_RATE_MAP);
  const [autoOpenDrawer, setAutoOpenDrawer] = useState(!!queryParam.id);
  // const [openFilterDialog, setOpenFilterDialog] = React.useState(false)
  // downloads
  const { data: { me } = {} } = useMe()
  const { data: { getAvailableFeatures: availableFeatures } = {} } = useQuery(GET_AVAILABLE_FEATURES)
  const [openDownloadDialog, setOpenDownloadDialog] = React.useState(false)
  const [isPythagoras, setIsPythagoras] = React.useState(false)
  const [isBank, setIsBank] = React.useState(false)

  /////////////////////////////////////////////////////////////////////////////
  const [selectedUserKycs, setSelectedUserKycs] = React.useState([]);
  const [isSelectAll, setIsSelectAll] = React.useState(false);

  const [openReopenWithVideoIdentDialog, setOpenReopenWithVideoIdentDialog] =
    React.useState(false);

  const [reopenVideoIdentComments, setReopenVideoIdentComments] =
    React.useState("");

  const [redoVideoIdentChecked, setRedoVideoIdentChecked] =
    React.useState(false);

  const [openBatchCompensateDialog, setOpenBatchCompensateDialog] =
    React.useState(false);

  const [reopenKycsForm] = useMutation(REOPEN_KYCS_FORM);

  const [isShowOnfidoCheck, setIsShowOnfidoCheck] = useState(false);
  const [isShowComplycubeCheck, setIsShowComplycubeCheck] = useState(false);
  const [isShowIdnowCheck, setIsShowIdnowCheck] = useState(false);
  /////////////////////////////////////////////////////////////////////////////

  const { data: { exchangeRates } = {}, loading: loadingExchangeRates } =
    useExchangeRates();

  const tokenToChf = (tokenAmount, customer) => {
    if (publicSettings && exchangeRates) {
      const result = ethToFiat("chf", tokenToEth(tokenAmount, exchangeRates), exchangeRates);

      if (
        publicSettings.token_type === TOKEN_TYPES.LIQUIDATION &&
        specificCompensationRateMap &&
        customer &&
        customer.tokenAddress &&
        specificCompensationRateMap[customer.tokenAddress.toLowerCase()]
      ) {
        return (result * specificCompensationRateMap[customer.tokenAddress.toLowerCase()]) / +(publicSettings.liquidation_compensation_rate)
      }

      return result
    }

    return null;
  }

  const fiatToChf = (currency, tokenAmount) =>
    exchangeRates &&
    ethToFiat("chf", fiatToEth(currency, tokenAmount, exchangeRates), exchangeRates);

  const { data, refetch, loading } = useQuery(ADMIN_KYC_LIST, {
    variables: {
      input: {
        ...reqOptions,
        page,
        pageSize,
        type,
      },
    },
  });

  useEffect(() => {
    if (data && data.kycList && publicSettings) {
      const initSelected = data.kycList.objects.map((kyc) => {
        return {
          id: kyc.id,
          checked: false,
          status: kyc.status,
        };
      });

      const activeIdentService = publicSettings.identification && publicSettings.identification.active_ident_service === 'electronic_id'
      const activeEnhancedService = publicSettings.identification && publicSettings.identification.watchlist_enhanced_service
      const isOnfidoCheck = data && data.kycList && data.kycList.objects && data.kycList.objects.find(object => {
        if (object.onfidoApplicant) {
          return true;
        }

        if (object.customerCompanyDirector && object.customerCompanyDirector.length) {
          const directorHaveOnfido = object.customerCompanyDirector.find((director) => {
            return !!director.onfidoApplicant
          })
          return !!directorHaveOnfido;
        }

        return false;
      })
      setIsShowOnfidoCheck(activeIdentService === 'onfido' || activeEnhancedService === 'onfido' || !!isOnfidoCheck)

      const isComplycubeCheck = data && data.kycList && data.kycList.objects && data.kycList.objects.find(object => {
        if (object.complycubeClient) {
          return true;
        }

        if (object.customerCompanyDirector && object.customerCompanyDirector.length) {
          const directorHaveComplycube = object.customerCompanyDirector.find((director) => {
            return !!director.ComplycubeClient
          })
          return !!directorHaveComplycube;
        }

        return false;
      })
      setIsShowComplycubeCheck(activeIdentService === 'complycube' || activeEnhancedService === 'complycube' || !!isComplycubeCheck)

      const isIdnowCheck = data && data.kycList && data.kycList.objects && data.kycList.objects.find(object => {
        if (object.idnowIdent) {
          return true;
        }

        if (object.customerCompanyDirector && object.customerCompanyDirector.length) {
          const directorHaveIdnow = object.customerCompanyDirector.find((director) => {
            return !!director.idnowIdent
          })
          return !!directorHaveIdnow;
        }

        return false;
      })
      setIsShowIdnowCheck(activeIdentService === 'idnow' || publicSettings.identification.comply_with_bafin || !!isIdnowCheck)

      setSelectedUserKycs(initSelected);
    }
  }, [data, publicSettings]);

  // const [changeApprovedToWhitelisted, changeApprovedToWhitelis] =
  //   useMutation(CHANGE_APPROVED_TO_WHITELISTED_CUSTOMER_STATUS);

  // const onClickApprovedToWhitelist = () => {
  //   changeApprovedToWhitelisted({ variables: { amount: 50, type } }).then(() =>
  //     refetch()
  //   ).catch(() => {});
  // };

  const onClickIsSelectAll = () => {
    const selectAll = !isSelectAll;
    setIsSelectAll(!isSelectAll);

    if (selectAll) {
      setSelectedUserKycs(selectedUserKycs.map(kyc => ({...kyc, checked: true})))
    } else {
      setSelectedUserKycs(selectedUserKycs.map(kyc => ({...kyc, checked: false})))
    }
  }

  const onChangeReqOptions = (name) => (event) => {
    setPage(0);
    return setReqOptions({ ...reqOptions, [name]: event.target.value });
  };

  const onChangeDropdownReqOptions = (newState) => {
    setPage(0);
    return setReqOptions(newState);
  };

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangePageSize = (event) => {
    if (parseInt(event.target.value, 10)) {
      setPageSize(parseInt(event.target.value, 10));
      setPage(0);
    } else {
      setPageSize(1);
      setPage(0);
    }
  };

  const onChangeFilterStatuses = (status) => () => {
    const newStatuses = reqOptions.statuses.includes(status)
      ? reqOptions.statuses.filter((v) => v !== status)
      : [...reqOptions.statuses, status];

    setReqOptions({ ...reqOptions, statuses: newStatuses });
  };

  const onChangeFilterLivenessChecks = (check) => {
    const newLivenessChecks = reqOptions.livenessChecks.includes(check)
      ? reqOptions.livenessChecks.filter((v) => v !== check)
      : [...reqOptions.livenessChecks, check];

    setReqOptions({ ...reqOptions, livenessChecks: newLivenessChecks });
  };

  const renderKycStatus = (status, isAutoApproved = false) => {
    const statusStyles = {
      [CUSTOMER_STATUS_TYPES.IDLE]: 'status-idle',
      [CUSTOMER_STATUS_TYPES.WHITELISTED]: 'status-purple',
      [CUSTOMER_STATUS_TYPES.APPROVED]: 'status-green-dark',
      [CUSTOMER_STATUS_TYPES.PENDING]: 'status-pending-dark',
      [CUSTOMER_STATUS_TYPES.REOPEN]: 'status-pending-dark',
      REOPEN_WITH_VIDEOIDENT: 'status-pending-dark',
      [CUSTOMER_STATUS_TYPES.REJECTED]: 'status-red-dark',
      [CUSTOMER_STATUS_TYPES.CONTRACTED]: 'status-green-dark',
      [CUSTOMER_STATUS_TYPES.KYC_LVL_CHANGE]: 'status-pending-dark',
      [CUSTOMER_STATUS_TYPES.INACTIVE]: 'status-gray-dark',
    };
    const text = {
      REOPEN_WITH_VIDEOIDENT: 'REOPEN_WITH_LIVENESS_CHECK',
    }

    return (
      <div className={statusStyles[status]}>
        {text[status] || status}
        {status === CUSTOMER_STATUS_TYPES.APPROVED && isAutoApproved && '*'}
      </div>
    )
  };

  const renderSettingsBtn = () => {
    if (loading) {
      return (
        <div className={classes.circularProgressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        {Object.values(CUSTOMER_STATUS_TYPES).map((v) => (
          <div className={classNames('field field__checkbox-kyc', classes.customFieldSpace)} key={v}>
            <label
              className="checkbox"
            >
              <input
                type="checkbox"
                className="checkbox__input"
                checked={reqOptions.statuses.includes(v)}
                onChange={onChangeFilterStatuses(v)}
                value={v}
              />
              <div className="checkbox__inner">
                <div className="checkbox__tick">{' '}</div>
                <div className="checkbox__text">{v}</div>
              </div>
            </label>
          </div>
        ))}
      </>
    );
  };

  // const renderEditBtn = () => {
  //   if (changeApprovedToWhitelistedData.loading) {
  //     return (
  //       <div className={classes.circularProgressWrapper}>
  //         <CircularProgress />
  //       </div>
  //     );
  //   }
  //   return (
  //     <>
  //       <div className="field">
  //         <div className="field__wrap">
  //           <button
  //             className="button-stroke button__full"
  //             onClick={onClickApprovedToWhitelist}
  //           >
  //             <Icon
  //               className={classNames('link')}
  //               name='link'
  //               size="24"
  //             />{" "}
  //             { t('Whitelist 50 Approved Applications') }
  //           </button>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const isKycUserChecked = (id) => {
    const userKycObject = selectedUserKycs.find((kyc) => kyc.id === id);
    return (userKycObject && userKycObject.checked) || false;
  };

  const handleChangeUserKycChecked = (id) => {
    const newSelected = selectedUserKycs.map((kyc) => {
      if (kyc.id === id) {
        return { ...kyc, checked: !kyc.checked };
      } else {
        return kyc;
      }
    });
    setSelectedUserKycs(newSelected);
  };

  const handleReopenKycsWithVideoIdent = async () => {
    const customerIds = selectedUserKycs
      .filter((kyc) => kyc.checked)
      .map((kyc) => kyc.id);
    const isRedoVideoIdent = redoVideoIdentChecked;
    const comment = reopenVideoIdentComments;

    reopenKycsForm({
      variables: {
        input: {
          customerIds: customerIds,
          isRedoVideoIdent: isRedoVideoIdent,
          comment: comment,
        },
      },
    }).then(() => {
      setOpenReopenWithVideoIdentDialog(false);
      refetch();
    }).catch(() => {});
  };

  const renderIdnowCheck = (idnowIdent) => {
    if (!idnowIdent) {
      return (
        <div className="status">{t('NOT FOUND')}</div>
      )
    }

    if (idnowIdent.status === 'COMPLETED') {
      return (
        <>
          {(idnowIdent.result === 'SUCCESS' || idnowIdent.result === 'SUCCESS_DATA_CHANGED') ? (
            <>
              <div className="status-green-dark">
                {idnowIdent.result.toUpperCase()}
              </div>
            </>
          ) : (
            <div className="status-pending-dark">
              {idnowIdent.result.toUpperCase()}
            </div>
          )}

          {
            idnowIdent.response &&
            idnowIdent.response.pepAndSanctions &&
            (
              <div>
                {idnowIdent.response.pepAndSanctions.matchStatus === 'NO_MATCH' ? (
                  <div className="status-green-dark">
                    {t('PEP & Sanction')}: NO_MATCH
                  </div>
                ) : (
                  <div className="status-pending-dark">
                    {t('PEP & Sanction')}: {idnowIdent.response.pepAndSanctions.matchStatus}
                  </div>
                )}
              </div>
            )
          }
        </>
      )
    } else {
      return (
        <div className="status-pending-dark">
          {t('PENDING')}
        </div>
      )
    }
  }
  const renderOnfidoCheck = (applicant) => {
    if (!applicant || applicant.onfidoChecks.length === 0) {
      return <div className="status">{t('NOT FOUND')}</div>;
    }

    const allChecks = applicant.onfidoChecks;

    allChecks.sort(function compare(a, b) {
      var dateA = new Date(a.created_at);
      var dateB = new Date(b.created_at);
      return dateB - dateA;
    });

    const completedDocumentCheck = allChecks.find(
      (check) => check.status === "complete" && check.onfidoReports.find(
        (element) => element.name && element.name.includes("document")
      )
    );

    const completedWatchlistEnhancedChecks = allChecks.filter(
      (check) => check.status === "complete" && check.onfidoReports.find(
        (element) => element.name && element.name.includes("watchlist_enhanced")
      )
    );

    let videoIdentStatus = {};

    if (completedDocumentCheck || completedWatchlistEnhancedChecks.length) {
      videoIdentStatus = {
        videoIdent: {},
        enhancedWatchLists: [],
      };

      if (completedDocumentCheck) {
        videoIdentStatus.videoIdent = {
          result: (completedDocumentCheck.onfidoReports.find((element) =>
              element.name && element.name.includes("document")
          ) || {}).result || 'NOT_SUBMITTED'
        }
      }

      if (completedWatchlistEnhancedChecks.length) {
        videoIdentStatus.enhancedWatchLists = completedWatchlistEnhancedChecks.map(check => {
          return check.onfidoReports.find(
            (element) => element.name && element.name.includes("watchlist_enhanced")
          ) || {}
        })
      }
    } else if (allChecks.length > 0) {
      videoIdentStatus = {
        numberCheck: allChecks.length,
        videoIdent: { result: "pending" },
        enhancedWatchLists: [{ result: "pending" }],
      };
    }

    return (
      <>
        {videoIdentStatus.videoIdent ? (
          <>
            { videoIdentStatus.videoIdent && (
              <div className="transactions__product">
                { t('Liveness Check')}:
                {' '}
                {['consider', 'pending'].includes(videoIdentStatus.videoIdent.result) && (
                  <div className="status-pending-dark">
                    {(
                      videoIdentStatus.videoIdent.result || ""
                    ).toUpperCase()}
                  </div>
                )}
                {(videoIdentStatus.videoIdent.result === 'clear') && (
                  <div className="status-green-dark">
                    {(
                      videoIdentStatus.videoIdent.result || ""
                    ).toUpperCase()}
                  </div>
                )}
              </div>
            )}

            { Array.isArray(videoIdentStatus.enhancedWatchLists) && videoIdentStatus.enhancedWatchLists.map(enhancedWatchList => (
              <div className="transactions__product">
                { t('Enhanced Watchlist') }:
                {' '}
                {['consider', 'pending'].includes(enhancedWatchList.result) && (
                  <div className="status-pending-dark">
                    {(
                      enhancedWatchList.result || ""
                    ).toUpperCase()}
                  </div>
                )}
                {(enhancedWatchList.result === 'clear') && (
                  <div className="status-green-dark">
                    {(
                      enhancedWatchList.result || ""
                    ).toUpperCase()}
                  </div>
                )}
                {!['consider', 'pending', 'clear'].includes(enhancedWatchList.result) && (
                  <div className="status-red">
                    {(
                      enhancedWatchList.result || ""
                    ).toUpperCase()}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : null}
      </>
    )
  }

  const renderComplycubeCheck = (client) => {
    if (!client || client.complycubeChecks.length === 0) {
      return <div className="status">{t('NOT FOUND')}</div>;
    }

    const allChecks = client.complycubeChecks;

    allChecks.sort(function compare(a, b) {
      var dateA = new Date(a.created_at);
      var dateB = new Date(b.created_at);
      return dateB - dateA;
    });

    const completedChecks = allChecks.filter(
      (check) => check.status === "complete"
    );

    let videoIdentStatus = null;

    const completedDocumentCheck = completedChecks.find(check => check.check_type === 'document_check')
    const completedWatchlistEnhancedCheck = completedChecks.filter(check => check.check_type === 'extensive_screening_check')

    if (completedDocumentCheck || completedWatchlistEnhancedCheck) {
      videoIdentStatus = {
        videoIdent: {},
        enhancedWatchLists: [],
      };

      if (completedDocumentCheck) {
        videoIdentStatus.videoIdent = {
          result: completedDocumentCheck.result.outcome,
        }
      }

      if (completedWatchlistEnhancedCheck && completedWatchlistEnhancedCheck.length) {
        videoIdentStatus.enhancedWatchLists = completedWatchlistEnhancedCheck.map(enhancedCheck => (
          {
            result: enhancedCheck.result.outcome,
          }
        ))
      }
    } else if (allChecks.length > 0) {
      videoIdentStatus = {
        numberCheck: allChecks.length,
        videoIdent: { result: "pending" },
        enhancedWatchLists: [{ result: "pending" }],
      };
    }

    return (
      <>
        {videoIdentStatus ? (
          <>
            {videoIdentStatus.videoIdent.result && (
              <div className="transactions__product">
                { t('Liveness Check')}{ videoIdentStatus.numberCheck > 1 ? `(${videoIdentStatus.numberCheck})` : ''}:
                {' '}
                {['attention', 'pending'].includes(videoIdentStatus.videoIdent.result) && (
                  <div className="status-pending-dark">
                    {(
                      videoIdentStatus.videoIdent.result || ""
                    ).toUpperCase()}
                  </div>
                )}
                {(videoIdentStatus.videoIdent.result === 'clear') && (
                  <div className="status-green-dark">
                    {(
                      videoIdentStatus.videoIdent.result || ""
                    ).toUpperCase()}
                  </div>
                )}
              </div>
            )}

            { Array.isArray(videoIdentStatus.enhancedWatchLists) && videoIdentStatus.enhancedWatchLists.map(enhancedWatchList => (
              <div className="transactions__product">
                { t('Enhanced Watchlist') }:
                {' '}
                {['attention', 'pending'].includes(enhancedWatchList.result) && (
                  <div className="status-pending-dark">
                    {(
                      enhancedWatchList.result || ""
                    ).toUpperCase()}
                  </div>
                )}
                {(enhancedWatchList.result === 'clear') && (
                  <div className="status-green-dark">
                    {(
                      enhancedWatchList.result || ""
                    ).toUpperCase()}
                  </div>
                )}
                {!['attention', 'pending', 'clear'].includes(enhancedWatchList.result) && (
                  <div className="status-red">
                    {(
                      enhancedWatchList.result || ""
                    ).toUpperCase()}
                  </div>
                )}
              </div>
            ))}
          </>
        ) : null}
      </>
    )
  }

  const renderKyc = (object) => {
    if (type === CUSTOMER_TYPES.COMPANY && typeof object === "object") {
      const customerCompany = object;

      const tokenToEthCustomerCompany = tokenToEth(
        (customerCompany || {}).amount,
        exchangeRates
      );

      return (
        <div key={customerCompany.id} className="transactions__row">
          {
            me && hasUserEnoughRights(
              me.rights,
              USER_RIGHT_TYPES.COMPLIANCE_OFFICER
            ) && (
              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Check to filter')}
                </div>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className="checkbox__input"
                    checked={isKycUserChecked(customerCompany.id)}
                    onChange={() => {
                      handleChangeUserKycChecked(customerCompany.id);
                    }}
                  />
                  <span className="checkbox__inner">
                    <div className="checkbox__tick">{' '}</div>
                  </span>
                </label>
              </div>
            )
          }

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Email')}
            </div>
            <ShortenText text={customerCompany.user.email} v2 />
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Unique KYC ID')}
            </div>
            {object.id}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Receiving Address')}
            </div>
            <ShortenText text={customerCompany.tokenAddress} />
            <br />
            {customerCompany.is_tangany_wallet && <span className="status-green-dark">{t('Tangany')}</span>}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Sending Address')}
            </div>
            <ShortenText text={customerCompany.tokenSendingAddress} />
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Contribution')}
            </div>
            {(customerCompany || {}).currency ? (
              <>
                {
                  +ethToFiat(
                    (customerCompany || {}).currency,
                    tokenToEthCustomerCompany,
                    exchangeRates
                  )
                }{" "}
                {(customerCompany || {}).currency}
              </>
            ) : (
              "undefined"
            )}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Source')}
            </div>
            {object.referral_code
              ? object.referral_code
              : object.user.is_gtoswiss
              ? "GTOwiss.com"
              : object.user.is_internal_sales
              ? "InternalSales"
              : "Organic"}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Submitted At')}</div>
            <div className="transactions__product">
              {new Date(customerCompany.created_at).toDateString()}
              {' '}
            </div>
            <div className="transactions__invoice">
              {moment(customerCompany.created_at).format("h:mm:ss a")}
            </div>
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Status')}</div>
            {renderKycStatus(customerCompany.status)}
            {' '}
            {customerCompany.status === !CUSTOMER_STATUS_TYPES.WHITELISTED && customerCompany.tokenAddress && (
              <StatusIsWhitelisted address={customerCompany.tokenAddress} />
            )}
            <div className="block space space__8">{' '}</div>
            {customerCompany.is_token_swap && <span className="status-green-dark">Token Swap</span>}
          </div>

          {publicSettings.identification && publicSettings.identification.active_ident_service === 'electronic_id' && (
            <div className="transactions__col vert__middle" />
          )}
          {isShowOnfidoCheck && (
            <div className="transactions__col vert__middle">
              <div className="transactions__label">{t('Liveness Check (Onfido)')}</div>

              <div className="transactions__col_group">
                {customerCompany.customerCompanyDirector.map((director, index) => (
                  <div className="transactions__product_warpper" key={director.id}>
                    <div className="transactions__product">
                      #{index + 1}. {director.firstName} {director.lastName}
                    </div>
                    {renderOnfidoCheck(director.onfidoApplicant)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {isShowComplycubeCheck && (
            <div className="transactions__col vert__middle">
              <div className="transactions__label">{t('Liveness Check (ComplyCube)')}</div>

              <div className="transactions__col_group">
                {customerCompany.customerCompanyDirector.map((director, index) => (
                  <div className="transactions__product_warpper" key={director.id}>
                    <div className="transactions__product">
                      #{index + 1}. {director.firstName} {director.lastName}
                    </div>
                    {renderComplycubeCheck(director.complycubeClient)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {isShowIdnowCheck && (
            <div className="transactions__col vert__middle">
              <div className="transactions__label">{t('Liveness Check (IDnow)')}</div>

              <div className="transactions__col_group">
                {customerCompany.livenessCheckServiceName === 'idnow' && customerCompany.customerCompanyDirector.map((director, index) => (
                  <div className="transactions__product_warpper" key={director.id}>
                    <div className="transactions__product">
                      #{index + 1}. {director.firstName} {director.lastName}
                    </div>
                    {renderIdnowCheck(director.idnowIdent)}
                  </div>
                ))}
                {customerCompany.livenessCheckServiceName !== 'idnow' && <div className="status">{t('NOT FOUND')}</div>}
              </div>
            </div>
          )}
          <div className="transactions__col transactions__col-action vert__middle">
            <KycListActions
              me={me}
              customerType={type}
              refetch={refetch}
              userKyc={customerCompany}
              autoOpenDrawer={autoOpenDrawer}
              resetAutoOpenDrawer={() => setAutoOpenDrawer(false)}
            />
          </div>
        </div>
      );
    }

    if (type === CUSTOMER_TYPES.INDIVIDUAL && typeof object === "object") {
      const customer = object;

      const videoIdentStatus = {
        electronicId: null,
        onfido: null,
      };

      if (
        customer &&
        customer.onfidoApplicant &&
        customer.onfidoApplicant.onfidoChecks
      ) {
        const onfidoChecks = customer.onfidoApplicant.onfidoChecks;

        onfidoChecks.sort(function compare(a, b) {
          var dateA = new Date(a.created_at);
          var dateB = new Date(b.created_at);
          return dateB - dateA;
        });

        const completedDocumentCheck = onfidoChecks.find(
          (check) => check.status === "complete" && check.onfidoReports.find(
            (element) => element.name && element.name.includes("document")
          )
        );

        const completedWatchlistEnhancedCheck = onfidoChecks.find(
          (check) => check.status === "complete" && check.onfidoReports.find(
            (element) => element.name && element.name.includes("watchlist_enhanced")
          )
        );

        if (completedDocumentCheck || completedWatchlistEnhancedCheck) {
          videoIdentStatus.onfido = {
            videoIdent: {},
            enhancedWatchList: {},
          };

          if (completedDocumentCheck) {
            videoIdentStatus.onfido.videoIdent = {
              result: (completedDocumentCheck.onfidoReports.find((element) =>
                  element.name && element.name.includes("document")
              ) || {}).result || 'NOT_SUBMITTED'
            }
          }

          if (completedWatchlistEnhancedCheck) {
            videoIdentStatus.onfido.enhancedWatchList = completedWatchlistEnhancedCheck.onfidoReports.find(
              (element) =>
                element.name && element.name.includes("watchlist_enhanced")
            ) || {}
          }
        } else if (onfidoChecks.length > 0) {
          videoIdentStatus.onfido = {
            videoIdent: { result: "PENDING" },
            enhancedWatchList: { result: "PENDING" },
          };
        } else {
          if (publicSettings.identification && publicSettings.identification.active_ident_service === 'electronic_id') {
            videoIdentStatus.electronicId = 'NOT_SUBMITTED';
          } else {
            videoIdentStatus.onfido = 'NOT_SUBMITTED';
          }
        }
      }

      const tokenToEthCustomer = tokenToEth(
        (customer || {}).amount,
        customer.personal_discount || exchangeRates,
      );
      return (
        <div key={customer.id} className="transactions__row">
          {
            me && hasUserEnoughRights(
              me.rights,
              USER_RIGHT_TYPES.COMPLIANCE_OFFICER
            ) && (
              <div className="transactions__col vert__middle">
                <div className="transactions__label">
                  {t('Check to filter')}
                </div>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    className="checkbox__input"
                    checked={isKycUserChecked(customer.id)}
                    onChange={() => {
                      handleChangeUserKycChecked(customer.id);
                    }}
                  />
                  <span className="checkbox__inner">
                    <div className="checkbox__tick">{' '}</div>
                  </span>
                </label>
              </div>
            )
          }

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Email')}
            </div>
            <ShortenText text={customer.user.email} v2/>
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Unique KYC ID')}
            </div>
            {object.id}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Receiving Address')}
            </div>
            <ShortenText text={customer.tokenAddress} />
            <br />
            {customer.is_tangany_wallet && <span className="status-gray-dark">{t('Tangany')}</span>}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Sending Address')}
            </div>
            <ShortenText text={customer.tokenSendingAddress} />
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Contribution')}
            </div>
            {publicSettings && publicSettings.token_type === TOKEN_TYPES.LIQUIDATION && (
              <>
                {`${customer.amount} ${publicSettings.token_symbol}`}
              </>
            )}
            {publicSettings && publicSettings.token_type !== TOKEN_TYPES.LIQUIDATION && exchangeRates && (
              <>
                {ethToFiat(
                  (customer || {}).currency,
                  tokenToEthCustomer,
                  publicSettings && publicSettings.enable_personal_discount && customer.personal_discount
                  ? customer.personal_discount
                  : exchangeRates
                )}{" "}
                {(customer || {}).currency}
              </>
            )}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">
              {t('Source')}
            </div>
            {object.referal_code
              ? object.referal_code
              : object.user.is_gtoswiss
              ? "GTOwiss.com"
              : object.user.is_internal_sales
              ? "InternalSales"
              : "Organic"}
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Submitted At')}</div>
            <div className="transactions__product">
              {new Date(customer.created_at).toDateString()}
              {' '}
            </div>
            <div className="transactions__invoice">
              {moment(customer.created_at).format("h:mm:ss a")}
            </div>
          </div>

          <div className="transactions__col vert__middle">
            <div className="transactions__label">{t('Status')}</div>
            {renderKycStatus(customer.status, customer.is_auto_approved || customer.auto_approve_by_sync)}
            {' '}
            {customer.status !== CUSTOMER_STATUS_TYPES.WHITELISTED && customer.tokenAddress && (
              <StatusIsWhitelisted address={customer.tokenAddress} />
            )}
            <div className="block space space__8">{' '}</div>
            {customer.is_token_swap && <span className="status-gray-dark">Token Swap</span>}
          </div>

          {publicSettings.identification && publicSettings.identification.active_ident_service === 'electronic_id' && (
            <div className="transactions__col vert__middle">
              <div className="transactions__label">{ t('Liveness Check') } (Electronic ID)</div>
              {!videoIdentStatus.onfido && customer.user.video_ident_status}
            </div>
          )}

          {isShowOnfidoCheck && (
            <div className="transactions__col vert__middle">
              <div className="transactions__label">{t('Liveness Check (Onfido)')}</div>
              {videoIdentStatus.onfido && (
                typeof videoIdentStatus.onfido === 'object' ? (
                  <div className="transactions__product_wrapper">
                    {(videoIdentStatus.onfido.videoIdent.result === 'clear') && (
                      <div className="transactions__product">
                        {t('Liveness Check')}:
                        {' '}
                        <div className="status-green-dark">
                          {(
                            videoIdentStatus.onfido.videoIdent.result || ""
                          ).toUpperCase()}
                        </div>
                      </div>
                    )}
                    {(videoIdentStatus.onfido.videoIdent.result === 'consider') && (
                      <div className="transactions__product">
                        {t('Liveness Check')}:
                        {' '}
                        <div className="status-pending-dark">
                          {(
                            videoIdentStatus.onfido.videoIdent.result || ""
                          ).toUpperCase()}
                        </div>
                      </div>
                    )}

                    {(videoIdentStatus.onfido.videoIdent.result === 'PENDING') && (
                      <div className="transactions__product">
                        {t('Liveness Check')}:
                        {' '}
                        <div className="status-pending-dark">
                          {(
                            videoIdentStatus.onfido.videoIdent.result || ""
                          ).toUpperCase()}
                        </div>
                      </div>
                    )}

                    {(videoIdentStatus.onfido.enhancedWatchList.result === 'clear') && (
                      <div className="transactions__product">
                        {t('Enhanced Check')}:
                        {' '}
                        <div className="status-green-dark">
                          {(
                            videoIdentStatus.onfido.enhancedWatchList.result || ""
                          ).toUpperCase()}
                        </div>
                      </div>
                    )}

                    {(videoIdentStatus.onfido.enhancedWatchList.result === 'consider') && (
                      <div className="transactions__product">
                        {t('Enhanced Check')}:
                        {' '}
                        <div className="status-pending-dark">
                          {(
                            videoIdentStatus.onfido.enhancedWatchList.result || ""
                          ).toUpperCase()}
                        </div>
                      </div>
                    )}

                    {(videoIdentStatus.onfido.enhancedWatchList.result === 'PENDING') && (
                      <div className="transactions__product">
                        {t('Enhanced Check')}:
                        {' '}
                        <div className="status-pending-dark">
                          {(
                            videoIdentStatus.onfido.enhancedWatchList.result || ""
                          ).toUpperCase()}
                        </div>
                      </div>
                    )}
                  </div>
                ) :
                <>
                  <div className="transactions__product">
                    <div className="status-pending-dark">{videoIdentStatus.onfido}</div>
                  </div>
                </>
              )}
            </div>
          )}

          {isShowComplycubeCheck && (
            <div className="transactions__col vert__middle">
              <div className="transactions__label">{ t('Liveness Check') } (ComplyCube)</div>
              {renderComplycubeCheck(customer.complycubeClient)}
            </div>
          )}

          {isShowIdnowCheck && (
            <div className="transactions__col vert__middle">
              <div className="transactions__label">{ t('Liveness Check') } (IDnow)</div>
              {renderIdnowCheck(customer.idnowIdent)}
            </div>
          )}

          <div className="transactions__col transactions__col-action vert__middle">
            <KycListActions
              me={me}
              customerType={type}
              refetch={refetch}
              userKyc={customer}
              autoOpenDrawer={autoOpenDrawer}
              resetAutoOpenDrawer={() => setAutoOpenDrawer(false)}
            />
          </div>
        </div>
      );
    }
    return false;
  };

  const [exportElectronicIdData, exportElectronicIdDataStatus] = useMutation(
    EXPORT_ELECTRONIC_ID_DATA
  );

  const requestExportElectronicIdData = () => {
    const customerIds = selectedUserKycs
      .filter((kyc) => kyc.checked)
      .map((kyc) => kyc.id);
    const userIds = data.kycList.objects.filter(object => customerIds.includes(object.id)).map(customer => customer.user_id);

    exportElectronicIdData({
      variables: {
        id: userIds.length ? userIds : []
      }
    }).then((response) => {
      if (response.data.exportElectronicIdData.success) {
        toaster.success('Your request is processing and the download link will be send via your email.');
      } else {
        toaster.error('No user has ElectronicID info');
      }
    }).catch(() => {})
  }

  const selectedKycData = (() => {
    if (!data || !data.kycList) {
      return []
    }

    const selectedIds = selectedUserKycs.filter((kyc) => kyc.checked).map(kyc => kyc.id)

    return data.kycList.objects.filter(kyc => selectedIds.includes(kyc.id))
  })()

  const whitelistAddresses = async () => {
    // Check for prerequisite settings
    if (publicSettings.token_type === TOKEN_TYPES.NONE) {
      toaster.error('Smart contract does not support token type NONE')

      return
    }

    if (metamaskService.isMetamaskNotAvailable()) {
      toaster.error('You need to install Metamask to use this feature')

      return
    }

    if (!publicSettings.enable_smart_contract) {
      toaster.error('Smart contract has not been enabled')

      return
    }

    if (!publicSettings.smart_contract_address) {
      toaster.error('Smart contract has not been set')

      return
    }

    if (!publicSettings.smart_contract_network) {
      toaster.error('Smart contract network has not been set')

      return
    }

    const customerIds = selectedUserKycs
      .filter((kyc) => kyc.checked)
      .map((kyc) => kyc.id)

    const kycList = data.kycList.objects.filter(object => customerIds.includes(object.id) && object.tokenAddress)

    if (kycList.length === 0) {
      toaster.error(`No KYC has wallet address`);

      return;
    }

    const isAllWalletsValid = kycList.reduce((all, kyc) => {
      const isValidAddress = Web3.utils.isAddress(metamaskService.formatAddress(kyc.tokenAddress));
      if (!isValidAddress) {
        toaster.error(`KYC ${kyc.id} has invalid wallet address`);

        return false;
      }

      if (+kyc.amount === 0) {
        toaster.error(`KYC ${kyc.id}'s token amount is 0`);

        return false;
      }

      return all
    }, true)

    if (!isAllWalletsValid) {
      return
    }

    // Start whitelisting for user
    // Getting account
    switch (publicSettings.smart_contract_network) {
    case 'mainnet':
      await metamaskService.requireNetwork(isProduction ? 'mainnet' : 'sepolia', () => {
        window.location.reload()
      })

      break

    case 'polygon':
      await metamaskService.requireNetwork(isProduction ? 'polygon' : 'amoy', () => {
        window.location.reload()
      })

      break

    case 'xinfin':
      await metamaskService.requireNetwork(isProduction ? 'xinfin' : 'apothem', () => {
        window.location.reload()
      })

      break

    default:
      toaster.error('Network not supported');

      return
    }

    await metamaskService.requestAccounts()

    const web3 = new Web3(window.ethereum)
    const account = metamaskService.getCurrentAddress()
    const contractAddress = metamaskService.formatAddress(publicSettings.smart_contract_whitelist_address || publicSettings.smart_contract_address)
    const contract = new web3.eth.Contract(
      publicSettings.smart_contract_type === 'crowdsale_contract'
        ? contractAbi.crowdsale
        : contractAbi.token,
      contractAddress
    );
    const allAddresses = kycList.map(kyc => metamaskService.formatAddress(kyc.tokenAddress))

    const baseCurrency = publicSettings.base_currency
      ? publicSettings.base_currency
      : 'chf';

    const allAmount = kycList.map(kyc => {
      if (kyc.max_invest_amount === 'Infinity'){
        return kyc.amount
      }

      return Math.floor(+fiatToToken(
        baseCurrency,
        kyc.max_invest_amount,
        exchangeRates
      ))
    })

    if (publicSettings.smart_contract_type === 'crowdsale_contract') {
      contract.methods.addWhitelistedMultiple(allAddresses, allAmount).send({
        from: account,
        value: 0
      })
    } else {
      contract.methods.addWhitelistedMultiple(allAddresses).send({
        from: account,
        value: 0
      })
    }
  }

  if (data && data.kycList && !loadingExchangeRates) {
    const emptyRows =
      pageSize - Math.min(pageSize, data.kycList.meta.total - page * pageSize);
    return (
      <>
        {selectedKycData.length > 0 && (
          <BatchCompensateDialog
            open={openBatchCompensateDialog}
            onClose={() => setOpenBatchCompensateDialog(false)}
            customers={selectedKycData}
          />
        )}
        <div className="create__card card">
          <MaterialAccordion className="accordion__kyc">
            <MaterialAccordionSummary
              className="accordion__kyc_head"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="attributesbh-content"
              id="attributes-header"
            >
              <div className="title-primary card__title">
                { t('Details & Filters') }
              </div>
            </MaterialAccordionSummary>

            <MaterialAccordionDetails className="accordion__kyc_body">
              <div className="settings__list w-full">
                <div className="settings__item">
                  {type === CUSTOMER_TYPES.INDIVIDUAL && (
                    <div className="w-full">
                      <div className="create__group">
                        {hasUserEnoughRights(
                          me.rights,
                          USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                        ) && (
                          <>
                            <div className="field">
                              <div className="field__label">
                                {t('Downloads')}
                              </div>

                              <div className="field__wrap">
                                <button
                                  className="button-stroke w-full"
                                  type="button"
                                  onClick={() => {
                                    setIsPythagoras(false);
                                    setOpenDownloadDialog(true);
                                  }}
                                >
                                  {t('Download as CSV')}
                                </button>
                              </div>
                            </div>

                            <div className="field">
                              <div className="field__wrap">
                                <button
                                  className="button-stroke w-full"
                                  type="button"
                                  onClick={isFeatureEnabled('PYTHAGORAS_EXPORT', availableFeatures) ? () => {
                                      setIsPythagoras(true);
                                      setOpenDownloadDialog(true);
                                    } : undefined
                                  }
                                >
                                  {t('Download as CSV for Pythagoras')}
                                </button>
                              </div>
                            </div>

                            <div className="field">
                              <div className="field__wrap">
                                <button
                                  className="button-stroke w-full"
                                  type="button"
                                  onClick={() => {
                                    setIsPythagoras(false);
                                    setIsBank(true);
                                    setOpenDownloadDialog(true);
                                  }}
                                >
                                  {t('Download as CSV for Bank')}
                                </button>
                              </div>
                            </div>

                            <div className="field">
                              <div className="field__wrap">
                                <ButtonExportTxtFile
                                  mutation={TXT_FILE_CONTRACTED}
                                  nameDownloadFile="eth_for_contracting"
                                  buttonClass="w-full"
                                >
                                  {t('ETH for contracting export')}
                                </ButtonExportTxtFile>
                              </div>
                            </div>

                            <div className="field">
                              <div className="field__wrap">
                                <ButtonExportTxtFile
                                  mutation={TXT_FILE_DISTRIBUTION}
                                  nameDownloadFile="token_distribution"
                                  buttonClass="w-full"
                                >
                                  {t('Token distribution export from approved transactions')}
                                </ButtonExportTxtFile>
                              </div>
                            </div>

                            <Dialog
                              open={openDownloadDialog}
                              onClose={() => {
                                setOpenDownloadDialog(false)
                              }}
                            >
                              <ModalInnerMaterialDialog
                                onClickClose={() => {
                                  setOpenDownloadDialog(false)
                                }}
                                modalTitle={t('Download')}
                                modalTitleClassColor="title-primary"
                              >
                                <AdminExportKyc
                                  isPythagoras={isPythagoras}
                                  isBank={isBank}
                                  type={CUSTOMER_TYPES.INDIVIDUAL}
                                  onClose={() => {
                                    setOpenDownloadDialog(false);
                                  }}
                                />
                              </ModalInnerMaterialDialog>
                            </Dialog>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="settings__item">
                  <div className="w-full">
                    <div className="create__group">
                      <div className="field">
                        <div className="field__label">{t('Attributes')}</div>
                      </div>
                    </div>
                    <div className="create__group">
                      {renderSettingsBtn()}
                    </div>

                    {/* <div className="space space__24">{' '}</div> */}
                    {/* {
                      renderEditBtn()
                    } */}
                  </div>
                </div>

                <div className="settings__item">
                  <div className="w-full">
                    <div className="create__group">
                      <div className="field">
                        <div className="field__label">{t('Liveness check (Onfido)')}</div>
                      </div>
                    </div>

                    <div className="create__group">
                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-idle"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t(' Idle')}</div>
                          </div>
                        </label>
                      </div>

                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-whitelisted"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t('Whitelisted')}</div>
                          </div>
                        </label>
                      </div>

                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-approved"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t('Approved')}</div>
                          </div>
                        </label>
                      </div>

                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-pending"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t('Pending')}</div>
                          </div>
                        </label>
                      </div>

                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-reopen"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t('Reopen')}</div>
                          </div>
                        </label>
                      </div>

                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-rejected"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t('Rejected')}</div>
                          </div>
                        </label>
                      </div>

                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-idle"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t('Contracted')}</div>
                          </div>
                        </label>
                      </div>

                      <div className="field field__checkbox-kyc">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox__input"
                            value="status-kyc-lvl-change"
                          />
                          <div className="checkbox__inner">
                            <div className="checkbox__tick">{' '}</div>
                            <div className="checkbox__text">{t('KCY level change')}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {type === CUSTOMER_TYPES.INDIVIDUAL && (
                  <div className="settings__item">
                    <div className="w-full">
                      <div className="create__group">
                        <div className="field">
                          <div className="field__label">
                            {t('Attributes')}
                          </div>
                        </div>
                      </div>
                      <div className="create__group">
                        <DropdownMaterial
                          label={t('Country of residence')}
                          propertyName="countryOfResidence"
                          state={reqOptions}
                          setState={onChangeDropdownReqOptions}
                          isGray
                        >
                          <MenuItem value="ANY">
                            {t('Any')}
                          </MenuItem>
                          <MenuItem value="RISK_LIST_A">
                            {t('Risk List A')}
                          </MenuItem>
                          <MenuItem value="OTHERS">
                            {t('Others')}
                          </MenuItem>
                        </DropdownMaterial>

                        <DropdownMaterial
                          label={t('Nationality')}
                          propertyName="nationality"
                          state={reqOptions}
                          setState={onChangeDropdownReqOptions}
                          isGray
                          cl
                        >
                          <MenuItem value="ANY">
                            {t('Any')}
                          </MenuItem>
                          <MenuItem value="RISK_LIST_A">
                            {t('Risk List A')}
                          </MenuItem>
                          <MenuItem value="OTHERS">
                            {t('Others')}
                          </MenuItem>
                        </DropdownMaterial>

                        <DropdownMaterial
                          label={t('Watchlist enhanced')}
                          propertyName="watchlistEnhanced"
                          state={reqOptions}
                          setState={onChangeDropdownReqOptions}
                          isGray
                        >
                          <MenuItem value="ANY">
                            {t('Any')}
                          </MenuItem>
                          <MenuItem value="RISK_LIST_A">
                            {t('Risk List A')}
                          </MenuItem>
                          <MenuItem value="OTHERS">
                            {t('Others')}
                          </MenuItem>
                        </DropdownMaterial>
                      </div>
                    </div>
                  </div>
                )}

                {publicSettings && (publicSettings.identification.active_ident_service === 'onfido' || publicSettings.identification.active_ident_service === 'idnow') && (
                  <div className="settings__item">
                    <div className="w-full">
                      <div className="create__group">
                        <div className="field">
                          <div className="field__label">{t('Liveness check filter')}</div>
                        </div>
                      </div>

                      <div className="create__group">
                        <div className={classNames('field field__checkbox-kyc', classes.customFieldSpace)}>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              className="checkbox__input"
                              checked={reqOptions.livenessChecks.includes('onfido')}
                              value="onfido"
                              onChange={() => onChangeFilterLivenessChecks('onfido')}
                            />
                            <div className="checkbox__inner">
                              <div className="checkbox__tick">{' '}</div>
                              <div className="checkbox__text">{t('Show based on Onfido')}</div>
                            </div>
                          </label>
                        </div>

                        <div className={classNames('field field__checkbox-kyc', classes.customFieldSpace)}>
                          <label className="checkbox">
                            <input
                              type="checkbox"
                              className="checkbox__input"
                              checked={reqOptions.livenessChecks.includes('idnow')}
                              value="idnow"
                              onChange={() => onChangeFilterLivenessChecks('idnow')}
                            />
                            <div className="checkbox__inner">
                              <div className="checkbox__tick">{' '}</div>
                              <div className="checkbox__text">{t('Show based on Idnow')}</div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </MaterialAccordionDetails>
          </MaterialAccordion>
        </div>
        {/*
          <div className="create__card card p__0 accordion__kyc-wrapper">
            <MaterialAccordion className="accordion__kyc">
              <MaterialAccordionSummary
                className="accordion__kyc_head"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="attributesbh-content"
                id="attributes-header"
              >
                <div className="title-primary card__title">
                  { t('Attributes') }
                </div>
              </MaterialAccordionSummary>

              <MaterialAccordionDetails className="accordion__kyc_body">
                <div className="field w-full">
                  <div className="create__label">
                    { t('Action') }
                  </div>

                  <div className="create__list">
                    {renderSettingsBtn()}
                  </div>

                  <div className="field">
                    {renderEditBtn()}
                  </div>
                </div>
              </MaterialAccordionDetails>
            </MaterialAccordion>
          </div>
        */}

        <div className="transactions refunds card">
          <div className="products__head flex__row flex__wrap flex__justify_between">
            <div className="title-primary products__title">{ t('Overview') }</div>
            <form className="form">
              <input
                type="search"
                className="form__input"
                placeholder={t('Type in search')}
                value={reqOptions.search}
                onChange={onChangeReqOptions("search")}
              />
              <button className="form__button">
                <Icon
                  className={classNames('searchh')}
                  name='searchh'
                  size="24"
                />{" "}
              </button>
            </form>

            {selectedUserKycs.find(kyc => kyc.checked) && (
              <div className="products__nav products__nav_btn">
                {type === CUSTOMER_TYPES.INDIVIDUAL && (
                  <button
                    className="button-small button-stroke"
                    onClick={() => {
                      setOpenReopenWithVideoIdentDialog(true);
                    }}
                  >
                    {t('Reopen with liveness check')}
                  </button>
                )}
                {
                  publicSettings.token_type === TOKEN_TYPES.LIQUIDATION &&
                  type === CUSTOMER_TYPES.INDIVIDUAL && (
                    <button
                      className="button-small button-stroke"
                      onClick={() => {
                        setOpenBatchCompensateDialog(true);
                      }}
                    >
                      {t('Pay compensation')}
                    </button>
                  )
                }
                {exportElectronicIdDataStatus.loading
                  ? (
                    <CircularProgress />
                  )
                  : (
                    <button
                      className="button-small button-stroke"
                      onClick={requestExportElectronicIdData}
                    >
                      {t('Export ElectronicID Info')}
                    </button>
                  )
                }
                { selectedUserKycs.find(kyc => (
                    kyc.checked &&
                    ![CUSTOMER_STATUS_TYPES.PENDING, CUSTOMER_STATUS_TYPES.REJECTED, CUSTOMER_STATUS_TYPES.REOPEN, 'REOPEN_WITH_VIDEOIDENT'].includes(kyc.status) &&
                    !kyc.whitelisted
                  )) && (
                  <button
                    className="button-small button-stroke"
                    onClick={whitelistAddresses}
                  >
                    {t('Whitelist addresses')}
                  </button>
                )}
              </div>
            )}
          </div>

          <div className="transactions__wrapper products__table-kyclist__wrapper">
            <div className="transactions__table kyc__table_transactions kyc__table_kyclist">
              <div className="transactions__row">
                {
                  me && hasUserEnoughRights(
                    me.rights,
                    USER_RIGHT_TYPES.COMPLIANCE_OFFICER
                  ) && (
                    <div className="transactions__col">
                      <label
                        className="checkbox"
                        title="Select all"
                      >
                        <input
                          type="checkbox"
                          className="checkbox__input"
                          checked={isSelectAll}
                          onChange={onClickIsSelectAll}
                        />
                        <div className="checkbox__inner">
                          <div className="checkbox__tick">{' '}</div>
                        </div>
                      </label>
                    </div>
                  )
                }

                <div className="transactions__col">
                  {t('Email')}
                </div>

                <div className="transactions__col">
                  {t('Unique KYC ID')}
                </div>

                <div className="transactions__col">
                  { t('Receiving Address') }
                </div>

                <div className="transactions__col">
                  { t('Sending Address') }
                </div>

                <div className="transactions__col">
                  { t('Contribution') }
                </div>

                <div className="transactions__col">
                  { t('Source') }
                </div>

                <div className="transactions__col">
                  { t('Submitted At') }
                </div>

                <div className="transactions__col">
                  { t('Status') }
                </div>

                {publicSettings.identification && publicSettings.identification.active_ident_service === 'electronic_id' && (
                  <div className="transactions__col">
                    { t('Liveness Check') } (Electronic ID)
                  </div>
                )}

                {isShowOnfidoCheck && (
                  <div className="transactions__col">
                    { t('Liveness Check') } (Onfido)
                  </div>
                )}

                {isShowComplycubeCheck && (
                  <div className="transactions__col">
                    { t('Liveness Check') } (ComplyCube)
                  </div>
                )}

                {isShowIdnowCheck && (
                  <div className="transactions__col">
                    { t('Liveness Check') } (IDnow)
                  </div>
                )}

                <div className="transactions__col">
                  {' '}
                </div>
              </div>

              {data.kycList.objects.map(renderKyc)}
              {emptyRows > 0 && (
                <div className="transactions__row">
                  <div className="transactions__col">
                    {' '}
                  </div>
                </div>
              )}
            </div>
          </div>
          <TablePaginationActions
            count={data.kycList.meta.total}
            page={page}
            rowsPerPage={pageSize}
            onChangePage={onChangePage}
            onChangeRows={onChangePageSize}
            rowsPerPageOptions={[1, 5, 10, 25, 100]}
          />

          <Dialog
            open={openReopenWithVideoIdentDialog}
            onClose={() => {
              setOpenReopenWithVideoIdentDialog(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <ModalInnerMaterialDialog
              modalSize="popup__kyc-medium"
              onClickClose={() => setOpenReopenWithVideoIdentDialog(false)}
              modalTitle={ t('Comment')}
              modalTitleClassColor="title-primary"
            >
              <div className="settings__fieldset">
                <div className="field">
                  <div className="field__wrap field__wrap-textarea">
                    <TextField
                      fullWidth
                      id="filled-multiline-flexible"
                      multiline
                      row="4"
                      maxRows="4"
                      onChange={(e) => {
                        setReopenVideoIdentComments(e.target.value);
                      }}
                      value={reopenVideoIdentComments}
                      autoFocus
                    />
                  </div>
                </div>

                <div className="field">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={redoVideoIdentChecked}
                        onChange={(e) => {
                          setRedoVideoIdentChecked(e.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Redo Liveness Check too?"
                  />
                </div>

                <div className="field">
                  <div className="field__wrap">
                    <button
                      type="button"
                      className="button-stroke"
                      onClick={handleReopenKycsWithVideoIdent}
                    >
                      {t('Send')}
                    </button>
                  </div>
                </div>
              </div>
            </ModalInnerMaterialDialog>
          </Dialog>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.circularProgressWrapper}>
        <CircularProgress />
      </div>
    </>
  );
};

AdminKycList.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AdminKycList;
